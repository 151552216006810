import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Button,
  Input,
  Badge
} from "reactstrap";
import { toast } from 'react-toastify';
import "views/index.css";
import { getReports }  from 'services/reportService';
import {getUser} from 'services/authService'
import SpinDiv from "components/SpinDiv.jsx";
import {Pagination} from 'antd'

class Reports extends React.Component {
  state = {
    activeNav: 1,
    loading: true,
    reports:[],
  	page: 1,
  	rows: 10,
  	total: 0,
    search: ''
  };

  componentDidMount() {
    this.getReports();
  }

  componentWillMount() {
  }

  

  getReports = () => {
    const {user, page, rows, search} = this.state;
    this.setState({loading: true});
    getReports({user, page, rows, search})
      .then((res) => {console.log(res)
        this.setState({
          reports: res.reports.data,
          page: res.reports.current_page,
          total: res.reports.total,
          loading: false,
        })
      },
      error => {
        this.setState({loading: false});
      });
  }

  


  onChange = (e, state) => {
    this.setState({[state]: e})
  }
 
  
  onPage = async (page, rows) => {
    const {search} = this.state;
    await this.setState({page, rows});
    this.getReports();
    if(search===''){
      await this.getReports();
    } else {
      await this.search();
    }
  }

  onSearch = () => {

  }

  

  

  
  showToast = msg => {
    toast.error(<div style={{padding:25}}>{msg}</div>);
  }


  render() {
    const {reports, total, page, rows, search, loading} = this.state;
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#3E70D7'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
       
          
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center" style={{justifyContent:'space-between', padding: '0 15px', overflowX: 'scroll', overflow: 'visible', flexWrap: 'nowrap'}}>
                    <div>
                      <h3 className="mb-0">Work Reported <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h3>
                    </div>
                    <div className="mobile-flex">
                      <Input placeholder="Search..." style={{maxHeight: 28, marginRight: 5, minWidth: 80}}
                        value={search}
                        onChange={e=>this.onChange(e.target.value, 'search')}
                        onKeyUp={(e) => {if(e.key==='Enter'){this.getReports();}}} />
                      <Button className="btn-icon btn-2 hide-mobile" color="secondary" size="sm" style={{marginRight: 10}} onClick={this.getReports}>
                        <i className="fa fa-search" />
                      </Button>
                     
                    </div>
                  </Row>
                </CardHeader>
                <Table className={`align-items-center table-flush ${loading?'load-opacity':''}`} responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Project Topic</th>
                      <th scope="col">Link</th>
                      <th scope="col">Institution</th>
                      <th scope="col">Year</th>
                     
                      
                    </tr>
                  </thead>
                  <tbody>
                    {reports.map((report, key) => {

                      return (
                        <tr key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                        this.props.history.push('/admin/report/'+report.id)}}>
                          <td>{report.fullname}</td>
                          <td>{report.project_topic.length > 20 ? report.project_topic.substr(0, 20)+"....":report.project_topic}</td>
                          <td>{report.link}</td> 
                          <td>{report.institution}</td>
                          <td>{report.year}</td>                   
                        </tr>
                        
                        );
                      })}
                  </tbody>
                </Table>
                {!loading&&reports.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Reports found
                </div>}
               
                <Row style={{margin:10}}>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {reports.length > 0 && <Pagination
                  total={total}
                  showSizeChanger
                  showTotal={total => `Total ${total} Reports`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Reports;
