import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Badge,
  //UncontrolledTooltip,
  Media,
  Table,
  Container,
  Row,
  Col,
  Button,
  Input
} from "reactstrap";

import { toast } from 'react-toastify';

import "views/index.css";
import { addUser, getUsers}  from 'services/userService';
import SpinDiv from "components/SpinDiv.jsx";
import { Pagination } from 'antd';
import AddUser from "./AddUser.jsx";
import DeleteUser from "./DeleteUser";

class Users extends React.Component {
  state = {
    activeNav: 1,
    loading: true,
  	page: 1,
  	rows: 10,
  	total: 0,
    roles: [],
    states: [],
    search: '',
    territories: [],
    axes: [],
    zones: [],
    users: []
  };

  componentDidMount() {
    this.getUsers();
  }

  componentWillMount() {
  }

  saveUser = (e) => {
    e.preventDefault();
    const {firstname, lastname, role_id, change_password} = this.state;
    addUser({firstname, lastname, role_id, change_password})
      .then(res => {
          this.setState({message : 'User added successfully.'});
          this.props.history.push('/users');
      },
    	error => {
    		console.log(error)
    	});
  }

  getUsers = () => {
    const {role, state, territory, axis, zone, page, rows, search} = this.state;
    console.log(rows)
    this.setState({loading: true});
    getUsers({role, state, territory, axis, rows, zone, page, rows, search})
      .then((res) => {
        this.setState({
          users: res.users.data,
          page: res.users.current_page,
          total: res.users.total,
          loading: false
        })
      },
      error => {
        this.setState({loading: false});
      });
  }

  toggleModal = () => {
    toast.dismiss()
    this.setState({addUser: !this.state.addUser});
  }

  toggleFilter = () => {
    this.setState({showFilter: !this.state.showFilter});
  }

  toggleDelete = (deleteUser) => {
    this.setState({ deleteUser });
  };

  onChange = (e, state) => {
    this.setState({[state]: e})
  }

  onSearch = () => {

  }

  onPage = async (page, rows) => {
    const {search} = this.state;
    await this.setState({page, rows});
    if(search===''){
      await this.getUsers();
    } else {
      await this.search();
    }
  }

  

  render() {
    const {users,  addUser, deleteUser,
       total, page, rows, search, loading} = this.state;
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#3E70D7'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        {addUser&&
          <AddUser
            saved={this.getUsers}
            toggle={this.toggleModal}
            addUser={addUser}
          />}
          {deleteUser && (
          <DeleteUser
            saved={this.getUsers}
            deleteUser={deleteUser}
            toggle={() => this.setState({ deleteUser: null })}
          />
        )}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Users <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h3>
                    </div>
                    <div style={{display: 'flex'}}>
                      
                      <Input placeholder="Search..." style={{maxHeight: 28, marginRight: 5}}
                        value={search}
                        onChange={e=>this.onChange(e.target.value, 'search')}
                        onKeyUp={(e) => {if(e.key==='Enter'){this.getUsers();}}} />
                      <Button className="btn-icon btn-2" color="secondary" size="sm" style={{marginRight: 10}} onClick={this.getUsers}>
                        <i className="fa fa-search" />
                      </Button>
                      <Button
                        onClick={() => this.toggleModal()}
                        size="sm"
                        style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}
                      >
                        Add User
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                
                <Table className={`align-items-center table-flush ${loading?'load-opacity':''}`} responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Role</th>
                      <th scope="col">Projects</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, key) => {
                      return (
                        <tr >
                          <th scope="row">
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#p"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={user.picture||require("assets/img/brand/user.jpg")}
                                />
                              </a>
                              <Media>
                                <span className="mb-0 text-sm">
                                  {user.name}
                                </span>
                              </Media>
                            </Media>
                          </th>
                        
                          
                          <td key={key} className="hover-list" to="/"
                          onClick={() => {console.log('111')
                            this.props.history.push(`/admin/users/${user.id}`, {user})
                          }}>{user.email=== "show4ril@gmail.com"? "hello@myproject.ng": user.email}</td>
                          <td key={key} className="hover-list" to="/"
                          onClick={() => {console.log('111')
                            this.props.history.push(`/admin/users/${user.id}`, {user})
                          }}>{user.phone}</td>
                          <td key={key} className="hover-list" to="/"
                          onClick={() => {console.log('111')
                            this.props.history.push(`/admin/users/${user.id}`, {user})
                          }}>{user.admin == 1 ?`Administrator${user.role === null ? " ":"/"+user.role}`:user.role}</td>

                          <td  className="hover-list" onClick={() => {console.log('111')
                            this.props.history.push(`/admin/users/${user.id}`, {user})
                          }}>{user.projects_count}</td>
                          <td key={key} className="hover-list" to="/"
                          onClick={() => {console.log('111')
                            this.props.history.push(`/admin/users/${user.id}`, {user})
                          }}>
                            <Badge color="" className="badge-dot mr-4">
                              <i className={user.status ==1 ?'bg-success':'bg-danger'} />
                              {user.status ==1 ?'Active':'Inactive'}
                            </Badge>
                            
                          </td>
                          {user.id ==1 ? ' ': <td>
                            <Button
                              color="danger"
                              onClick={() => this.toggleDelete(user)}
                              size="sm"
                            >
                              Delete
                            </Button>
                          </td>}
                        </tr>
                        );
                      })}
                  </tbody>
                </Table>
                {!loading&&users.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No user found
                </div>}
               
                <Row style={{margin:10}}>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {users.length > 0 && <Pagination
                  total={total}
                  showSizeChanger
                  showTotal={total => `Total ${total} users`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Users;
