import React from "react";
import { Row, Col, Button, Modal, FormGroup, Input } from "reactstrap";
import { toast } from "react-toastify";

import { addHirewriter } from "services/hirewriterService";
import SpinDiv from "components/SpinDiv.jsx";

class AddHirewriter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      categories: props.categories,
      search: "",
      validation: {},
      plan_title: "",
      allow_to_choose_method:"",
      plan_type: "",
      plancategory_id: "",
      price: "",
      content_nature: "",
      proposal: "",
      chapters: "",
      reference: "",
      questionaire: "",
      format: "",
      page_length: "",
      plagiarism_level: "",
      chapterization: "",
      special_request: "",
      source_code: "",
      plagiarism_checker: "",
      supported_department: "",
      data_analysis: "",
      appendixe: "",
      support_service: "",
      abstract: "",
      prelim_page: "",
      bgcolor:"",
      project_format:"",
      custom_field_one:"",
      custom_field_two:"",
      custom_field_three:"",
    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
  }

  onSave = async e => {
    e.preventDefault();
    await toast.dismiss();
    const {
      plancategory_id,
      allow_to_choose_method,
      plan_type,
      plan_title,
      price,
      content_nature,
      proposal,
      chapters,
      reference,
      questionaire,
      format,
      page_length,
      plagiarism_level,
      chapterization,
      special_request,
      source_code,
      plagiarism_checker,
      supported_department,
      data_analysis,
      validation,
      appendixe,
      support_service,
      abstract,
      prelim_page,
      bgcolor,
      project_format
    } = this.state;
    await this.setState({
      validation: {
        ...validation,
        plan_title: plan_title !== "",
        plan_type: plan_type !== "",
        price: price !== "",
        content_nature: content_nature !== "",
        proposal: proposal !== "",
        chapters: chapters !== "",
        reference: reference !== "",
        questionaire: questionaire !== "",
        format: format !== "",
        allow_to_choose_method:allow_to_choose_method !== "",
        page_length: page_length !== "",
        plagiarism_level: plagiarism_level !== "",
        chapterization: chapterization !== "",
        special_request: special_request !== "",
        source_code: source_code !== "",
        plagiarism_checker: plagiarism_checker !== "",
        supported_department: supported_department !== "",
        data_analysis: data_analysis !== "",
        abstract: abstract !== "",
        prelim_page: prelim_page !== "",
        plancategory_id: plancategory_id !== "",
        bgcolor: bgcolor!=="",
        project_format:project_format !== ""
      }
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveHirewriter();
    } else {
      const errors = Object.keys(this.state.validation).filter(id => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(
        () =>
          toast.error(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold" }}>Errors:</p>
              {errors.map(v => (
                <p key={v} style={{ margin: 0, fontSize: 14 }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          ),
        250
      );
    }
  };

  validationRules = field => {
    if (field === "name") {
      return "Name is required";
    } else if (field === "plan_type") {
      return "Plan Type is Required";
    } else if (field === "plan_title") {
      return "Plan title is required";
    } else if (field === "price") {
      return "price is required";
    } else if(field === "project_format"){
      return "Project Format is required"
    } else if (field === "content_nature") {
      return "Content Nature is required";
    } else if (field === "proposal") {
      return "Proposal is required";
    } else if (field === "chapters") {
      return "Chapters is required";
    } else if (field === "reference") {
      return "reference is required";
    } else if (field === "questionaire") {
      return "questionaire is required";
    } else if (field === "format") {
      return "format is required";
    } else if (field === "page_length") {
      return "page length is required";
    } else if (field === "plagiarism_level") {
      return "plagiarism level is required";
    } else if (field === "chapterization") {
      return "chapterization is required";
    } else if (field === "special_request") {
      return "special_request is required";
    } else if (field === "source_code") {
      return "source code is required";
    } else if (field === "plagiarism_checker") {
      return "plagiarism checker is required";
    } else if (field === "supported_department") {
      return "supported department is required";
    } else if (field === "data_analysis") {
      return "data analysis is required";
    } else if (field === "abstract") {
      return "Abstract is required";
    } else if (field === "prelim_page") {
      return "Preliminary page is required";
    } else if (field === "plancategory_id") {
      return "Plan Category is required";
    } else if (field === "bgcolor") {
        return "Choose a backgroud color";
      }
  };

  saveHirewriter = () => {
    this.setState({ loading: true });
    const {
      plancategory_id,
      plan_type,
      plan_title,
      price,
      content_nature,
      allow_to_choose_method,
      proposal,
      chapters,
      reference,
      questionaire,
      format,
      page_length,
      bgcolor,
      plagiarism_level,
      chapterization,
      special_request,
      source_code,
      plagiarism_checker,
      supported_department,
      data_analysis,
      appendixe,
      support_service,
      abstract,
      prelim_page,
      project_format,
      custom_field_one,
      custom_field_two,
      custom_field_three,
    } = this.state;
    addHirewriter({
      plancategory_id,
      plan_type,
      bgcolor,
      plan_title,
      price,
      content_nature,
      allow_to_choose_method,
      proposal,
      chapters,
      reference,
      questionaire,
      format,
      project_format,
      page_length,
      plagiarism_level,
      chapterization,
      special_request,
      source_code,
      plagiarism_checker,
      supported_department,
      data_analysis,
      custom_field_one,
      custom_field_two,
      custom_field_three,
      appendixe,
      support_service,
      abstract,
      prelim_page
    }).then(
      res => {
        console.log(res);
        this.setState({ loading: false });
        this.props.saved();
        this.props.toggle();
        this.showToast("HireWriter Plan Created");
      },
      error => {
        console.log(error);
        if (error.plan_title) {
          this.showToast("A Plan with this title exists");
        }
        this.setState({ loading: false });
      }
    );
  };

  showToast = msg => {
    toast.error(<div style={{ padding: 20 }}>{msg}</div>);
  };

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  render() {
    const { add, toggle } = this.props;
    const {
      loading,
      categories,
      plancategory_id,
      plan_type,
      plan_title,
      price,
      content_nature,
      allow_to_choose_method,
      proposal,
      chapters,
      reference,
      questionaire,
      format,
      bgcolor,
      page_length,
      plagiarism_level,
      chapterization,
      special_request,
      source_code,
      plagiarism_checker,
      supported_department,
      custom_field_one,
      custom_field_two,
      custom_field_three,
      data_analysis,
      appendixe,
      support_service,
      abstract,
      prelim_page,
      project_format,
      validation
    } = this.state;
    const Required = () => <span style={{ color: "red" }}>*</span>;
    return (
      <>
        <Modal
          className="modal-header"
          isOpen={add}
          toggle={() => !loading && toggle}
          style={{ maxWidth: "60%" }}
        >
          {loading && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <h3 className="modal-title" id="exampleModalLabel">
              Create Plan
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" style={{ border: "1px solid #eee" }}>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-name"
                    style={{
                      color: validation.plan_title === false ? "red" : null
                    }}
                  >
                    <Required />
                    Plan type
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-name"
                    onChange={e => this.onChange(e.target.value, "plan_title")}
                    placeholder="Plan type"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-name"
                    style={{
                      color: validation.page_length === false ? "red" : null
                    }}
                  >
                    <Required />
                    Pages Length
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-pagelength"
                    onChange={e => this.onChange(e.target.value, "page_length")}
                    placeholder="Name"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-name"
                    style={{
                      color: validation.format === false ? "red" : null
                    }}
                  >
                    <Required />
                    Format
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-name"
                    onChange={e => this.onChange(e.target.value, "format")}
                    placeholder="Name"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-name"
                    style={{
                      color:
                        validation.supported_department === false ? "red" : null
                    }}
                  >
                    <Required />
                    Support Department
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-supportfields"
                    onChange={e =>
                      this.onChange(e.target.value, "Support Department")
                    }
                    placeholder="Name"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-category_id"
                  >
                    Choose Hirewriter Plan Category
                  </label>
                  <Input
                    value={plancategory_id}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.plancategory_id === false ? "red" : null
                    }}
                    onChange={async e => {
                      await this.onChange(e.target.value, "plancategory_id");
                    }}
                  >
                    <option disabled value="">
                      Select Category
                    </option>
                    {categories.map(p => (
                      <option value={p.name} key={p.id}>
                        {p.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-name"
                   
                  >
                    <Required />
                    Custom Field One
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-supportfields"
                    onChange={e =>
                      this.onChange(e.target.value, "custom_field_one")
                    }
                    placeholder="Custom Field One"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-name"
                   
                  >
                    <Required />
                    Custom Field Two
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-supportfields"
                    onChange={e =>
                      this.onChange(e.target.value, "custom_field_two")
                    }
                    placeholder="Custom Field Two"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-name"
                   
                  >
                    <Required />
                    Custom Field Three
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-supportfields"
                    onChange={e =>
                      this.onChange(e.target.value, "custom_field_three")
                    }
                    placeholder="Custom Field Three"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-name"
                    style={{
                      color: validation.plan_type === false ? "red" : null
                    }}
                  >
                    <Required />
                    Plan description..(e.g premium basic, Advance etc).
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-plan_type"
                    onChange={e => this.onChange(e.target.value, "plan_type")}
                    placeholder="Plan Description"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-plan_price"
                    style={{ color: validation.price === false ? "red" : null }}
                  >
                    <Required />
                    Plan Price
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-price"
                    onChange={e => this.onChange(e.target.value, "price")}
                    placeholder="Price"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-reference"
                  >
                    <Required />
                    Choose Reference
                  </label>
                  <Input
                    value={reference}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.reference === false ? "red" : null
                    }}
                    onChange={async e => {
                      await this.onChange(e.target.value, "reference");
                    }}
                  >
                    <option disabled value="">
                      Choose Reference
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-reference"
                  >
                    Questionaire
                  </label>
                  <Input
                    value={questionaire}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.questionaire === false ? "red" : null
                    }}
                    onChange={async e => {
                      await this.onChange(e.target.value, "questionaire");
                    }}
                  >
                    <option disabled value="">
                      Choose Questionaire
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-reference"
                  >
                    Proposal
                  </label>
                  <Input
                    value={proposal}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.proposal === false ? "red" : null
                    }}
                    onChange={async e => {
                      await this.onChange(e.target.value, "proposal");
                    }}
                  >
                    <option disabled value="">
                      Choose Proposal
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-abstract"
                  >
                    Abstract
                  </label>
                  <Input
                    value={abstract}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.abstract === false ? "red" : null
                    }}
                    onChange={async e => {
                      await this.onChange(e.target.value, "abstract");
                    }}
                  >
                    <option disabled value="">
                      Choose Abstract
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-abstract"
                  >
                    Appendixe
                  </label>
                  <Input
                    value={appendixe}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%"
                      //color: validation.appem === false ? "red" : null,
                    }}
                    onChange={async e => {
                      await this.onChange(e.target.value, "appendixe");
                    }}
                  >
                    <option disabled value="">
                      Choose Appendixe
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Input>
                </FormGroup>
              </Col>
              
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-abstract"
                  >
                    Data Analysis
                  </label>
                  <Input
                    value={data_analysis}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.data_analysis === false ? "red" : null
                    }}
                    onChange={async e => {
                      await this.onChange(e.target.value, "data_analysis");
                    }}
                  >
                    <option disabled value="">
                      Choose Data Analysis
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-chapterization"
                  >
                    Personalized Chapterization
                  </label>
                  <Input
                    value={chapterization}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.chapterization === false ? "red" : null
                    }}
                    onChange={async e => {
                      await this.onChange(e.target.value, "chapterization");
                    }}
                  >
                    <option disabled value="">
                      Choose Data chapterization
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-abstract"
                  >
                    Support service Type
                  </label>
                  <Input
                    value={support_service}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.support_service === false ? "red" : null
                    }}
                    onChange={async e => {
                      await this.onChange(e.target.value, "support_service");
                    }}
                  >
                    <option disabled value="">
                      Choose Support service
                    </option>
                    <option value="One-time Support Service">
                      One-time Support Service
                    </option>
                    <option value="Full-time Support Service">
                      Full-time Support Service
                    </option>
                    <option value=" Upto 3-times Support">
                    Upto 3-times Support
                    </option>
                    <option value="no support">None</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-abstract"
                  >
                    Plagiarism Checker
                  </label>
                  <Input
                    value={plagiarism_checker}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color:
                        validation.plagiarism_checker === false ? "red" : null
                    }}
                    onChange={async e => {
                      await this.onChange(e.target.value, "plagiarism_checker");
                    }}
                  >
                    <option disabled value="">
                      Choose Plagiarism checker
                    </option>
                    <option value="yes">yes</option>
                    <option value="No">No</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-name"
                    style={{
                      color: validation.chapters === false ? "red" : null
                    }}
                  >
                    <Required />
                    Enter No of Chapters
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-plan_type"
                    onChange={e => this.onChange(e.target.value, "chapters")}
                    placeholder="Enter Chapters range"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-abstract"
                  >
                  Set client data analysis view
                  </label>
                  <Input
                    value={allow_to_choose_method}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.allow_to_choose_method === false ? "red" : null
                    }}
                    onChange={async e => {
                      await this.onChange(e.target.value, "allow_to_choose_method");
                    }}
                  >
                    <option disabled value="">
                      Choose
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-abstract"
                  >
                    <Required />
                    Plagiarism Level
                  </label>
                  <Input
                    value={plagiarism_level}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color:
                        validation.plagiarism_level === false ? "red" : null
                    }}
                    onChange={async e => {
                      await this.onChange(e.target.value, "plagiarism_level");
                    }}
                  >
                    <option disabled value="">
                      Choose Plagiarism Level
                    </option>
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-abstract"
                  >
                    <Required />
                    Content Nature
                  </label>
                  <Input
                    value={content_nature}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.content_nature === false ? "red" : null
                    }}
                    onChange={async e => {
                      await this.onChange(e.target.value, "content_nature");
                    }}
                  >
                    <option disabled value="">
                      Choose Content Nature
                    </option>
                    <option value="guide">Guide</option>
                    <option value="advance">Advance</option>
                    <option value="professional">Professional</option>
                    <option value="standard">Standard</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-abstract"
                  >
                    <Required />
                    Preliminary Pages
                  </label>
                  <Input
                    value={prelim_page}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.prelim_page === false ? "red" : null
                    }}
                    onChange={async e => {
                      await this.onChange(e.target.value, "prelim_page");
                    }}
                  >
                    <option disabled value="">
                      Choose Preliminary page
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-abstract"
                  >
                    <Required />
                    Special Request
                  </label>
                  <Input
                    value={special_request}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.special_request === false ? "red" : null
                    }}
                    onChange={async e => {
                      await this.onChange(e.target.value, "special_request");
                    }}
                  >
                    <option disabled value="">
                      Choose Special Request
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-sourcecode"
                  >
                    <Required />
                    Source code
                  </label>
                  <Input
                    value={source_code}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.source_code === false ? "red" : null
                    }}
                    onChange={async e => {
                      await this.onChange(e.target.value, "source_code");
                    }}
                  >
                    <option disabled value="">
                      choose Source Code
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-bgcolor"
                  >
                    <Required />
                    Add background Color
                  </label>
                  <Input
                    value={bgcolor}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.source_code === false ? "red" : null
                    }}
                    onChange={async e => {
                      await this.onChange(e.target.value, "bgcolor");
                    }}
                  >
                    <option disabled value="">
                      choose Background Color
                    </option>
                    <option value="#00E1E1">Light Blue</option>
                    <option value=" #FFD70A">Gold color</option>
                    <option value="#214A74">Marble Blue</option>
                    <option value="#CB2521">Chili Red</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-reference"
                  >
                    <Required />
                    Choose Project Format
                  </label>
                  <Input
                    value={project_format}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.project_format === false ? "red" : null
                    }}
                    onChange={async e => {
                      await this.onChange(e.target.value, "project_format");
                    }}
                  >
                    <option disabled value="">
                      Allow Project Format Upload?
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </div>

          <div className="modal-footer" style={{ padding: "1rem" }}>
            <Button
              size="sm"
              color="secondary"
              data-dismiss="modal"
              type="button"
              disabled={loading}
              onClick={toggle}
            >
              Close
            </Button>
            <Button
              color="success"
              type="button"
              disabled={loading}
              size="sm"
              onClick={this.onSave}
              style={{
                backgroundColor: "#EC3237",
                borderColor: "#EC3237",
                color: "#fff"
              }}
            >
              Save
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AddHirewriter;
