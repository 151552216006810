import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Button,
  Input,
  Badge
} from "reactstrap";
import { toast } from 'react-toastify';
import "views/index.css";
import { getActivities } from 'services/activityService';
import { getUser } from 'services/authService'
import SpinDiv from "components/SpinDiv.jsx";
import { Pagination } from 'antd'
import DeleteMultActivity from "./DeleteMultActivity";
import moment from "moment";
import ReactDatetime from "react-datetime";

class Activities extends React.Component {
  state = {
    activeNav: 1,
    loading: true,
    activities: [],
    checkActivities: [],
    delete_activity_ids: [],
    page: 1,
    rows: 10,
    total: 0,
    search: '',
    fromdate: moment().startOf('month'),
    todate: moment().endOf('day'),
  };

  componentDidMount() {
    this.getActivities();
  }

  componentWillMount() {
  }


  toggleCheckActivity = (e, item) => {
    const { checkActivities } = this.state;
    if (e.target.checked) {
      checkActivities.push(item.id);

    } else {
      checkActivities.splice(checkActivities.indexOf(item.id), 1);


    }
    this.setState({ checkActivities })
    console.log(this.state.checkActivities);
  }

  onFilter = async (e, filter) => {
   
    await this.setState({ [filter]: e });
    await this.getActivities();
  };

  toggleDeleteAll = (delete_activity_ids) => {

    this.setState({ delete_activity_ids });
  };

  getActivities = () => {
    const { user, page, rows, search, todate, fromdate} = this.state;
    this.setState({ loading: true });
    getActivities({ user, page, rows, search, todate, fromdate })
      .then((res) => {
        console.log(res)
        this.setState({
          activities: res.activities.data,
          page: res.activities.current_page,
          total: res.activities.total,
          loading: false,
        })
      },
        error => {
          this.setState({ loading: false });
        });
  }




  onChange = (e, state) => {
    this.setState({ [state]: e })
  }


  onPage = async (page, rows) => {
    const { search } = this.state;
    await this.setState({ page, rows });
    this.getActivities();
    if (search === '') {
      await this.getActivities();
    } else {
      await this.search();
    }
  }

  onSearch = () => {

  }






  showToast = msg => {
    toast.error(<div style={{ padding: 25 }}>{msg}</div>);
  }


  render() {
    const { activities, delete_activity_ids, checkActivities, total, page, rows, search, loading, fromdate, todate } = this.state;
    return (
      <>
        {delete_activity_ids &&
          <DeleteMultActivity
            saved={this.getActivities}
            activity_ids={delete_activity_ids}
            toggle={() => this.setState({ checkActivities: [], delete_activity_ids: [] })}
          />
        }
        <div className="header pb-8 pt-5 pt-md-6" style={{ backgroundColor: '#3E70D7' }}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}


        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading && <SpinDiv text={'Loading...'} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center" style={{ justifyContent: 'space-between', padding: '0 15px', overflowX: 'scroll', overflow: 'visible', flexWrap: 'nowrap' }}>
                    <div>
                      
                      <h3 className="mb-0">User Activities <span style={{ color: '#aaa', fontSize: 14, fontWeight: 'normal' }}> ({total})</span></h3>
                      <div style={{display:'flex', marginTop:'10px'}} >
                      <ReactDatetime
                            value={fromdate}
                            dateFormat={'MMM D, YYYY'}
                            closeOnSelect
                            onChange={e => this.onFilter(e, 'fromdate')}
                            inputProps={{
                              required: true,
                              className: 'form-control date-filter'
                            }}
                            isValidDate={(current) => { return (current.isBefore(todate) || current.isSame(todate)) && current.isBefore(moment()); }}
                            timeFormat={false}
                           
                          />


                          &nbsp;&nbsp;- &nbsp;&nbsp;

                          <ReactDatetime
                            value={todate}
                            dateFormat={'MMM D, YYYY'}
                            closeOnSelect
                            onChange={e => this.onFilter(e, 'todate')}
                            inputProps={{
                              required: true,
                              className: 'form-control date-filter'
                            }}
                            // isValidDate={(current)=>{return (current.isAfter(fromdate)||current.isSame(fromdate))&&current.isBefore(moment());}}
                            timeFormat={false}
                          />
                      </div>
                      
                      {checkActivities.length > 0 && <Button
                        color="danger"
                        onClick={() => this.toggleDeleteAll(checkActivities)}
                        size="sm"
                      >
                        Delete All Selected
                      </Button>}
                    </div>
                    <div className="mobile-flex">
                      <Input placeholder="Search..." style={{ maxHeight: 28, marginRight: 5, minWidth: 80 }}
                        value={search}
                        onChange={e => this.onChange(e.target.value, 'search')}
                        onKeyUp={(e) => { if (e.key === 'Enter') { this.getActivities(); } }} />
                      <Button className="btn-icon btn-2 hide-mobile" color="secondary" size="sm" style={{ marginRight: 10 }} onClick={this.getActivities}>
                        <i className="fa fa-search" />
                      </Button>

                    </div>
                  </Row>
                  
                </CardHeader>
                <Table className={`align-items-center table-flush ${loading ? 'load-opacity' : ''}`} responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Delete</th>
                      <th scope="col">User</th>
                      <th scope="col">Entity</th>
                      <th scope="col">Action</th>
                      <th scope="col">Date</th>



                    </tr>
                  </thead>
                  <tbody>
                    {activities.map((activity, key) => {

                      return (
                        <tr key={key} className="hover-list" to="/">
                          <td><input type="checkbox" className="selectsingle"
                            value={activity.id} checked={checkActivities.length > 0 ? checkActivities.find((p) => p.id === activity.id) : false} onChange={(e) => this.toggleCheckActivity(e, activity)} /></td>
                          <td>{activity.name}</td>
                          <td>{activity.entity}</td>
                          <td>{activity.action}</td>
                          <td>{activity.created_at}</td>
                        </tr>

                      );
                    })}
                  </tbody>
                </Table>
                {!loading && activities.length < 1 &&
                  <div style={{ color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13 }}>
                    <i className="fa fa-ban" style={{ marginRight: 5 }} />
                    No Activities yet
                  </div>}

                <Row style={{ margin: 10 }}>
                  <Col md={12} style={{ fontWeight: "bold", paddingTop: 3 }}>
                    {activities.length > 0 && <Pagination
                      total={total}
                      showSizeChanger
                      showQuickJumper
                      showTotal={total => `Total ${total} Activities`}
                      onChange={this.onPage}
                      pageSize={rows}
                      current={page}
                    />}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Activities;
