import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const hirewriterService = {
    addHirewriter,
    getHirewriters,
    updateHirewriter,
    deleteHirewriter,
    getHirewriter
};
export function getHirewriter(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}writerplan/${id}`, requestOptions).then(authService.handleResponse);
}

export function getHirewriters(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}hirewriter`, requestOptions).then(authService.handleResponse);
}

export function addHirewriter(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addwriterplan`, requestOptions).then(authService.handleResponse);
}

export function updateHirewriter(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateplan/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteHirewriter(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleteplan/${id}`, requestOptions).then(authService.handleResponse);
}
