import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Media,
  Button,
  Input,
  Badge
} from "reactstrap";
import { toast } from 'react-toastify';
import "views/index.css";
import { getResellers }  from 'services/resellerDownloadService';
import {getUser} from 'services/authService'
import SpinDiv from "components/SpinDiv.jsx";

import CustomPagination from "components/CustomPagination.jsx";
import { Pagination } from "antd";

class Reseller extends React.Component {
  state = {
    activeNav: 1,
    loading: true,
    resellers:[],
  	page: 1,
  	rows: 10,
  	total: 0,
    search: '',
    plans:[],
  };

  componentDidMount() {
    this.getResellers();
  }

  componentWillMount() {
  }

  

  getResellers = () => {
    const {plan,user, page, rows, search} = this.state;
    console.log(search)
    this.setState({loading: true});
    getResellers({user, page,plan, rows, search})
      .then((res) => {console.log(res)
        this.setState({
          resellers: res.resellers.data,
          plans:res.plans,
          page: res.resellers.current_page,
          total: res.resellers.total,
          loading: false,
        })
      },
      error => {
        this.setState({loading: false});
      });
  }

  


  onChange = (e, state) => {
    this.setState({[state]: e})
  }

  
 
  
  onPage = async (page, rows) => {

    await this.setState({page, rows});
    this.getResellers();
   
  }

  onSearch = () => {

  }

  

  

  
  showToast = msg => {
    toast.error(<div style={{padding:25}}>{msg}</div>);
  }


  render() {
    const {plans,resellers, total, page, rows, search, loading} = this.state;
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#3E70D7'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
       
        
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center" style={{justifyContent:'space-between', padding: '0 15px', overflowX: 'scroll', overflow: 'visible', flexWrap: 'nowrap'}}>
                    <div>
                      <h3 className="mb-0">Reseller Downloads <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h3>
                    </div>
                    <div className="mobile-flex">
                      <Input placeholder="Search..." style={{maxHeight: 28, marginRight: 5, minWidth: 80}}
                        value={search}
                        onChange={e=>this.onChange(e.target.value, 'search')}
                        onKeyUp={(e) => {if(e.key==='Enter'){this.getResellers();}}} />
                      <Button className="btn-icon btn-2 hide-mobile" color="secondary" size="sm" style={{marginRight: 10}} onClick={this.getResellers}>
                        <i className="fa fa-search" />
                      </Button>
                     
                    </div>
                  </Row>
                </CardHeader>
                <Table className={`align-items-center table-flush ${loading?'load-opacity':''}`} responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Role</th>
                      <th scope="col">Projects</th>
                      <th scope="col">Projects Paid</th>
                      <th scope="col">Project Not paid</th>

                      <th scope="col">Status</th>

                    </tr>
                  </thead>
                  <tbody>
                    {resellers.map((user, key) => {
                      return (
                        <tr key={key} className="hover-list" to="/"
                          onClick={() => {console.log('111')
                            this.props.history.push(`/admin/reseller_downloads/${user.id}`)
                          }}>
                          <th scope="row">
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#p"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={user.picture||require("assets/img/brand/user.jpg")}
                                />
                              </a>
                              <Media>
                                <span className="mb-0 text-sm">
                                  {user.name}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td>{user.email}</td>
                          <td>{user.phone}</td>
                          
                          <td>{user.admin == 1 ?'Administrator':'Reseller'}</td>
                          <td>{user.projects_count}</td>
                          <td>{user.project_sold}</td>
                          <td>{user.project_request}</td>
                          <td>
                            <Badge color="" className="badge-dot mr-4">
                              <i className={user.status ==1 ?'bg-success':'bg-danger'} />
                              {user.status ==1 ?'Active':'Inactive'}
                            </Badge>
                            
                          </td>
                        </tr>
                        );
                      })}
                  </tbody>
                </Table>
                {!loading&&resellers.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Reseller found
                </div>}
               
                <Row style={{margin:10}}>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {resellers.length > 0 && <Pagination
                  total={total}
                  showSizeChanger
                  showTotal={total => `Total ${total} Resellers`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Reseller;
