/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col, Table, CardHeader, Button } from "reactstrap";

import { getStat } from "services/projectService";
class Header extends React.Component {
  state = {
    activeNav: 1,
    loading: true,
    sold: "",
    upload: "",
    request: "",
    page: 1,
    rows: 10,
    total: 0,
    search: "",
    blogcategories: [],
    writer_requests: [],
    loading: false
  };

  componentDidMount() {
    this.getStat();
  }

  componentWillMount() { }

  getStat = () => {
    this.setState({ loading: true });
    getStat().then(
      res => {
        console.log(res);
        this.setState({
          sold: res.sold,
          prequest: res.prequest,
          upload: res.upload,
          writer_requests: res.writer_requests,
          loading: false
        });
      },
      error => {
        this.setState({ loading: false });
      }
    );
  };

  render() {
    const { sold, upload, prequest, writer_requests, loading } = this.state;
    return (
      <>
        <div
          className="header pb-8 pt-5 pt-md-7"
          style={{ backgroundColor: "#3E70D7" }}
        >
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col md="4">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Project Uploaded
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {upload}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Project Sold
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {sold}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Project Requested
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {prequest}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row style={{ marginTop: '20px' }}>
                <Col md="12">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardHeader className="border-0">
                      <Row className="align-items-center" style={{ justifyContent: 'space-between', padding: '0 15px', overflowX: 'scroll', overflow: 'visible', flexWrap: 'nowrap' }}>
                        <div>
                          <h3 className="mb-0">Writer Requests <span style={{ color: '#aaa', fontSize: 14, fontWeight: 'normal' }}> ({writer_requests.length})</span></h3>
                        </div>

                      </Row>
                    </CardHeader>
                    <CardBody>

                      <Table className={`align-items-center table-flush ${loading ? 'load-opacity' : ''}`} responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Plan</th>
                            <th scope="col">Name</th>
                            <th scope="col">Topic</th>
                            <th scope="col">Work Status</th>
                            <th scope="col">Requested Date</th>

                          </tr>
                        </thead>
                        <tbody>
                          {writer_requests.map((prequest, key) => {
                            console.log(prequest)

                            return (
                              <tr key={key} className="hover-list" to="/" >
                                <td>{prequest.plan !== null ? prequest.plan.plan_title : "plan not found"}</td>
                                <td>{prequest.full_name}</td>
                                <td>{prequest.topic}</td>
                                <td>{prequest.status == null ? 'In Progress' : 'Completed'}</td>
                                <td>{prequest.created_at}</td>
                               <td><Link to={`/admin/requests/${prequest.id}`}> <Button
                              color="primary"
                             
                              size="sm"
                            >
                           View
                            </Button></Link></td>

                              </tr>

                            );
                          })}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;
