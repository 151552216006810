import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Badge,
  Row,
  Col,
  Button,
  Input
} from "reactstrap";
import { toast } from 'react-toastify';
import DownloadProject from "./DownloadProject";

import "views/index.css";
import { addProject, getProjects }  from 'services/resellerProjectService';
import SpinDiv from "components/SpinDiv.jsx";
import CustomPagination from "components/CustomPagination.jsx";
import AddProject from "./AddProject.jsx";
import { Route, Redirect } from 'react-router-dom';
import {getUsers}  from 'services/resellerProjectService';
import {Pagination} from 'antd';


class Projects extends React.Component {
  state = {
    activeNav: 1,
    loading: true,
  	page: 1,
  	rows: 10,
  	total: 0,
    search: '',
    users: [],
    u:"",
    projects: [],
    categories:[],
    tags:[],
    departments:[],
    studylevels:[]
  };

  componentDidMount() {
    this.getProjects();
    this.getUsers();
  }

  componentWillMount() {
  }

  saveProject = (e) => {
    e.preventDefault();
    const {name, phone, email, address} = this.state;
    addProject({name, phone, email, address})
      .then(res => {
          this.setState({message : 'Project added successfully.'});
          this.props.history.push('/projects');
      },
    	error => {
    		console.log(error)
    	});
  }

  getProjects = () => {
    const {category,department,studylevel,user, page, rows, search} = this.state;
    this.setState({loading: true});
    getProjects({user, page,category,department,studylevel, rows, search})
      .then((res) => {console.log(res)
        this.setState({
          projects: res.projects.data,
          users: res.users,
          categories:res.categories,
          tags:res.tags,
          departments:res.departments,
          studylevels:res.studylevels,
          page: res.projects.current_page,
          total: res.projects.total,
          loading: false,
        })
      },
      error => {
        this.setState({loading: false});
      });
  }
  getUsers = () => {
    const {role, u,page, rows, search} = this.state;
    this.setState({loading: true});
    getUsers()
      .then((res) => {
        this.setState({
          u: res.user,
          loading: false
        })
      },
      error => {
        this.setState({loading: false});
      });
  }

  toggleModal = () => {
    toast.dismiss()
    this.setState({addProject: !this.state.addProject});
  }


  onChange = (e, state) => {
    this.setState({[state]: e})
  }
  toggleDownload= (downloadProject) => {
    this.setState({ downloadProject });
  };
  
  onPage = async (page, rows) => {
    const {search} = this.state;
    await this.setState({page, rows});
    this.getProjects();
    if(search===''){
      await this.getProjects();
    } else {
      await this.search();
    }
  }

  onSearch = () => {

  }

  

  

  
  showToast = msg => {
    toast.error(<div style={{padding:25}}>{msg}</div>);
  }


  render() {
    const {projects,u, downloadProject,categories,tags,departments,studylevels,users, addProject, total, page, rows, search, loading} = this.state;
     if (u.status == 0) {
        // status not authorised so redirect to login page
        return <Redirect to={{ pathname: '/auth/login'}} />
      }
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#3E70D7'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        {addProject&&
          <AddProject
            saved={this.getProjects}
            toggle={this.toggleModal}
            addProject={addProject}
            users={users}
            categories={categories}
            tags={tags}
            departments={departments}
            studylevels={studylevels}
          />}
          {downloadProject && (
          <DownloadProject
            saved={this.getProjects}
            downloadProject={downloadProject}
            toggle={() => this.setState({ downloadProject: null })}
          />
        )}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center" style={{justifyContent:'space-between', padding: '0 15px', overflowX: 'scroll', overflow: 'visible', flexWrap: 'nowrap'}}>
                    <div>
                      <h3 className="mb-0">Projects <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h3>
                    </div>
                    <div className="mobile-flex">
                      <Input placeholder="Search..." style={{maxHeight: 28, marginRight: 5, minWidth: 80}}
                        value={search}
                        onChange={e=>this.onChange(e.target.value, 'search')}
                        onKeyUp={(e) => {if(e.key==='Enter'){this.getProjects();}}} />
                      <Button className="btn-icon btn-2 hide-mobile" color="secondary" size="sm" style={{marginRight: 10}} onClick={this.getProjects}>
                        <i className="fa fa-search" />
                      </Button>
                    <Button
                        onClick={() => this.toggleModal()}
                        size="sm"
                        style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}
                      >
                        Create Project
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className={`align-items-center table-flush ${loading?'load-opacity':''}`} responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Author</th>
                      <th scope="col">Title</th>
                      <th scope="col">Department</th>
                      <th scope="col">Chapters</th>
                      <th scope="col">Price</th>
                      <th scope="col">Approve</th>
                      
                     
                    </tr>
                  </thead>
                  <tbody>
                    {projects.map((project, key) => {
                      return (
                        <tr >
                          <td>{project.user.name}</td>
                          
                          <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                          this.props.history.push('/reseller/projects/'+project.slug)
                        }}>{project.title}</td>
                        <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                          this.props.history.push('/reseller/projects/'+project.slug)
                        }}>{project.department_id}</td>
                          <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                          this.props.history.push('/reseller/projects/'+project.slug)
                        }}>{project.chapters}</td>
                        <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                          this.props.history.push('/reseller/projects/'+project.slug)
                        }}>{project.prices }</td>
                        <td>
                        <Badge color="" className="badge-dot mr-4">
                              <i className={project.approved == 1 ?'bg-success':'bg-danger'} />
                              {project.approved == 1 ?'Active':'Inactive'}
                            </Badge>
                        </td>
                        
                        
                          
                          <td>
                            
                            <Button
                              color="primary"
                              onClick={() => this.toggleDownload(project)}
                              size="sm"
                            >
                              Download
                            </Button>
                          </td>
                          
                        </tr>
                        
                        );
                      })}
                  </tbody>
                </Table>
                {!loading&&projects.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No project found
                </div>}
               
                <Row style={{margin:10}}>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {projects.length > 0 && <Pagination
                  total={total}
                  showSizeChanger
                  showTotal={total => `Total ${total} Projects`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Projects;
