import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const essayService = {
    getDownloads,
   
    getResellers
};
export function getDownloads(id,data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}reseller_downloads/${id}`, requestOptions).then(authService.handleResponse);
}

export function getResellers(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}reseller_downloads`, requestOptions).then(authService.handleResponse);
}

