import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const writerrequestService = {
    getRequests,
    getRequest,
};

export function getRequests(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}writer/requests`, requestOptions).then(authService.handleResponse);
}



export function getRequest(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}writer/requests/${id}`, requestOptions).then(authService.handleResponse);
}

export function deleteRequest(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}writer/deleterequest/${id}`, requestOptions).then(authService.handleResponse);
}

export function updateRequest(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}update/writerrequest/${data.id}`, requestOptions).then(authService.handleResponse);
}

