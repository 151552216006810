import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const hirewriterService = {
    getSeminar,
    updateSeminar,
    getSeminars
};
export function getSeminar(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}seminar/${id}`, requestOptions).then(authService.handleResponse);
}

export function getSeminars(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}seminar`, requestOptions).then(authService.handleResponse);
}

export function updateSeminar(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateseminar/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteSeminar(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleteseminar/${id}`, requestOptions).then(authService.handleResponse);
}
