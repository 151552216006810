import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { updateDownload,getDownload } from "services/downloadService";
import { getUser } from "services/authService";
import SpinDiv from "components/SpinDiv.jsx";

class Download extends React.Component {
  constructor(props) {
    super(props);
    const { location: state } = props;
    this.state = {
      loading: false,
      edit: false,
      change: false,
      download: state && state.download ? state.download : null,
      validation: {},
      id: props.match.params.id,
      users: [],
      status:""
    };
  }

  componentDidMount() {
    this.getDownload();
  }

  getDownload = () => {
    const { id } = this.state;
    this.setState({ loading: true });
    getDownload(id).then(
      (res) => {
        console.log(res.delete_status);
        this.setState({
          loading: false,
          download: { ...res.download },
          availability:{...res.project},
          delete_status:{...res.delete_status},
          initialDownload: { ...res.download },
          users: res.users,
          //.map(p=>({label:p.name, value:p.id})),
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  toggleEdit = () => {
    const { initialDownload,initialStatus } = this.state;
    this.setState({ edit: !this.state.edit, delete_status:{...initialStatus},download: { ...initialDownload } });
  };

  togglePassword = () => {
    this.setState({ change: !this.state.change });
  };

  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { download, delete_status,validation } = this.state;
    console.log(download);
    const {
      title,
      status,
      
      id,
    } = download;
    await this.setState({
      validation: {
        ...validation,
        status: status !== "",
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveDownload();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(
        () =>
          toast.error(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold" }}>Errors:</p>
              {errors.map((v) => (
                <p key={v} style={{ margin: 0, fontSize: 14 }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          ),
        250
      );
    }
  };

  saveDownload = () => {
    this.setState({ saving: true });
    const { download } = this.state;
    updateDownload({ ...download }).then(
      (res) => {
        console.log(res);
        this.setState({
          saving: false,
          edit: false,
          download: { ...res.download },
          initialDownload: { ...download },
          //target: res.target
        });
        
        this.showToast("Download request Approved");
      },
      (error) => {
        console.log(error);
        this.setState({ saving: false });
        alert("This project has been deleted from the server");
      }
    );
  };

  validationRules = (field) => {
    if (field === "status") {
      return "status is required";
    }
  };

  onChange = (e, state) => {
    console.log(e);
    const { download } = this.state;

    this.setState({ download: { ...download, [state]: e } });
  };

  showToast = (msg) => {
    toast.error(<div style={{ padding: 20 }}>{msg}</div>);
  };

  render() {
    const {
      download,
      loading,
      validation,
      edit,
      delete_status,
      initialStatus,
      initialDownload,
      saving,
      change,
      users,
      availability
    } = this.state;
    const Required = () => <span style={{ color: "red" }}>*</span>;
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        minWidth: 170,
        height: 46,
        borderColor: "hsl(0,0%,95%)",
        boxShadow: "1px 1px 1px rgba(50, 50, 50, 0.1)",
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      },
    };

    return (
      <>
        <div
          className="header pb-8 pt-5 pt-md-6"
          style={{ backgroundColor: "#3E70D7" }}
        >
          <Container fluid>
            <div className="header-body">
              <Link
                to="/admin/downloads"
                className="white-link"
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <i className=" ni ni-bold-left" style={{ marginRight: 5 }} />
                <span>Back to Downloads</span>
              </Link>
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
           
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                {loading && <SpinDiv text={"Loading..."} />}
                {saving && <SpinDiv text={"Saving..."} />}
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      {initialDownload && (
                        <h3 className="mb-0">
                          Owner: {initialDownload.user.name}
                        </h3>
                      )}
                    </Col>
                    {initialDownload &&
                    (getUser().admin ||
                      getUser().id === initialDownload.user_id) ? (
                      <Col className="text-right" xs="4">
                        {download && (
                          <Button
                            color={edit ? "secondary" : "success"}
                            onClick={this.toggleEdit}
                            size="sm"
                          >
                            {edit ? "Discard Changes" : "Edit"}
                          </Button>
                        )}
                      </Col>
                    ) : (
                      <Col className="text-right" xs="4">
                        {download && (
                          <Button
                            color="success"
                            onClick={this.addSubDownload}
                            size="sm"
                          >
                            Add download
                          </Button>
                        )}
                      </Col>
                    )}
                  </Row>
                </CardHeader>
                {console.log(delete_status)}
                {download && (
                  <CardBody>
                  
                    <Form>
                      <h6 className="heading-small text-muted mb-2">
                        Download Update
                      </h6>
                      <div>
                      <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pagecount"
                              >
                                Availability
                              </label>
                              {console.log(this.state.availability.available)}
                              <Input
                                value={this.state.availability.available}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                 disabled                               
                              ></Input>
                            </FormGroup>
                          </Col>

                        </Row>
                        <Row>
                          <Col lg="9">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pagecount"
                              >
                                Download Link
                              </label>
                              <Input
                             
                                value={availability.filename == null ?"Awaiting upload" : "https://myproject.ng/api/userdownload/"+download.reference_code}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                                              
                              ></Input>
                            </FormGroup>
                          </Col>

                        </Row>
                      <Row>
                      <Col lg="6">
                      
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pagecount"
                              >
                                Email
                              </label>
                              <Input
                                value={download.email}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                                               
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                      
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pagecount"
                              >
                                Project Title
                              </label>
                              <Input
                                value={download.title}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                                               
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pagecount"
                              >
                                File Name
                              </label>
                              <Input
                                value={availability.filename == null ? "Awaiting upload": download.filename}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                 disabled                               
                              ></Input>
                            </FormGroup>
                          </Col>

                        </Row>
                        
                        
                        
                        <Row>
                      <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pagecount"
                              >
                                Phone
                              </label>
                              <Input
                                value={download.phone }
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                 disabled={!edit}                               
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                      <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pagecount"
                              >
                                Amount paid
                              </label>
                              <Input
                                value={download.amount_paid}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                 disabled={!edit}
                                  id="amount_paid"
                                onChange={async (e) => {
                                    await this.onChange(
                                      e.target.value,
                                      "amount_paid"
                                    );
                                  }}                               
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pagecount"
                              >
                                Payment option
                              </label>
                              <Input
                                value={download.payment_option}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                 disabled={!edit} 
                                 id="payment_option"
                                onChange={async (e) => {
                                    await this.onChange(
                                      e.target.value,
                                      "payment_option"
                                    );
                                  }}
                                                             
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                        <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pagecount"
                              >
                                Pay Reseller
                              </label>
                              <Input
                                value={download.amount_paid_reseller}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                 disabled={!edit}
                                  id="amount_paid"
                                onChange={async (e) => {
                                    await this.onChange(
                                      e.target.value,
                                      "amount_paid_reseller"
                                    );
                                  }}                               
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                            <FormGroup style={{margin: 0}}>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                             
                              <Input
                                className="custom-control-input"
                                
                                checked={download.status == 1 ? true:false || false}
                                disabled={!edit}
                                id="status"
                                onChange={async (e) => {
                                    await this.onChange(
                                      e.target.checked,
                                      "status"
                                    );
                                  }}
                               
                                type="checkbox"
                              />
                              <label className="custom-control-label" htmlFor="status">
                                Approve Download
                              </label>
                            </div>
                          </FormGroup>
                            </Col>
                        </Row>
                        
                      </div>
                      <hr style={{ marginBottom: 20, marginTop: 10 }} />
                      {edit && (
                        <div>
                          <Button
                            color="success"
                            type="button"
                            disabled={saving}
                            onClick={this.onSave}
                            style={{
                              backgroundColor: "#EC3237",
                              borderColor: "#EC3237",
                              color: "#fff",
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      )}
                    </Form>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Download;
