import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const studyLevelService = {
    addStudy,
    getStudies,
    updateStudy,
    deleteStudy
};

export function getStudies(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}studylevels`, requestOptions).then(authService.handleResponse);
}

export function addStudy(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addstudylevel`, requestOptions).then(authService.handleResponse);
}

export function updateStudy(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updatestudylevel/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteStudy(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletestudylevel/${id}`, requestOptions).then(authService.handleResponse);
}