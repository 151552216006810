import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { getHirewriter, updateHirewriter } from "services/hirewriterService";
import { getUser } from "services/authService";
import SpinDiv from "components/SpinDiv.jsx";

export class HirewriterP extends React.Component {
  constructor(props) {
    super(props);
    const { location: state } = props;
    this.state = {
      loading: false,
      edit: false,
      change: false,
      plan: state && state.plan ? state.plan : null,
      validation: {},
      categories: [],
      format: {},
      id: props.match.params.id,
      users: [],
      
    };
  }

  componentDidMount() {
    this.getHirewriter();
  }

  getHirewriter = () => {
    const { id } = this.state;
    this.setState({ loading: true });
    getHirewriter(id).then(
      res => {
        console.log(res);
        this.setState({
          loading: false,
          plan: { ...res.plan },
          initialPlan: { ...res.plan },
          categories: res.categories
          //.map(p=>({label:p.name, value:p.id})),
        });
      },
      error => {
        this.setState({ loading: false });
      }
    );
  };

  toggleEdit = () => {
    const { initialPlan } = this.state;
    this.setState({ edit: !this.state.edit, plan: { ...initialPlan } });
  };

  togglePassword = () => {
    this.setState({ change: !this.state.change });
  };

  onSave = async e => {
    e.preventDefault();
    await toast.dismiss();
    const { plan, validation } = this.state;
    const {
      plancategory_id,
      plan_type,
      plan_title,
      price,
      content_nature,
      proposal,
      allow_to_choose_method,
      chapters,
      reference,
      questionaire,
      format,
      page_length,
      project_format,
      bgcolor,
      plagiarism_level,
      chapterization,
      special_request,
      source_code,
      plagiarism_checker,
      supported_department,
      data_analysis,
      appendixe,
      support_service,
      abstract,
      prelim_page,
      id
    } = plan;
    await this.setState({
      validation: {
        ...validation,
        plan_title: plan_title !== "",
        plan_type: plan_type !== "",
        price: price !== "",
        content_nature: content_nature !== "",
        allow_to_choose_method:allow_to_choose_method!=="",
        proposal: proposal !== "",
        chapters: chapters !== "",
        reference: reference !== "",
        questionaire: questionaire !== "",
        format: format !== "",
        project_format:project_format !== "",
        page_length: page_length !== "",
        plagiarism_level: plagiarism_level !== "",
        chapterization: chapterization !== "",
        special_request: special_request !== "",
        source_code: source_code !== "",
        plagiarism_checker: plagiarism_checker !== "",
        supported_department: supported_department !== "",
        data_analysis: data_analysis !== "",
        abstract: abstract !== "",
        prelim_page: prelim_page !== "",
        plancategory_id: plancategory_id !== "",
        bgcolor: bgcolor !== ""
      }
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.savePlan();
    } else {
      const errors = Object.keys(this.state.validation).filter(id => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(
        () =>
          toast.error(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold" }}>Errors:</p>
              {errors.map(v => (
                <p key={v} style={{ margin: 0, fontSize: 14 }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          ),
        250
      );
    }
  };

  savePlan = () => {
    this.setState({ saving: true });
    const { plan } = this.state;
    updateHirewriter({ ...plan }).then(
      res => {
        console.log(res);
        this.setState({
          saving: false,
          edit: false,
          plan: { ...res.plan },
          initialPlan: { ...plan }
          //target: res.target
        });
        this.getHirewriter(plan.id);
        this.showToast("Hire Writer Plan Updated");
      },
      error => {
        console.log(error);
        this.setState({ saving: false });
      }
    );
  };

  validationRules = field => {
    if (field === "name") {
      return "Name is required";
    } else if (field === "plan_type") {
      return "Plan Type is Required";
    } else if (field === "plan_title") {
      return "Plan title is required";
    } else if (field === "price") {
      return "price is required";
    } else if(field === "project_format"){
      return "Project format is required";
    } else if (field === "content_nature") {
      return "Content Nature is required";
    }  else if (field === "allow_to_choose_method") {
      return "Content Nature is required";
    } 
    else if (field === "proposal") {
      return "Proposal is required";
    } else if (field === "chapters") {
      return "Chapters is required";
    } else if (field === "reference") {
      return "reference is required";
    } else if (field === "questionaire") {
      return "questionaire is required";
    } else if (field === "format") {
      return "format is required";
    } else if (field === "page_length") {
      return "page length is required";
    } else if (field === "plagiarism_level") {
      return "plagiarism level is required";
    } else if (field === "chapterization") {
      return "chapterization is required";
    } else if (field === "special_request") {
      return "special_request is required";
    } else if (field === "source_code") {
      return "source code is required";
    } else if (field === "plagiarism_checker") {
      return "plagiarism checker is required";
    } else if (field === "supported_department") {
      return "supported department is required";
    } else if (field === "data_analysis") {
      return "data analysis is required";
    } else if (field === "abstract") {
      return "Abstract is required";
    } else if (field === "prelim_page") {
      return "Preliminary page is required";
    } else if (field === "plancategory_id") {
      return "Plan Category is required";
    } else if (field === "bgcolor") {
      return "Background color is required";
    }
  };

  onChange = (e, state) => {
    console.log(e);
    const { plan } = this.state;

    this.setState({ plan: { ...plan, [state]: e } });
  };

  showToast = msg => {
    toast.error(<div style={{ padding: 20 }}>{msg}</div>);
  };

  render() {
    const {
      plan,
      loading,
      validation,
      edit,
      categories,
      initialPlan,
      saving,
      change
    } = this.state;
    const Required = () => <span style={{ color: "red" }}>*</span>;
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        minWidth: 170,
        height: 46,
        borderColor: "hsl(0,0%,95%)",
        boxShadow: "1px 1px 1px rgba(50, 50, 50, 0.1)"
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      }
    };

    return (
      <>
        <div
          className="header pb-8 pt-5 pt-md-6"
          style={{ backgroundColor: "#3E70D7" }}
        >
          <Container fluid>
            <div className="header-body">
              <Link
                to="/admin/settings/hirewriter"
                className="white-link"
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer"
                }}
              >
                <i className=" ni ni-bold-left" style={{ marginRight: 5 }} />
                <span>Back to Hirewriter Plans</span>
              </Link>
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            {plan && (
              <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                <Card className="card-profile shadow">
                  {plan && (
                    <CardBody className="pt-0 pt-md-0">
                      <Row>
                        <div className="col">
                          <div className="card-profile-stats d-flex justify-content-center">
                            <div>
                              <span>Plan type: </span>
                              <span
                                style={{ display: "unset" }}
                                className="heading"
                              >
                                {plan.plan_title}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Row>
                      <div className="text-center">
                        {plan.chapters && (
                          <div className="h5 mt-1 font-weight-300">
                            Chapters:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.chapters}
                            </span>
                          </div>
                        )}
                        {plan.source_code && (
                          <div className="h5 mt-1 font-weight-300">
                            Source code:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.source_code}
                            </span>
                          </div>
                        )}
                        {plan.project_format && (
                          <div className="h5 mt-1 font-weight-300">
                            Project Format:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.project_format}
                            </span>
                          </div>
                        )}
                        {plan.abstract && (
                          <div className="h5 mt-1 font-weight-300">
                            Abstract:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.abstract}
                            </span>
                          </div>
                        )}
                        {plan.bgcolor && (
                          <div className="h5 mt-1 font-weight-300">
                            Background Color:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.bgcolor}
                            </span>
                          </div>
                        )}
                        {plan.chapterization && (
                          <div className="h5 mt-1 font-weight-300">
                            Chapterization:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.chapterization}
                            </span>
                          </div>
                        )}
                        {plan.plancategory_id && (
                          <div className="h5 font-weight-300">
                            <i className="ni location_pin mr-2" />
                            Category:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {plan.plancategory_id}
                            </span>
                          </div>
                        )}
                        {plan.plan_type && (
                          <div className="h5 mt-1 font-weight-300">
                            Plan description:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {plan.plan_type}
                            </span>
                          </div>
                        )}
                        {plan.questionaire && (
                          <div className="h5 mt-1 font-weight-300">
                            Questionaire:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {plan.questionaire}
                            </span>
                          </div>
                        )}

                        {plan.reference && (
                          <div className="h5 mt-1 font-weight-300">
                            Reference:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {plan.reference}
                            </span>
                          </div>
                        )}
                        {plan.proposal && (
                          <div className="h5 mt-1 font-weight-300">
                            Proposal:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {plan.proposal}
                            </span>
                          </div>
                        )}

                        {plan.plagiarism_checker && (
                          <div className="h5 mt-1 font-weight-300">
                            Plagiarism checker:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.plagiarism_checker}
                            </span>
                          </div>
                        )}
                        {plan.content_nature && (
                          <div className="h5 mt-1 font-weight-300">
                            Content Nature:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.content_nature}
                            </span>
                          </div>
                        )}
                        {plan.special_request && (
                          <div className="h5 mt-1 font-weight-300">
                            Special Request:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.special_request}
                            </span>
                          </div>
                        )}
                        {plan.prelim_page && (
                          <div className="h5 mt-1 font-weight-300">
                            Preliminary Page:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.prelim_page}
                            </span>
                          </div>
                        )}
                        {plan.page_length && (
                          <div className="h5 mt-1 font-weight-300">
                            No. of Pages:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.page_length}
                            </span>
                          </div>
                        )}

                        {plan.support_service && (
                          <div className="h5 mt-1 font-weight-300">
                            Support service:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.support_service}
                            </span>
                          </div>
                        )}
                        {plan.plagiarism_level && (
                          <div className="h5 mt-1 font-weight-300">
                            Plagiarism Level:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.plagiarism_level}
                            </span>
                          </div>
                        )}
                        {plan.appendixe && (
                          <div className="h5 mt-1 font-weight-300">
                            Appendix:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.appendixe}
                            </span>
                          </div>
                        )}
                        {plan.data_analysis && (
                          <div className="h5 mt-1 font-weight-300">
                            Data Analysis:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.data_analysis}
                            </span>
                          </div>
                        )}
                        {plan.allow_to_choose_method && (
                          <div className="h5 mt-1 font-weight-300">
                            Client Data Analysis view:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.allow_to_choose_method}
                            </span>
                          </div>
                        )}


                        {plan.price && (
                          <div className="h5 mt-1 font-weight-300">
                            Price:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.price}
                            </span>
                          </div>
                        )}
                        {plan.supported_department && (
                          <div className="h5 mt-1 font-weight-300">
                            Supported Department:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.supported_department}
                            </span>
                          </div>
                        )}
                         {plan.custom_field_one && (
                          <div className="h5 mt-1 font-weight-300">
                            Custom Field one:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.custom_field_one}
                            </span>
                          </div>
                        )}
                         {plan.custom_field_one && (
                          <div className="h5 mt-1 font-weight-300">
                            Custom Field Two:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.custom_field_two}
                            </span>
                          </div>
                        )}
                         {plan.custom_field_one && (
                          <div className="h5 mt-1 font-weight-300">
                            Custom Field Three:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.custom_field_three}
                            </span>
                          </div>
                        )}
                        {plan.format && (
                          <div className="h5 mt-1 font-weight-300">
                            Format:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {plan.format}
                            </span>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  )}
                </Card>
              </Col>
            )}
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                {loading && <SpinDiv text={"Loading..."} />}
                {saving && <SpinDiv text={"Saving..."} />}
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8"></Col>
                    {initialPlan && getUser().admin ? (
                      <Col className="text-right" xs="4">
                        {plan && (
                          <Button
                            color={edit ? "secondary" : "success"}
                            onClick={this.toggleEdit}
                            size="sm"
                          >
                            {edit ? "Discard Changes" : "Edit"}
                          </Button>
                        )}
                      </Col>
                    ) : (
                      <Col className="text-right" xs="4"></Col>
                    )}
                  </Row>
                </CardHeader>
                {plan && (
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-2">
                        Hirewriter information
                      </h6>
                      <div>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                style={{
                                  color:
                                    validation.plan_title === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Plan type
                              </label>
                              <Input
                                value={plan.plan_title}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "plan_title"
                                  );
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-plantype"
                                style={{
                                  color:
                                    validation.plan_type === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Plan description
                              </label>
                              <Input
                                value={plan.plan_type}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "plan_type"
                                  );
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-supportdept"
                                style={{
                                  color:
                                    validation.plan_supported_department ===
                                    false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Supported Department
                              </label>
                              <Input
                                value={plan.supported_department}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "supported_department"
                                  );
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-plantype"
                               
                              >
                                <Required />
                                Custom Field One
                              </label>
                              <Input
                                value={plan.custom_field_one}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "custom_field_one"
                                  );
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-plantype"
                               
                              >
                                <Required />
                                Custom Field Two
                              </label>
                              <Input
                                value={plan.custom_field_two}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "custom_field_two"
                                  );
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-plantype"
                               
                              >
                                <Required />
                                Custom Field Three
                              </label>
                              <Input
                                value={plan.custom_field_three}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "custom_field_three"
                                  );
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                          </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-category_id"
                                style={{
                                  color:
                                    validation.plancategory_id === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Category
                              </label>
                              <Input
                                value={plan.plancategory_id}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "plancategory_id"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Category
                                </option>
                                {categories.map(p => (
                                  <option value={p.name} key={p.id}>
                                    {p.name}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-plagiarism_checker"
                                style={{
                                  color:
                                    validation.plagiarism_checker === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Plagiarism Checker
                              </label>
                              <Input
                                value={plan.plagiarism_checker}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "plagiarism_checker"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Plagiarism Checker
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                                ))
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-reference"
                                style={{
                                  color:
                                    validation.questionaire === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Questionaire
                              </label>
                              <Input
                                value={plan.questionaire}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "questionaire"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Questionaire
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-contentnature"
                                style={{
                                  color:
                                    validation.content_nature === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Content Nature
                              </label>
                              <Input
                                value={plan.content_nature}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "content_nature"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Choose Content Nature
                                </option>
                                <option value="guide">Guide</option>
                                <option value="advance">Advance</option>
                                <option value="professional">
                                  Professional
                                </option>
                                <option value="standard">Standard</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-reference"
                                style={{
                                  color:
                                    validation.proposal === false ? "red" : null
                                }}
                              >
                                <Required />
                                Proposal
                              </label>
                              <Input
                                value={plan.proposal}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "proposal"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Proposal
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-reference"
                                style={{
                                  color:
                                    validation.reference === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Chapterization
                              </label>
                              <Input
                                value={plan.chapterization}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "chapterization"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Chapterization
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-department_id"
                                style={{
                                  color:
                                    validation.reference === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Reference
                              </label>
                              <Input
                                value={plan.reference}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "reference"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Reference
                                </option>
                                <option value="yes">yes</option>
                                <option value="no">No</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-prices"
                              >
                                <Required />
                                Price
                              </label>
                              <Input
                                value={plan.price}
                                type="number"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(e.target.value, "price");
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pagecount"
                              >
                                <Required />
                                Chapters
                              </label>
                              <Input
                                value={plan.chapters}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "chapters"
                                  );
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-specialrequest"
                                style={{
                                  color:
                                    validation.reference === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Special Request
                              </label>
                              <Input
                                value={plan.special_request}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "special_request"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Special Request
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row></Row>
                        <Row>
                        
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                <Required />
                                No. of Pages
                              </label>
                              <Input
                                value={plan.page_length}
                                type="number"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "page_length"
                                  );
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-dataanalysis"
                                style={{
                                  color:
                                    validation.allow_to_choose_method === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Data Analysis CLient view
                              </label>
                              <Input
                                value={plan.allow_to_choose_method}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "allow_to_choose_method"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Data Analysis client View
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-available"
                              >
                                Choose Support service
                              </label>
                              <Input
                                value={plan.support_service}
                                type="select"
                                bsSize="sm"
                                disabled={!edit}
                                style={{
                                  marginRight: 10,
                                  width: "100%",
                                  color:
                                    validation.support_service === false
                                      ? "red"
                                      : null
                                }}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "support_service"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Choose Support service
                                </option>
                                <option value="One-time Support Service">
                                  One-time Support Service
                                </option>
                                <option value="Upto 3-times Support">
                                  Upto 3-times Support
                                </option>
                                <option value="Full-time Support Service">
                                  Full-time Support Service
                                </option>
                                <option value="No Support">None</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-reference"
                              >
                                Choose Format
                              </label>
                              <Input
                                value={plan.format}
                                disabled={!edit}
                                type="text"
                                bsSize="sm"
                                style={{
                                  marginRight: 10,
                                  width: "100%",
                                  color:
                                    validation.format === false ? "red" : null
                                }}
                                onChange={async e => {
                                  await this.onChange(e.target.value, "format");
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-available"
                              >
                                Choose Abstract
                              </label>
                              <Input
                                value={plan.abstract}
                                type="select"
                                bsSize="sm"
                                disabled={!edit}
                                style={{
                                  marginRight: 10,
                                  width: "100%",
                                  color:
                                    validation.abstract === false ? "red" : null
                                }}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "abstract"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Choose Abstract
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-level"
                                style={{
                                  color:
                                    validation.plagiarism_level === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Plagiarism Level
                              </label>
                              <Input
                                value={plan.plagiarism_level}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "plagiarism_level"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Plagiarism Level
                                </option>
                                <option value="high">High</option>
                                <option value="medium">Medium</option>
                                <option value="low">Low</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-reference"
                                style={{
                                  color:
                                    validation.appendixe === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Appendix
                              </label>
                              <Input
                                value={plan.appendixe}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "appendixe"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Appendixe
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-reference"
                                style={{
                                  color:
                                    validation.prelim_page === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Preliminary Page
                              </label>
                              <Input
                                value={plan.prelim_page}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "prelim_page"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Preliminary Page
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-dataanalysis"
                                style={{
                                  color:
                                    validation.data_analysis === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Data Analysis
                              </label>
                              <Input
                                value={plan.data_analysis}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "data_analysis"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Data Analysis
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-dataanalysis"
                                style={{
                                  color:
                                    validation.source_code === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Source Code
                              </label>
                              <Input
                                value={plan.source_code}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "source_code"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Source Code
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-bgcolor"
                                style={{
                                  color:
                                    validation.bgcolor === false ? "red" : null
                                }}
                              >
                                <Required />
                                Background color
                              </label>
                              <Input
                                value={plan.bgcolor}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "bgcolor"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Background color
                                </option>
                                <option value="#00E1E1">Light Blue</option>
                                <option value=" #FFD70A">Gold color</option>
                                <option value="#214A74">Marble Blue</option>
                                <option value="#CB2521">Chili Red</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-plagiarism_checker"
                                style={{
                                  color:
                                    validation.plagiarism_checker === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Project Format
                              </label>
                              <Input
                                value={plan.project_format}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "project_format"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Allow Project Format upload?
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                                ))
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr style={{ marginBottom: 20, marginTop: 10 }} />
                      {edit && (
                        <div>
                          <Button
                            color="success"
                            type="button"
                            disabled={saving}
                            onClick={this.onSave}
                            style={{
                              backgroundColor: "#EC3237",
                              borderColor: "#EC3237",
                              color: "#fff"
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      )}
                    </Form>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default HirewriterP;
