import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Badge,
  //UncontrolledTooltip,
  Media,
  Table,
  Container,
  Row,
  Col,
  Button,
  Input
} from "reactstrap";
import { Route, Redirect } from 'react-router-dom';


import { toast } from 'react-toastify';

import "views/index.css";
import {getUsers}  from 'services/resellerProjectService';
import SpinDiv from "components/SpinDiv.jsx";
import CustomPagination from "components/CustomPagination.jsx";

class User extends React.Component {
  state = {
    activeNav: 1,
    loading: true,
  	page: 1,
  	rows: 10,
  	total: 0,
    users: [],
    user:""
  };

  componentDidMount() {
    this.getUsers();
  }

  componentWillMount() {
  }

  

  getUsers = () => {
    const {role, state, territory, axis,user, zone, page, rows, search} = this.state;
    this.setState({loading: true});
    getUsers()
      .then((res) => {
        this.setState({
          user: res.user,
          loading: false
        })
      },
      error => {
        this.setState({loading: false});
      });
  }

  

  toggleFilter = () => {
    this.setState({showFilter: !this.state.showFilter});
  }

  onChange = (e, state) => {
    this.setState({[state]: e})
  }

  onSearch = () => {

  }

  

  

  render() {
    const {user,  
       total, page, rows, search, loading} = this.state;

       if (user.status == 0) {
        // status not authorised so redirect to login page
        return <Redirect to={{ pathname: '/auth/login'}} />
    }
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#3E70D7'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">My Profile <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}></span></h3>
                    </div>
                    <div style={{display: 'flex'}}>
                      
                     
                    </div>
                  </Row>
                </CardHeader>
                
                <Table className={`align-items-center table-flush ${loading?'load-opacity':''}`} responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Role</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                      
                        <tr className="hover-list" to="/"
                          onClick={() => {console.log('111')
                            this.props.history.push(`/reseller/profile/detail`)
                          }}>
                          <th scope="row">
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#p"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={user.picture||require("assets/img/brand/user.jpg")}
                                />
                              </a>
                              <Media>
                                <span className="mb-0 text-sm">
                                  {user.name}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td>{user.email}</td>
                          <td>{user.phone}</td>
                          <td>{`${user.admin == 1 ?'Administrator':'Reseller'}`}</td>
                          <td>
                            <Badge color="" className="badge-dot mr-4">
                              <i className={user.status?'bg-success':'bg-danger'} />
                              {user.status ?'Active':'Inactive'}
                            </Badge>
                            
                            
                          </td>
                        </tr>
                        
                      
                  </tbody>
                </Table>
                
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default User;
