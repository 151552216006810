/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Switch,Route} from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import routes from "routes.js";
import sroutes from "staffRoute.js";
import {SecureRoute} from '../services/SecureRoute'
import Categories from "views/settings/categories/Index.jsx";
import Bcategories from "views/settings/bcategories/Index.jsx";
import Btags from "views/settings/btags/Index.jsx";
import Ptags from "views/settings/ptags/Index.jsx";
import {getUser} from "../services/authService";

import Hirewriter from "views/settings/hirewriter/Index.jsx";
import HirewriterP from "../views/settings/hirewriter/Hirewriter.jsx";
import HirewriterCategories from "views/settings/hirewritercat/Index.jsx";
import Index from "../views/Index";
import Departments from "../views/settings/departments/Index";
import StudyLevels from "../views/settings/studylevels/Index";
import Project from "views/projects/Project.jsx";
import My404Component from "../views/notfound/My404Component";
import Download from "views/downloads/Download.jsx";
import ResellerDownload from "views/reseller_downloads/Download.jsx";
import Blog from "views/blogs/Blog.jsx";
import SingleRequest from "../views/requests/request";
import Singleseminar from "../views/seminar/seminar";
import Singleessay from "../views/essay/Essay";
import Report from "../views/reports/Report";
import Singleassignment from "../views/assignment/Assignment";


import User from "views/users/User.jsx";
class Admin extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
     
      if (prop.layout === "/admin") {
        console.log(prop.role)
        return (
          <SecureRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getStaffRoute = routes => {
    return routes.map((prop, key) => {
     
      if (prop.layout === "/staff") {
        console.log(prop.role)
        return (
          <SecureRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    let user = JSON.parse(localStorage.getItem("user"));
    return (
      <>
        <Sidebar
          {...this.props}
          routes={getUser().role === "staff" ? sroutes : routes}
          logo={{
            innerLink: "/admin/home",
            imgSrc: require("assets/img/brand/logo.png"),
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
           <SecureRoute exact path="/admin" component={Index}/>
          
           <Route exact path="/admin/projects/:id" render={props => <Project {...props} />} />
           <Route exact path="/admin/downloads/:id" render={props => <Download {...props} />} />
           <Route exact path="/admin/reseller_downloads/:id" render={props => <ResellerDownload {...props} />} />
           
           <Route exact path="/admin/blogs/:id" render={props => <Blog {...props} />} />
           <Route exact path="/admin/requests/:id" render={props => <SingleRequest {...props} />} />
           
           <Route exact path="/admin/users/:id" render={props => <User {...props} />} />
           <Route exact path="/admin/settings/hirewriter" render={props => <Hirewriter {...props} />} />
           <Route exact path="/admin/settings/hirewriters/:id" render={props => <HirewriterP {...props} />} />
           <Route exact path="/admin/seminar/:id" render={props => <Singleseminar {...props} />} />
           <Route exact path="/admin/essay/:id" render={props => <Singleessay {...props} />} />
           <Route exact path="/admin/assignment/:id" render={props => <Singleassignment {...props} />} />
           <Route exact path="/admin/report/:id" render={props => <Report {...props} />} />
           <Route exact path="/admin/settings/hirewritercat" render={props => <HirewriterCategories {...props} />} />
           <Route exact path="/admin/settings/categories" render={props => <Categories {...props} />} />
           <Route exact path="/admin/settings/bcategories" render={props => <Bcategories {...props} />} />
           <Route exact path="/admin/settings/blogtags" render={props => <Btags {...props} />} />
           <Route exact path="/admin/settings/projecttags" render={props => <Ptags {...props} />} />
           <Route exact path="/admin/settings/departments" render={props => <Departments {...props} />} />
           <Route exact path="/admin/settings/studylevel" render={props => <StudyLevels {...props} />} />
          
           
            {getUser().role === "staff" ? this.getRoutes(sroutes) :this.getRoutes(routes)}
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default Admin;
