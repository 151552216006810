import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { getUser, updateUser }  from 'services/resellerProjectService';
import SpinDiv from "components/SpinDiv.jsx";

const emailReg=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneReg = /^([0]\d{10})$/;
class Profile extends React.Component {
	constructor(props) {
		super(props);
    const {location: state} = props;
    this.state = {
      loading: false,
      edit: false,
      change: false,
      user: state&&state.user?state.user:null,
      validation: {},
      id: props.match.params.id
    };
	}

  componentDidMount() {
    this.getUser();
  }

  getUser = () => {
    const {id} = this.state
    this.setState({loading: true});
    getUser()
      .then((res) => {console.log(res)
        this.setState({
          loading: false,
          user: res.user,
          downloads:res.downloads,
          authuser:res.authuser,
          initialUser: {...res.user},
        })
      },
      error => {
        this.setState({loading: false});
      });
  }

  toggleEdit = () => {
    const {initialUser} = this.state
    this.setState({edit: !this.state.edit, user: {...initialUser}});
  }

  togglePassword = () => {
    this.setState({change: !this.state.change});
  }

  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {user, validation} = this.state;
    const {firstname, lastname,bank_name,bank_account, message,subject,email,phone, password, id} = user;
    await this.setState({
      validation: {
        ...validation,
        firstname: firstname!==''&&firstname!==null,
        //bank_name: bank_name!==''&&bank_name!==null,
        //bank_account: bank_account!==''&&bank_account!==null,
        lastname: (lastname!==''&&lastname!==null),
        email: emailReg.test(email),
        phone:phoneReg.test(phone)
      }
    })
    if(Object.values(this.state.validation).every(Boolean)){
      this.saveUser();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id]
      })
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(()=>toast.error(
        <div style={{padding:'10px 20px'}}>
          <p style={{margin: 0, fontWeight: 'bold'}}>Errors:</p>
          {errors.map(v => (
            <p key={v} style={{margin: 0, fontSize: 14}}>* {this.validationRules(v)}</p>
          ))}
        </div>
      ), 250);
    }
  }

  saveUser = () => {
    this.setState({saving: true});
    const {user} = this.state;
    updateUser(user)
    //console.log(user)
      .then(res => {
          console.log(res)
          this.setState({saving: false});
          this.setState({edit: false, user: res.user});
          this.showToast('User Updated')
      },
    	error => {
    		console.log(error)
        if(error.email){
          this.showToast('A user with this Email address already exists')
        }
        if(error.phone){
          this.showToast('A user with this Phone number already exists')
        }
        this.setState({saving: false});
    	});
  }

  validationRules = field => {
    if(field==='firstname'){
      return 'First name is required';
    } else if(field==='lastname'){
      return 'Last name is required';
    } else if(field==='email'){
      return 'Email is invalid';
    }
    
     else if(field==='phone'){
    return 'Phone is invalid';
  }
  }

  onChange = (e, state) => {
    const {user} = this.state
    
      this.setState({user: {...user, [state]: e}})
    
  }


  showToast = msg => {
    toast.error(<div style={{padding:20}}>{msg}</div>);
  }

  render() {
    const {user, downloads,authuser,loading, validation, edit,initialUser, saving, change} = this.state
    const Required = () => (<span style={{color: 'red'}}>*</span>)
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#3E70D7'}}>
          <Container fluid>
            <div className="header-body">
              <Link
                to="/admin/users"
                className="white-link"
                style={{position: 'absolute', display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                <i className=" ni ni-bold-left" style={{marginRight: 5}} />
                <span>Back to Users</span>
              </Link>
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
       
        <Container className="mt--7" fluid>
          <Row>
            
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                {saving&&<SpinDiv text={'Saving...'} />}
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">{initialUser&&initialUser.name}</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {user&&<Button
                        color={edit?'secondary':"success"}
                        onClick={this.toggleEdit}
                        size="sm"
                      >
                        {edit?'Discard Changes':'Edit Profile'}
                      </Button>
                      
                      }

                    </Col>
                  </Row>
                  <Row  style={{ marginTop: "15px" }}>
                  <Col xs="8">
                      <h3 className="mb-0"></h3>
                    </Col>
                    <Col className="text-right" xs="4">

                      <Link
                        className="text-center"
                        style={{ width: "100%", color: "#fff" }}
                        to="/auth/password/reset"
                      >
                        <Button
                          color={'danger'}
                          size="sm"
                        >
                          Reset Password
                      </Button>

                      </Link>
                    </Col>

                  </Row>
                  <h3 className="mb-0">Total Downloads
                  <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({downloads})</span></h3>
                </CardHeader>
                {user&&<CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-2">
                      User information
                    </h6>
                    <div>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                              style={{color: validation.firstname===false?'red':null}}
                            >
                              <Required />First name
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={user.firstname}
                              disabled={!edit}
                              placeholder={edit?'First name':null}
                              id="input-first-name"
                              onChange={e=>this.onChange(e.target.value, 'firstname')}
                              bsSize="sm"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                              style={{color: validation.lastname===false?'red':null}}
                            >
                              <Required />Last name
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={user.lastname||''}
                              disabled={!edit}
                              placeholder={edit?'Last name':null}
                              id="input-last-name"
                              onChange={e=>this.onChange(e.target.value, 'lastname')}
                              bsSize="sm"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        
                        
                      </Row>
                      {user.role === 'reseller'?
                      <Row>
                        <Col lg="6">
                       
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-bank-acct"
                              style={{color: validation.bank_account===false?'red':null}}
                            >
                              <Required />Bank Account
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={user.bank_account||''}
                              disabled={!edit}
                              placeholder={edit?'Bank Account':null}
                              id="input-bank-acct"
                              onChange={e=>this.onChange(e.target.value, 'bank_account')}
                              bsSize="sm"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                        
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-bank-name"
                              style={{color: validation.bank_name===false?'red':null}}
                            >
                              <Required />Bank Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={user.bank_name||''}
                              disabled={!edit}
                              placeholder={edit?'Bank Name':null}
                              id="input-bank-name"
                              onChange={e=>this.onChange(e.target.value, 'bank_name')}
                              bsSize="sm"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>:''}
                      
                      <Row>
                      {(user.phone === '08067332797' || user.phone === '07032618748')&&(
                          authuser.phone !=='08067332797' || authuser.phone !=='07032618748') ? '':
                              
                              <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                  style={{color: validation.email===false?'red':null}}
                                >
                                  <Required />Email
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  value={user.email||''}
                                  disabled={!edit}
                                  id="input-email"
                                  onChange={e=>this.onChange(e.target.value, 'email')}
                                  placeholder={edit?'Email':null}
                                  bsSize="sm"
                                  type="text"
                                />
                              </FormGroup>
                            </Col>}
                            
                        {(user.phone === '08067332797' || user.phone === '07032618748')&&(
                          authuser.phone !=='08067332797' || authuser.phone !=='07032618748') ? '':<Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone"
                              style={{color: validation.phone===false?'red':null}}
                            >
                              <Required />Phone
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={user.phone||''}
                              disabled={!edit}
                              id="input-phone"
                              onChange={e=>this.onChange(e.target.value, 'phone')}
                              placeholder={edit?'Phone':null}
                              bsSize="sm"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        }
                          
                        
                        
                      </Row>
                     <Row>
                     <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Address
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={user.address||''}
                              disabled={!edit}
                              id="input-address"
                              onChange={e=>this.onChange(e.target.value, 'address')}
                              placeholder={edit?'Adress':null}
                              bsSize="sm"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        
                     </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    
                    
                    {edit&&<div style={{padding: '1rem'}}>
                      <Button color="success" type="button" disabled={saving}
                        onClick={this.onSave}
                        style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}>
                        Save
                      </Button>
                    </div>}
                  </Form>
                </CardBody>}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Profile;
