import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Input,
} from "reactstrap";
import { getTags } from "services/projecttagService";
import AddTag from "./AddTag";
import EditTag from "./EditTag";
import DeleteTag from "./DeleteTag";
import SpinDiv from "components/SpinDiv.jsx";
import CustomPagination from "components/CustomPagination.jsx";
import "views/index.css";
import {Pagination} from 'antd'
class Ptags extends React.Component {
  state = {
    page: 1,
    total: 0,
    search: "",
    tags: [],
    rows:10
  };
 
  componentDidMount() {
    this.getTags();
  }

  getTags = () => {
    const { state, page, search, rows } = this.state;
    this.setState({ loading: true });
    getTags({ state, page, rows,  search }).then(
      (res) => {
        this.setState({
          tags: res.tags.data,
          page: res.tags.current_page,
          total: res.tags.total,
          loading: false,
        });
      },
      (error) => {
        console.log(error);
        this.setState({ loading: false });
      }
    );
  };

  toggleAdd = () => {
    this.setState({ add: !this.state.add });
  };
  onPage = async (page, rows) => {
    const {search} = this.state;
    await this.setState({page, rows});
    await this.getTags();
   
  }

  toggleEdit = (editTag) => {
    this.setState({ editTag });
  };

  toggleDelete = (deleteTag) => {
    this.setState({ deleteTag });
  };

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };
  render() {
    const {
      add,
      loading,
      search,
      tags,
      editTag,
      deleteTag,
      total, page, rows,

    } = this.state;

    return (
      <>
        <div
          className="header pb-8 pt-5 pt-md-6"
          style={{ backgroundColor: "#3E70D7" }}
        >
          <Container fluid>
            <div className="header-body">
              <Link
                to="/admin/settings"
                className="white-link"
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <i className=" ni ni-bold-left" style={{ marginRight: 5 }} />
                <span>Back to Settings</span>
              </Link>
            </div>
          </Container>
        </div>
        {/* Page content */}
        {add && (
          <AddTag
            saved={this.getTags}
            toggle={this.toggleAdd}
            add={add}
          />
        )}
        {editTag && (
          <EditTag
            saved={this.getTags}
            editTag={editTag}
            toggle={() => this.setState({ editTag: null })}
          />
        )}
        {deleteTag && (
          <DeleteTag
            saved={this.getTags}
            deleteTag={deleteTag}
            toggle={() => this.setState({ deleteTag: null })}
          />
        )}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading && <SpinDiv text={"Loading..."} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Tags </h3>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Input
                        placeholder="Search..."
                        style={{ maxHeight: 28, marginRight: 5 }}
                        value={search}
                        onChange={(e) =>
                          this.onChange(e.target.value, "search")
                        }
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            this.getTags();
                          }
                        }}
                      />
                      <Button
                        className="btn-icon btn-2"
                        color="secondary"
                        size="sm"
                        style={{ marginRight: 10 }}
                        onClick={this.getTags}
                      >
                        <i className="fa fa-search" />
                      </Button>
                      <Button
                        color="success"
                        onClick={this.toggleAdd}
                        size="sm"
                      >
                        Add Tags
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">No of Projects</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tags.map((tag) => {
                      return (
                        <tr key={tag.id} className="hover-list">
                          <th scope="row">{tag.title}</th>
                          <th scope="row">{tag.projects_count}</th>
                          <td>
                            <Button
                              color="secondary"
                              onClick={() => this.toggleEdit(tag)}
                              size="sm"
                            >
                              Edit
                            </Button>
                            <Button
                              color="danger"
                              onClick={() => this.toggleDelete(tag)}
                              size="sm"
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {!loading && tags.length < 1 && (
                  <div
                    style={{
                      color: "#ccc",
                      alignSelf: "center",
                      padding: 10,
                      fontSize: 13,
                    }}
                  >
                    <i className="fa fa-ban" style={{ marginRight: 5 }} />
                    No Tag found
                  </div>
                )}
                <Row style={{margin:10}}>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {tags.length > 0 && <Pagination
                  total={total}
                  showSizeChanger
                  showTotal={total => `Total ${total} Tags`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Ptags;
