import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Badge,
  Container,
  Row,
  Col,
  Button,
  Input
} from "reactstrap";
import { toast } from 'react-toastify';
import "views/index.css";
import {getDownloads }  from 'services/resellerDownloadService';
import {getUser} from 'services/authService'
import SpinDiv from "components/SpinDiv.jsx";
import CustomPagination from "components/CustomPagination.jsx";

class ResellerDownloads extends React.Component {
  
  constructor(props) {
		super(props);
    const {location: state} = props;
    this.state = {
      activeNav: 1,
    loading: true,
  	page: 1,
  	rows: 10,
  	total: 0,
    search: '',
    users: [],
    user:'',
    downloads: [],
    amount:[],
    month:'',
    year:'',
    id: props.match.params.id
    };
	}
  

  componentDidMount() {
    this.getDownloads();
  }

  componentWillMount() {
  }

  

  getDownloads = () => {
    const {id,month, year,page, rows, search} = this.state;
    this.setState({loading: true});
    getDownloads(id,{month, year})
      .then((res) => {console.log(res)
        this.setState({
          downloads: res.downloads,
          users: res.users,
          user:res.user,
          page: res.downloads.current_page,
          //total: res.downloads.total,
          loading: false,
        })
      },
      error => {
        this.setState({loading: false});
      });
  }

  getTotalPrice(downloads){
    const doubled = downloads.map((download) => download.amount_paid);
    let sum=0;
    for(var i=0; i < doubled.length; i++){
      sum += parseInt(doubled[i]);
    }
    return sum;
  }

  getTotalPaid(downloads){
    const doubled = downloads.map((download) => download.amount_paid_reseller);
    let sum=0;
    for(var i=0; i < doubled.length; i++){
      sum += parseInt(doubled[i]);
    }
    return sum;
  }



  onChange = (e, state) => {
    this.setState({[state]: e})
  }
 
  
  onPage = async (page) => {
    const {search} = this.state;
    await this.setState({page});
    this.getDownloads();
    if(search===''){
      await this.getDownloads();
    } else {
      await this.search();
    }
  }
  toggleFilter = () => {
    this.setState({showFilter: !this.state.showFilter});
  }

  onFilter = async (e, filter) => {
    console.log(filter);
    await this.setState({[filter]: e});
    await this.getDownloads();
  }

  

  onSearch = () => {

  }
  getYear() {
    return new Date().getFullYear();
  }
  

  getMonth() {
    let monthNumber=new Date().getMonth();
    let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", 
    "September", "October", "November", "December"];
    let monthName = monthNames[monthNumber];
    console.log(monthName);
    return monthName;
  }
  getUpm(monthNumber) {
    
    let monthNames = ["","January", "February", "March", "April", "May", "June", "July", "August", 
    "September", "October", "November", "December"];
    let monthName = monthNames[monthNumber];
    console.log(monthName);
    return monthName;
  }

  

  

  
  showToast = msg => {
    toast.error(<div style={{padding:25}}>{msg}</div>);
  }


  render() {
    const {downloads,month,year,amount,showFilter, users, user, total, page, rows, search, loading} = this.state;
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#3E70D7'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center" style={{justifyContent:'space-between', padding: '0 15px', overflowX: 'scroll', overflow: 'visible', flexWrap: 'nowrap'}}>
                    <div>
                      <h3 className="mb-0">Downloads <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h3>
                    </div>
                    {!showFilter&&<Button
                        color="warning"
                        onClick={this.toggleFilter}
                        size="sm"
                        style={{marginRight: 10}}
                      >
                        Filter
                      </Button>}
                    <div className="mobile-flex">
                      <Input placeholder="Search..." style={{maxHeight: 28, marginRight: 5, minWidth: 80}}
                        value={search}
                        onChange={e=>this.onChange(e.target.value, 'search')}
                        onKeyUp={(e) => {if(e.key==='Enter'){this.getDownloads();}}} />
                      <Button className="btn-icon btn-2 hide-mobile" color="secondary" size="sm" style={{marginRight: 10}} onClick={this.getDownloads}>
                        <i className="fa fa-search" />
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                {showFilter&&<div style={{height: 50, borderTop: '0.5px solid #e9ecef', padding: '0 0 0 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflowX: 'auto'}}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{marginRight: 10, fontSize: 14}}>Filter Project Downloads Monthly: </span>
                    <Input value={month} type="select" bsSize="sm" style={{marginRight: 10, width: 'fit-content'}} 
                    onChange={e=>this.onFilter(e.target.value, 'month')}>
                       <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </Input>
                    
                  </div>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{marginRight: 10, fontSize: 14}}>Filter Project Downloads with year: </span>
                    <Input value={year} type="text" bsSize="sm" style={{marginRight: 10, width: 'fit-content'}} 
                   
                    onChange={e=>this.onChange(e.target.value, 'year')}
                    onKeyUp={(e) => {if(e.key==='Enter'){this.getDownloads();}}}>
                    </Input>
                    
                    
                  </div>
                  <Button
                    color="warning"
                    onClick={this.toggleFilter}
                    size="sm"
                    style={{marginRight: 10}}
                  >
                    Hide Filters
                  </Button>
                </div>}
                <Table className={`align-items-center table-flush ${loading?'load-opacity':''}`} responsive>
                  <thead className="thead-light">
                    <tr >
                    {year == '' && month=='' ? 
                    <th scope="col">Summary for {this.getMonth()}, {this.getYear()}</th> 
                    : <th scope="col">Summary for {this.getUpm(month)},{year}</th>}
                      <th scope="col" style={{color:'black',fontWeight:'bold'}}>
                       For Reseller&nbsp;&nbsp;&nbsp;&nbsp;{user.name}
                      </th>
                     
                    </tr>
                    
                     
                  
                    
                    <tr>
                      <th scope="col">Project Title</th>
                      <th scope="col">price</th>
                      <th scope="col">Amount paid Reseller</th>
                      <th scope="col">status</th>
                  
                    </tr>
                  </thead>
                  <tbody>
                  
                 
                    {downloads.map((download, key) => {
                      
                      return (
                        <tr  key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                        this.props.history.push('/admin/downloads/'+download.id)
                        }} >
                        
                          <td>{download.title}</td>
                          <td>{download.amount_paid}</td>
                          <td>{download.amount_paid_reseller}</td>
                          <td>
                            <Badge color="" className="badge-dot mr-4">
                              <i className={download.status ==1 ?'bg-success':'bg-danger'} />
                              {download.status ==1 ?'Order Confirmed':'order Not Confirmed'}
                            </Badge>
                            
                          </td>
                          
                          
                        </tr>
                        
                        
                        );
                      })}

             
                      
                      <tr>
                    <th scope="col"style={{color:'black',fontWeight:'bold',fontSize:20}}>Total Sold :{this.getTotalPrice(downloads)}</th>
                      </tr>
                      <tr>
                    <th scope="col"style={{color:'black',fontWeight:'bold',fontSize:20}}>Amount Paid Reseller :{this.getTotalPaid(downloads)}</th>
                      </tr>
                  </tbody>
                </Table>
                
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ResellerDownloads;
