import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const hirewriterService = {
    getAssignment,
    updateAssignment,
    getAssignments
};
export function getAssignment(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}assignment/${id}`, requestOptions).then(authService.handleResponse);
}

export function getAssignments(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}assignment`, requestOptions).then(authService.handleResponse);
}

export function updateAssignment(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateassignment/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteAssignment(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleteassignment/${id}`, requestOptions).then(authService.handleResponse);
}
