import React from 'react';
import { Route, Redirect } from 'react-router-dom';

/*export const SecureRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => 
        
        localStorage.getItem('user')
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
    } />
)*/


export const SecureRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        let user = JSON.parse(localStorage.getItem('user'));
        
        if (!user) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
        }

        // check if route is restricted by role
        
        if (parseInt(user.admin) !== 1) {
            // role not authorised so redirect to home page
            return <Redirect to={{ pathname: '/reseller/home'}} />
        }
        
       
        
        if (user.status == 0) {
            // status not authorised so redirect to login page
            return <Redirect to={{ pathname: '/auth/login'}} />
        }
        
        
        

        // authorised so return component
        return <Component {...props} />
    }} />
)