import React, {Component} from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Button,
  Input
} from "reactstrap";
import { toast } from 'react-toastify';
import DownloadProject from "./DownloadProject";
import DeleteProject from "./DeleteProject";

import "views/index.css";
import { addProject, getProjects, getSearch } from 'services/projectService';
import { getUser } from 'services/authService'
import SpinDiv from "components/SpinDiv.jsx";
import CustomPagination from "components/CustomPagination.jsx";
import AddProject from "./AddProject.jsx";
import { Pagination } from 'antd';
import { Link } from "react-router-dom";
import DeleteMultProject from "./DeleteMultProject";

export class Projects extends Component {
  constructor(props){
    super(props);
    this.state = {
      activeNav: 1,
      loading: true,
      page: 1,
      rows: 10,
      total: 0,
      search: '',
      users: [],
      authuser: JSON.parse(localStorage.getItem('user')),
      projects: [],
      categories: [],
      tags: [],
      order: 'All',
      departments: [],
      studylevels: [],
      checkProjects:[],
      showFilter:false,
      delete_project_ids:[]
      
    };
    

  }
  
  componentDidMount() {
    this.getProjects();
  }

  componentWillMount() {
  }

  saveProject = (e) => {
    e.preventDefault();
    const { name, phone, email, address } = this.state;
    addProject({ name, phone, email, address })
      .then(res => {
        this.setState({ message: 'Project added successfully.' });
        this.props.history.push('/projects');
      },
        error => {
          console.log(error)
        });
  }

  getProjects = () => {
    const { category, department, studylevel, user, order, page, rows, search } = this.state;
    this.setState({ loading: true });
    getProjects({ user, page, category, order, department, studylevel, rows, search })
      .then((res) => {
        //console.log(res)
        this.setState({
          projects: res.projects.data,
          page: res.projects.current_page,
          total: res.projects.total,
          loading: false,
        })
      },
        error => {
          this.setState({ loading: false });
        });
  }

  toggleModal = () => {
    toast.dismiss()
    this.setState({ addProject: !this.state.addProject });
  }

  toggleCheckProject = (e, item) => {		
    const {checkProjects} = this.state;
		if(e.target.checked) {
			checkProjects.push(item.id);
			
		} else {			
			checkProjects.splice(checkProjects.indexOf(item.id), 1);
			
			
		}		
    this.setState({checkProjects})
		console.log(this.state.checkProjects);
	}


  onChange = (e, state) => {
    this.setState({ [state]: e })
  }
  toggleDownload = (downloadProject) => {
    this.setState({ downloadProject });
  };
  toggleDelete = (deleteProject) => {
    this.setState({ deleteProject });
  };

  toggleDeleteAll = (delete_project_ids) => {
    
    this.setState({ delete_project_ids });
  };

  onPage = async (page, rows) => {
    const { search } = this.state;
    await this.setState({ page, rows, search });
    await this.setState({checkProjects:[]})
    await this.getProjects();
  }
  toggleFilter = () => {
    this.setState({ showFilter: !this.state.showFilter });
  }

  onFilter = async (e, filter) => {
    console.log(filter);
    await this.setState({ [filter]: e });
    await this.getProjects();
  }


  onSearch = () => {

  }






  showToast = msg => {
    toast.error(<div style={{ padding: 25 }}>{msg}</div>);
  }


  render() {
    const { projects, checkProjects,delete_project_ids,  authuser, showFilter, order, downloadProject, deleteProject, categories, tags, departments, studylevels, users, addProject, total, page, rows, search, loading } = this.state;
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{ backgroundColor: '#3E70D7' }}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        {addProject &&
          <AddProject
            saved={this.getProjects}
            toggle={this.toggleModal}
            addProject={addProject}
          />}
        {delete_project_ids && 
          <DeleteMultProject 
          saved={this.getProjects}
          project_ids={delete_project_ids}
          toggle={() => this.setState({ checkProjects: [], delete_project_ids:[]})}
          />
        }
        {downloadProject && (
          <DownloadProject
            saved={this.getProjects}
            downloadProject={downloadProject}
            toggle={() => this.setState({ downloadProject: null })}
          />
        )}
        {deleteProject && (
          <DeleteProject
            saved={this.getProjects}
            deleteProject={deleteProject}
            toggle={() => this.setState({ deleteProject: null })}
          />
        )}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading && <SpinDiv text={'Loading...'} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center" style={{ justifyContent: 'space-between', padding: '0 15px', overflowX: 'scroll', overflow: 'visible', flexWrap: 'nowrap' }}>
                    <div>
                      <h3 className="mb-0">Projects <span style={{ color: '#aaa', fontSize: 14, fontWeight: 'normal' }}> ({total})</span></h3>
                    </div>
                    {!showFilter && <Button
                      color="warning"
                      onClick={this.toggleFilter}
                      size="sm"
                      style={{ marginRight: 10 }}
                    >
                      Filter
                    </Button>}
                    {checkProjects.length > 0 && <Button
                              color="danger"
                              onClick={() => this.toggleDeleteAll(checkProjects)}
                              size="sm"
                            >
                              Delete All Selected 
                            </Button>}

                    <div className="mobile-flex">
                      <Input placeholder="Search..." style={{ maxHeight: 28, marginRight: 5, minWidth: 80 }}
                        value={search}
                        onChange={e => this.onChange(e.target.value, 'search')}
                        onKeyUp={(e) => { if (e.key === 'Enter') { this.getProjects(); } }} />
                      <Button className="btn-icon btn-2 hide-mobile" color="secondary" size="sm" style={{ marginRight: 10 }} onClick={this.getProjects}>
                        <i className="fa fa-search" />
                      </Button>
                      {(getUser().admin == 1) && <Button
                        onClick={() => this.toggleModal()}
                        size="sm"
                        style={{ backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff' }}
                      >
                        Create Project
                      </Button>}
                    </div>
                  </Row>
                </CardHeader>
                {showFilter && <div style={{ height: 50, borderTop: '0.5px solid #e9ecef', padding: '0 0 0 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflowX: 'auto' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: 10, fontSize: 14 }}>Filter Project: </span>
                    <Input value={order} type="select" bsSize="sm" style={{ marginRight: 10, width: 'fit-content' }}
                      onChange={e => this.onFilter(e.target.value, 'order')}>
                      <option value="All">All</option>
                      <option value="latest">Latest</option>
                      <option value="With Files">With Files</option>
                      <option value="Without Files">Without Files</option>
                      <option value="Approved">Approved Project</option>
                      <option value="Available">Available Project</option>
                      <option value="Not Approved">Not Approved Project</option>
                      <option value="Not Available">Not Available Project</option>
                      <option value="Not Set Availability">Availabilty not set</option>
                    </Input>

                  </div>
                  <Button
                    color="warning"
                    onClick={this.toggleFilter}
                    size="sm"
                    style={{ marginRight: 10 }}
                  >
                    Hide Filters
                  </Button>
                </div>}
                <Table className={`align-items-center table-flush ${loading ? 'load-opacity' : ''}`} responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Delete</th>
                      <th scope="col">Author</th>
                      <th scope="col">Title</th>
                      <th scope="col">Department</th>
                      <th scope="col">Chapters</th>
                      <th scope="col">Tags</th>
                      <th scope="col">Price</th>
                      <th scope="col">Approve</th>
                      <th scope="col">Date and time</th>


                    </tr>
                  </thead>
                  <tbody>
                    {projects.map((project, key) => {
                      return (
                        <tr >
                          <td><input type="checkbox" className="selectsingle" 
                          value={project.id} checked={checkProjects.length > 0 ? checkProjects.find((p) => p.id === project.id):false} onChange={(e) => this.toggleCheckProject(e, project)}/></td>
                          <td> <Link

                            to={`/admin/projects/${project.id}`}
                            style={{ textDecoration: 'none', color: 'black' }}
                          >
                            {project.user.name}
                          </Link></td>
                          <td> <Link

                            to={`/admin/projects/${project.id}`}
                            style={{ textDecoration: 'none', color: 'black' }}
                          >
                            {project.title.length > 120 ? project.title.substr(0, 120) + "...." : project.title}
                          </Link></td>
                          <td> <Link

                            to={`/admin/projects/${project.id}`}
                            style={{ textDecoration: 'none', color: 'black' }}
                          >
                            {project.department_id}
                          </Link></td>
                          <td> <Link

                            to={`/admin/projects/${project.id}`}
                            style={{ textDecoration: 'none', color: 'black' }}
                          >
                            {project.chapters}
                          </Link></td>
                          <td> <Link

                            to={`/admin/projects/${project.id}`}
                            style={{ textDecoration: 'none', color: 'black' }}
                          >
                            {project.tag_length}
                          </Link></td>
                          <td> <Link

                            to={`/admin/projects/${project.id}`}
                            style={{ textDecoration: 'none', color: 'black' }}
                          >
                            {project.prices}
                          </Link></td>
                          <td> <Link

                            to={`/admin/projects/${project.id}`}
                            style={{ textDecoration: 'none', color: 'black' }}
                          >
                            {project.approved == 1 ? "Active" : "Inactive"}
                          </Link></td>
                          <td> <Link

                            to={`/admin/projects/${project.id}`}
                            style={{ textDecoration: 'none', color: 'black' }}
                          >
                            {project.created_at}
                          </Link></td>






                          {project.filename !== null ?<td>
                            {authuser.email == "bravehands85@gmail.com" ? <Button
                              color="primary"
                              onClick={() => this.toggleDownload(project)}
                              size="sm"
                            >
                              Download
                            </Button> : <td><Button
                              color="primary"
                              
                              disabled
                              size="sm"
                            >
                              File Attached
                            </Button></td>}

                          </td>:<td style={{marginLeft:20}}><Button
                              color="primary"
                              disabled
                              size="sm"
                            >
                              No File
                            </Button></td>}
                          <td>
                            {authuser.email == "bravehands85@gmail.com" && <Button
                              color="danger"
                              onClick={() => this.toggleDelete(project)}
                              size="sm"
                            >
                              Delete
                            </Button>}

                          </td>

                        </tr>

                      );
                    })}
                  </tbody>
                </Table>
                {!loading && projects.length < 1 &&
                  <div style={{ color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13 }}>
                    <i className="fa fa-ban" style={{ marginRight: 5 }} />
                    No project found
                  </div>}

                <Row style={{ margin: 10 }}>
                  <Col md={12} style={{ fontWeight: "bold", paddingTop: 3 }}>
                    {projects.length > 0 && <Pagination
                      total={total}
                      showSizeChanger
                      showQuickJumper
                      showTotal={total => `Total ${total} projects`}
                      onChange={this.onPage}
                      pageSize={rows}
                      current={page}
                    />}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Projects;
