/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.jsx";
import Projects from "views/projects/Index.jsx";
import Blogs from "views/blogs/Index.jsx";
import Users from "views/users/Index.jsx";
import Downloads from "views/downloads/Index.jsx";
import Reseller from "views/reseller_downloads/Index.jsx";
import Writerrequests from "views/requests/Index.jsx";
import Reports from "views/reports/Index.jsx";
import Seminar from "views/seminar/Index.jsx";
import Assignment from "views/assignment/Index.jsx";
import Essay from "views/essay/Index.jsx";
import Settings from "views/settings/Index.jsx";
import {getUser} from "./services/authService";
import Activities from "./views/activity/Index";

var routes = [
  {
    path: "/home",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  

    
  }, 
  {
    path: "/users",
    name: "users",
    icon: "fas fa-user-friends text-blue",
    component: Users,
    layout: "/admin",
    admin:true
  },
  {
    path: "/projects",
    name: "Project",
    icon: "ni ni-archive-2 text-green",
    component: Projects,
    layout: "/admin"
  },
   //{ path: "/auth/reset-password/:id" },
  {
    path: "/reports",
    name: "Reported Work",
    icon: "fas fa-copyright text-black",
    component: Reports,
    layout: "/admin"
  },
  {
    path: "/activities",
    name: "User Activities",
    icon: "fa fa-check-circle text-blue",
    component: Activities,
    layout: "/admin"
  },
  
  
  {
    path: "/blog",
    name: "Blog",
    icon: "ni ni-box-2 text-yellow",
    component: Blogs,
    layout: "/admin"
  },
  {
    path: "/downloads",
    name: "Downloads",
    icon: "fa fa-download text-blue",
    component: Downloads,
    layout: "/admin"
  },
  {
    path: "/reseller_downloads",
    name: "Reseller Payment",
    icon: "fa fa-download text-green",
    component: Reseller,
    layout: "/admin"
  },
  {
    path: "/writerrequest",
    name: "Writer Requests",
    icon: "fa fa-check-circle text-orange",
    component: Writerrequests,
    layout: "/admin"
  },
  // {
  //   path: "/seminar",
  //   name: "seminar",
  //   icon: "fa fa-user-plus text-green",
  //   component: Seminar,
  //   layout: "/admin"
  // },
  // {
  //   path: "/assignments",
  //   name: "Assignments",
  //   icon: "fa fa-tasks text-purple",
  //   component: Assignment,
  //   layout: "/admin"
  // },
  // {
  //   path: "/essays",
  //   name: "Essays",
  //   icon: "fa fa-edit text-green",
  //   component: Essay,
  //   layout: "/admin"
  // },
  {
    path: "/settings",
    name: "Settings",
    icon: "ni ni-settings text-red",
    component: Settings,
    layout: "/admin"
  },
  

  
];
export default routes;
