import React from 'react';
import { Route, Redirect } from 'react-router-dom';

/*export const SecureRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => 
        
        localStorage.getItem('user')
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
    } />
)*/


export const SecureReseller = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        let user = JSON.parse(localStorage.getItem('user'));
        let u = JSON.parse(localStorage.getItem('u'));
        
        if (!user) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/authreseller/login', state: { from: props.location } }} />
        }

        // check if route is restricted by role
        if (user.admin === 1) {
            // role not authorised so redirect to home page
            return <Redirect to={{ pathname: '/admin'}} />
        }
        if (user.status == 0) {
            // role not authorised so redirect to home page
            return <Redirect to={{ pathname: '/auth/login'}} />
        }
        if(u !== null){
            if (u.status == 0) {
                // status not authorised so redirect to login page
                return <Redirect to={{ pathname: '/auth/login'}} />
            }
        }

        

        // authorised so return component
        return <Component {...props} />
    }} />
)