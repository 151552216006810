import React from "react";
import {
  Button,
  Modal
} from "reactstrap";
import { toast } from 'react-toastify';
import axios from 'axios'
import {authHeader} from '../../services/authHeader';
import {authService} from '../../services/authService';
import settings from '../../services/settings';
import { downloadProject }  from 'services/projectService';
import SpinDiv from "components/SpinDiv.jsx";

class DownloadProject extends React.Component {
	constructor(props) {
		super(props);
    this.state = {
      project: props.downloadProject,
      loading: false,
      search: '',
      validation: {},
      name: ''
    };
	}

  componentDidMount() {
    toast.configure({hideProgressBar: true, closeButton: false});
  }

  onDownload = () => {
    this.setState({loading: true});
    const {project} = this.state;
  
    var fileExt =  project.filename.split('.').pop();

    fetch(`${settings.API_URL}download/${project.slug}`,{ method: 'GET',
        headers:authHeader()
      })
			.then(response => {
                console.log(response);
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
          console.log(url)
					let a = document.createElement('a');
					a.href = url;
					a.download = response.url+`.${fileExt}`;
                    a.click();
                    this.setState({ loading: false });
                    this.props.saved();
                    this.props.toggle();
				});
				//window.location.href = response.url;
		});
    
  }

  showToast = msg => {
    toast.error(<div style={{padding:20}}>{msg}</div>);
  }

  render() {
    const { downloadProject, toggle } = this.props
    const {project, loading} = this.state;
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={downloadProject!=null}
          toggle={() => !loading&&toggle}
          style={{maxWidth: 600}}
        >
          {loading&&<SpinDiv text={'Downloading...'} />}
          <div className="modal-header" style={{padding: '1rem'}}>
            <h3 className="modal-title" id="exampleModalLabel">
              Download file - {project.title}
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" style={{border: '1px solid #eee'}}>
            You are about to download this file
          </div>
          <div className="modal-footer" style={{padding: '1rem'}}>
            <Button
              size="sm"
              color="secondary"
              data-dismiss="modal"
              type="button"
              disabled={loading}
              onClick={toggle}
            >
              Cancel
            </Button>
            <Button color="success" type="button" disabled={loading} size="sm"
              onClick={this.onDownload}
              style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}>
              Download
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default DownloadProject;
