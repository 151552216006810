import React, { Component } from 'react'

export class My404Component extends Component {
    render() {
        return (
            <div>
                Page Not found
                
            </div>
        )
    }
}

export default My404Component
