import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormText,
  UncontrolledTooltip
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { getEssay } from "services/essayService";
import { getUser } from "services/authService";
import SpinDiv from "components/SpinDiv.jsx";
import axios from "axios";
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";

class Singleessay extends React.Component {
  constructor(props) {
    super(props);
    const { location: state } = props;
    this.state = {
      loading: false,
      edit: false,
      change: false,
      essay: state && state.essay ? state.essay : null,
      validation: {},
      format: {},
      id: props.match.params.id,
      users: []
    };
  }

  componentDidMount() {
    this.getEssay();
  }

  getEssay = () => {
    const { id} = this.state;
    this.setState({ loading: true });
    getEssay(id).then(
      (res) => {
        
        this.setState({
          loading: false,
          essay: { ...res.essay },
          initialEssay: { ...res.essay },
          //.map(p=>({label:p.name, value:p.id})),
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
     
    );
  };

  toggleEdit = () => {
    const { initialEssay } = this.state;
    this.setState({ edit: !this.state.edit, essay: { ...initialEssay } });
  };

  togglePassword = () => {
    this.setState({ change: !this.state.change });
  };

  onSave = async e => {
    e.preventDefault();
    await toast.dismiss();
    const { essay, validation } = this.state;
    const {
      status,
      message,
      reference,
      available,
      subject,
      id
    } = essay;
    await this.setState({
      validation: {
        ...validation,
        
        //status: status !== "",
        //message: message !== "",
      }
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveEssay();
    } else {
      const errors = Object.keys(this.state.validation).filter(id => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(
        () =>
          toast.error(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold" }}>Errors:</p>
              {errors.map(v => (
                <p key={v} style={{ margin: 0, fontSize: 14 }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          ),
        250
      );
    }
  };

  saveEssay = () => {
    this.setState({ saving: true });
    let data = new FormData();
      data.set("message", this.state.essay.message);
      data.set("status", this.state.essay.status);
      data.set("subject", this.state.essay.subject);
    return axios
      .post(
        `${settings.API_URL}updateessay/${this.state.essay.id}`,
        data,
        {
          headers: authHeader()
        },
        authService.handleResponse
      )
      .then(res => {
        console.log(res.data);
        this.showToast("Status updated");
        //document.getElementById('myedit').style.display = 'none';
        //document.getElementById('back').style.display = 'block';
        this.setState({ saving: false, edit: false });
        this.getEssay(this.state.essay.id);
      })
      .catch(err => {
        console.log(err);
        this.setState({
          errorMessage: err,
          show: true
        });
        this.setState({ saving: false });
      });
    
    
  };

  validationRules = field => {
    if (field === "title") {
      return "title is required";
    } else if (field === "category_id") {
      return "Category is required";
   
    }
  };

  onChange = (e, state) => {
    console.log(e);
    const { essay } = this.state;

    this.setState({ essay: { ...essay, [state]: e } });
  };

  showToast = msg => {
    toast.error(<div style={{ padding: 20 }}>{msg}</div>);
  };

  render() {
    const {
      essay,
      loading,
      validation,
      edit,
      initialEssay,
      saving,
      change,
      users
    } = this.state;
    const Required = () => <span style={{ color: "red" }}>*</span>;
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        minWidth: 170,
        height: 46,
        borderColor: "hsl(0,0%,95%)",
        boxShadow: "1px 1px 1px rgba(50, 50, 50, 0.1)"
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      }
    };

    return (
      <>
        <div
          className="header pb-8 pt-5 pt-md-6"
          style={{ backgroundColor: "#3E70D7" }}
        >
          <Container fluid>
            <div className="header-body">
              <Link
                to="/admin/essays"
                className="white-link"
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer"
                }}
              >
                <i className=" ni ni-bold-left" style={{ marginRight: 5 }} />
                <span>Back to essay</span>
              </Link>
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                {loading && <SpinDiv text={"Loading..."} />}
                {saving && <SpinDiv text={"Saving..."} />}
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      
                    </Col>
                    {initialEssay &&
                    (getUser().admin ||
                      getUser().id === initialEssay.user_id) ? (
                      <Col className="text-right" xs="4">
                        {essay && (
                          <Button
                            color={edit ? "secondary" : "success"}
                            onClick={this.toggleEdit}
                            size="sm"
                          >
                            {edit ? "Discard Changes" : "Edit"}
                          </Button>
                        )}
                      </Col>
                    ) : (
                      <Col className="text-right" xs="4">
                        {essay && (
                          <Button
                            color="success"
                            onClick={this.addSubEssay}
                            size="sm"
                          >
                            update essay
                          </Button>
                        )}
                      </Col>
                    )}
                  </Row>
                </CardHeader>
                {essay && (
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-2">
                        essay information
                      </h6>
                      <div>
                      <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                
                              >
                                <Required />
                                Name
                              </label>
                              <Input
                                value={essay.fullname}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled
                                onChange={async (e) => {
                                  await this.onChange(e.target.value, "topic");
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                
                              >
                                <Required />
                                Email
                              </label>
                              <Input
                                value={essay.email}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled
                                onChange={async (e) => {
                                  await this.onChange(e.target.value, "topic");
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                         
                        </Row>
                        <Row>
                        <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                
                              >
                                <Required />
                                Phone
                              </label>
                              <Input
                                value={essay.phone}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled
                                onChange={async (e) => {
                                  await this.onChange(e.target.value, "topic");
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                
                              >
                                <Required />
                                Requested Date
                              </label>
                              <Input
                                value={essay.created_at}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled
                                onChange={async (e) => {
                                  await this.onChange(e.target.value, "topic");
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        
  
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                
                              >
                                <Required />
                                Topic
                              </label>
                              <Input
                                value={essay.title}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled
                                
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                
                              >
                                <Required />
                                Department
                              </label>
                              <Input
                                value={essay.department}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled
                               
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                
                              >
                                <Required />
                                Proposed Completion Date
                              </label>
                              <Input
                                value={essay.delivery}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled
                               
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        
                        
                        <Row>
                          
                          
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-body"
                              >
                                Description:
                                <Required />
                              </label>
                              <Input
                                value={essay.additional}
                                type="textarea"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled
                                onChange={async (e) => {
                                  await this.onChange(
                                    e.target.value,
                                    "body"
                                  );
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                          
                        </Row>
                        <Row>
                        <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-status"
                                style={{
                                  color:
                                    validation.status === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Work status
                              </label>
                              <Input
                            
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "status"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Status
                                </option>
                                <option value="pending">Pending</option>
                                <option value="completed">Work Completed</option> 
                                <option value="Work in progress">Work in progress</option> 
                                <option value="On hold">On hold</option> 
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                        <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                
                              >
                                <Required />
                                Writing
                              </label>
                              <Input
                                value={essay.writing}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled
                                onChange={async (e) => {
                                  await this.onChange(e.target.value, "topic");
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                
                              >
                                <Required />
                                Level
                              </label>
                              <Input
                                value={essay.level}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled
                                onChange={async (e) => {
                                  await this.onChange(e.target.value, "topic");
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                        <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                
                              >
                                <Required />
                                Page
                              </label>
                              <Input
                                value={essay.page}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled
                                onChange={async (e) => {
                                  await this.onChange(e.target.value, "topic");
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                
                              >
                                <Required />
                                Work
                              </label>
                              <Input
                                value={essay.work}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled
                                onChange={async (e) => {
                                  await this.onChange(e.target.value, "topic");
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                
                              >
                                <Required />
                                Subject
                              </label>
                              <Input
                               placeholder="Subject"
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async (e) => {
                                    await this.onChange(
                                      e.target.value,
                                      "subject"
                                    );
                                  }}
                               
                              ></Input>
                            </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                        <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-body"
                              >
                                Message Requestor
                                <Required />
                              </label>
                              <Input
                             
                               placeholder="Send Feedback"
                                type="textarea"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async (e) => {
                                  await this.onChange(
                                    e.target.value,
                                    "message"
                                  );
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      
                      <hr style={{ marginBottom: 20, marginTop: 10 }} />
                      {edit && (
                        <div>
                          <Button
                            color="success"
                            type="button"
                            disabled={saving}
                            onClick={this.onSave}
                            style={{
                              backgroundColor: "#EC3237",
                              borderColor: "#EC3237",
                              color: "#fff"
                            }}
                          >
                            Update status and Reply Email
                          </Button>
                        </div>
                      )}
                    </Form>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Singleessay;
