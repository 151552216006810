import React from "react";
import { Link } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import {
  Button,
  Card,
  //CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  //Row,
  Col
} from "reactstrap";

import  { toast } from "react-toastify";
import { login } from "services/authService";

class Login extends React.Component {
  state = {
    username: "",
    password: "",
    loading: false,
    error: null,
    show: false,
  };

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  isValid = () => {
    const { username, password } = this.state;
    const phone = /^([0]\d{10})$/;
    const email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      password != null &&
      password !== "" &&
      (email.test(username.trim()) || phone.test(username.trim()))
    ) {
      return true;
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    this.setState({ loading: true });
    login({ username, password }).then(
      (v) => {
        this.setState({
          loading: false,
        });
        this.props.history.push("/admin/home");
      },
      (error) => {
        this.setState({ loading: false, password: "" });
        toast.dismiss();
        toast.configure({ hideProgressBar: true, closeButton: false });
        toast(
          <div style={{ padding: 20, color: "#EC3237" }}>
            Invalid Username or Password
          </div>
        );
      }
    );
  };

  render() {
    const { show, loading, username, password } = this.state;
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <img
                  style={{ width: 180, borderRadius: 5 }}
                  alt="..."
                  src={require("assets/img/brand/logo.png")}
                />
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      autoCapitalize="off"
                      placeholder="Username"
                      value={username}
                      onChange={(e) =>
                        this.onChange(e.target.value, "username")
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type={show ? "text" : "password"}
                      value={password}
                      onChange={(e) =>
                        this.onChange(e.target.value, "password")
                      }
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <span
                          className="hover-link"
                          style={{ fontSize: 12, cursor: "pointer" }}
                          onClick={() => this.setState({ show: !show })}
                        >
                          {show ? "hide" : "show"}
                        </span>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
                {true && false && (
                  <div className="custom-control custom-control-alternative custom-checkbox text-center">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                      color="success"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label>
                  </div>
                )}
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="success"
                    type="submit"
                    disabled={!this.isValid() || loading}
                    style={{
                      maxHeight: 43,
                      width: "100%",
                      backgroundColor: "#3E70D7",
                      borderColor: "#3E70D7",
                      padding: loading ? "0.25rem 1.25rem" : null,
                    }}
                    onClick={this.onSubmit}
                  >
                    {loading ? <Spinner animation="grow" variant="light" /> : <span>login</span>}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <div className="mt-3 text-center">
            <Link
              className="text-center"
              style={{ width: "100%", color: "#fff" }}
              to="/auth/login"
            >
              <small>Forgot password?</small>
            </Link>
            {true && false && (
              <Col className="text-right" xs="6">
                <Link className="text-muted" to={{ pathname: "https://myproject.ng/upload-work" }} target="_blank">
                  <small>
                    <span style={{ color: "#8b0000" }}>Register</span>
                  </small>
                </Link>
                
              </Col>
            )}
          </div>
        </Col>
      </>
    );
  }
}

export default Login;
