import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const departmentService = {
    addDepartment,
    getDepartments,
    updateDepartment,
    deleteDepartment
};

export function getDepartments(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}departments`, requestOptions).then(authService.handleResponse);
}

export function addDepartment(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}adddepartment`, requestOptions).then(authService.handleResponse);
}

export function updateDepartment(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updatedepartment/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteDepartment(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletedepartment/${id}`, requestOptions).then(authService.handleResponse);
}
