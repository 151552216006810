import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const hirecategoryService = {
    addhireCategory,
    gethireCategories,
    updatehireCategory,
    deletehireCategory
};

export function gethireCategories(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}hirewritercategories`, requestOptions).then(authService.handleResponse);
}

export function addhireCategory(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addhcat`, requestOptions).then(authService.handleResponse);
}

export function updatehireCategory(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updatehcat/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deletehireCategory(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletehcat/${id}`, requestOptions).then(authService.handleResponse);
}
