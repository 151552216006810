import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Button,
  Input
} from "reactstrap";
import { toast } from 'react-toastify';
import "views/index.css";
import {getDownloads }  from 'services/downloadService';
import {getUser} from 'services/authService'
import SpinDiv from "components/SpinDiv.jsx";
import CustomPagination from "components/CustomPagination.jsx";
import DeleteDownload from "./DeleteDownload";
import { deleteDownload } from "../../services/downloadService";
import {Pagination} from 'antd'

class Downloads extends React.Component {
  state = {
    activeNav: 1,
    loading: true,
  	page: 1,
  	rows: 10,
  	total: 0,
    search: '',
    user:JSON.parse(localStorage.getItem("user")),
    users: [],
    downloads: []
  };

  componentDidMount() {
    this.getDownloads();
  }

  componentWillMount() {
  }

  toggleDelete = (deleteDownload) => {
    this.setState({ deleteDownload });
  };

  

  getDownloads = () => {
    const {user, page, rows, search} = this.state;
    this.setState({loading: true});
    getDownloads({user, page, rows, search})
      .then((res) => {console.log(res)
        this.setState({
          downloads: res.downloads.data,
          users: res.users,
          projects:res.projects,
          page: res.downloads.current_page,
          total: res.downloads.total,
          loading: false,
        })
      },
      error => {
        this.setState({loading: false});
      });
  }



  onChange = (e, state) => {
    this.setState({[state]: e})
  }
 
  
  onPage = async (page, rows) => {
    const {search} = this.state;
    await this.setState({page, rows});
    if(search===''){
      await this.getDownloads();
    } else {
      await this.search();
    }
  }

  onSearch = () => {

  }

  

  

  
  showToast = msg => {
    toast.error(<div style={{padding:25}}>{msg}</div>);
  }


  render() {
    const {downloads,projects,user,deleteDownload, users,  total, page, rows, search, loading} = this.state;
    return (
      <>
      {deleteDownload && (
          <DeleteDownload
            saved={this.getDownloads}
            deleteDownload={deleteDownload}
            toggle={() => this.setState({ deleteDownload: null })}
          />
        )}
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#3E70D7'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center" style={{justifyContent:'space-between', padding: '0 15px', overflowX: 'scroll', overflow: 'visible', flexWrap: 'nowrap'}}>
                    <div>
                      <h3 className="mb-0">Downloads <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h3>
                    </div>
                    <div className="mobile-flex">
                      <Input placeholder="Search..." style={{maxHeight: 28, marginRight: 5, minWidth: 80}}
                        value={search}
                        onChange={e=>this.onChange(e.target.value, 'search')}
                        onKeyUp={(e) => {if(e.key==='Enter'){this.getDownloads();}}} />
                      <Button className="btn-icon btn-2 hide-mobile" color="secondary" size="sm" style={{marginRight: 10}} onClick={this.getDownloads}>
                        <i className="fa fa-search" />
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className={`align-items-center table-flush ${loading?'load-opacity':''}`} responsive>
                  <thead className="thead-light">
                    <tr >
                      <th scope="col">Author</th>
                      <th scope="col">Title</th>
                      <th scope="col">Customer Name</th>
                      <th scope="col">Approve</th>
                      <th scope="col">Download Code</th>
                      <th scope="col">Date</th>
                      
                     
                    </tr>
                  </thead>
                  <tbody>
                    {downloads.map((download, key) => {
                      return (
                        <tr>
                          <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                        this.props.history.push('/admin/downloads/'+download.id)
                        }}>{download.user.name}</td>
                          <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                        this.props.history.push('/admin/downloads/'+download.id)
                        }}>{download.title.length > 50 ? 
                          download.title.substr(0, 20)+"....":download.title}</td>
                          <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                        this.props.history.push('/admin/downloads/'+download.id)
                        }}>{download.name}</td>
                          <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                        this.props.history.push('/admin/downloads/'+download.id)
                        }}>{download.status === 1 && download.available=='yes' ? 
                          "downloaded" : download.status == null ?'requested' :'paid'}</td>
                          <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                        this.props.history.push('/admin/downloads/'+download.id)
                        }}>{download.reference_code}</td>
                          <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                        this.props.history.push('/admin/downloads/'+download.id)
                        }}>{download.created_at}</td>
                        {user.email == 'bravehands85@gmail.com' || user.email =='show4ril@gmail.com' ? <td>
                            <Button
                              color="danger"
                              onClick={() => this.toggleDelete(download)}
                              size="sm"
                            >
                              Delete
                            </Button>
                          </td>:'' }
                          
                        </tr>
                        
                        );
                      })}
                  </tbody>
                </Table>
                {!loading&&downloads.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Downlods found
                </div>}
               
                <Row style={{margin:10}}>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {downloads.length > 0 && <Pagination
                  total={total}
                  showSizeChanger
                  showTotal={total => `Total ${total} Downloads`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Downloads;
