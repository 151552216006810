import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  FormGroup,
  Input,
  Label,
  FormText,
} from "reactstrap";
import { toast } from "react-toastify";
import SunEditor, {buttonList} from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import axios from 'axios'
import SpinDiv from "../../../components/SpinDiv.jsx";
import settings from '../../../services/settings';
import {authHeader} from '../../../services/authHeader';
import {authService} from '../../../services/authService'
import { Alert } from "react-bootstrap";

class AddProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNav: 1,
      loading: false,
      search: "",
      categories: props.categories,
      departments: props.departments,
      studylevels: props.studylevels,
      //tags: props.tags,
      users: props.users,
      validation: {},
      title: "",
      file:"",
      page_numbers: "",
      tag_id:"",
      selectOptions:"",
      price: "",
      summary: "",
      category_id: "",
      department_id: "",
      studylevel_id: "",
      chapters: "",
      handleChange:""
    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
  }

  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {
      title,
      category_id,
      department_id,
      studylevel_id,
      chapters,
      summary,
      page_numbers,
      price,
      file,
      validation,
      //tag_id,
      selectOptions,
    } = this.state;
    await this.setState({
      validation: {
        ...validation,
        title: title !== "",
        category_id: category_id !== "",
        department_id: department_id !== "",
        studylevel_id: studylevel_id !== "",
        chapters: chapters !== "",
        summary: summary !== "",
        price: price !== "",
        page_numbers: page_numbers !== "",
        file:file!=="",
        //selectOptions:selectOptions!==""
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveProject();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(
        () =>
          toast.error(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold" }}>Errors:</p>
              {errors.map((v) => (
                <p key={v} style={{ margin: 0, fontSize: 14 }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          ),
        250
      );
    }
  };

  validationRules = (field) => {
    if (field === "title") {
      return "title is required";
    } else if (field === "category_id") {
      return "Category is required";
    } else if (field === "department_id") {
      return "Department is required";
    } else if (field === "studylevel_id") {
      return "Study level is required";
    } else if (field === "summary") {
      return "Summary is required";
    }
    else if (field === "price") {
      return "Price is required";
    }
    else if (field === "file") {
        return "file is required";
    }
  };
  
  saveProject = () => {
    this.setState({ loading: true });
    const {
      title,
      category_id,
      department_id,
      studylevel_id,
      //tag_id,
      chapters,
      summary,
      file,
      page_numbers,
      price
    } = this.state;
    if (this.state.file) {
      let data = new FormData();
      let options = this.state.selectOptions;
      
      data.append("file", this.state.file);
      data.set("title", this.state.title);
      data.set("category_id", this.state.category_id);
      data.set("department_id", this.state.department_id);
      data.set("studylevel_id", this.state.studylevel_id);
      data.set("chapters", this.state.chapters);
      data.set("summary", this.state.summary);
      data.set("page_numbers", this.state.page_numbers);
      data.set("price", this.state.price);
     
     return axios
        .post(`${settings.API_URL}reseller/addproject`, data,{
          headers:authHeader()
        },authService.handleResponse)
        .then(
          (res) => {
            console.log(res.data);
            this.setState({ loading: false });
            this.props.saved();
            this.props.toggle();
            this.showToast("Project Created");
          }
        ).catch((err) => {
          //console.log(err.response.data.file);
          this.setState({
            errorMessage: err.response.data,
            show: true,
          });
          console.log(this.state.errorMessage);
          if (this.state.errorMessage.title) {
            this.showToast(this.state.errorMessage.title[0]);
           
          }
          if (this.state.errorMessage.file) {
            this.showToast(this.state.errorMessage.file[0]);
            
          }
          
          this.setState({ loading: false });
        });
    }
    
  };

  showToast = (msg) => {
    toast.error(<div style={{ padding: 20 }}>{msg}</div>);
  };

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };
  handleChange=(content)=>{
    this.setState({summary:content });
     //Get Content Inside Editor
  };

  tagHandler = e => {
    let target = e.target;
    let name = target.name;
    //here
    let value = Array.from(target.selectedOptions, option => option.value);
    this.setState({
      [name]: value
    });
  };

  render() {
    const { addProject, toggle } = this.props;
    
    const {
      projects,
      //tags,
      categories,
      departments,
      studylevels,
      saving,
      loading,
      validation,
      file,
      category_id,
      department_id,
      studylevel_id,
    } = this.state;
    const Required = () => <span style={{ color: "red" }}>*</span>;
    return (
      <>
        <Modal
          className="modal-dialog-full"
          isOpen={addProject}
          toggle={() => !saving && toggle}
          style={{ maxWidth:"60%" }}
        >
          {loading && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <h3 className="modal-title" id="exampleModalLabel">
              Add Project
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>x</span>
            </button>
          </div>
          <div className="modal-body" style={{ border: "1px solid #eee" }}>
            <Row>
                <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-phone"
                    style={{ color: validation.title === false ? "red" : null }}
                  >
                    <Required />
                    Add Title
                  </label>
                  <Input
                    style={{ height:35 }}
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-title"
                    onChange={(e) => this.onChange(e.target.value, "title")}
                    placeholder="Add title"
                  />
                </FormGroup>
                </Col>
            </Row>
            
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-category_id"
                  >
                    Choose Category
                  </label>
                  <Input
                    value={category_id}
                    type="select"
                    bsSize="sm"
                    style={{marginRight: 10, width: "100%", color: validation.category_id === false ? "red" : null }}
                    onChange={async (e) => {
                      await this.onChange(e.target.value, "category_id");
                    }}
                  >
                    <option disabled value="">
                      Select Category
                    </option>
                    {categories.map((p) => (
                      <option value={p} key={p}>
                        {p}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-product_id"
                  >
                    Department
                  </label>
                  <Input
                    value={department_id}
                    type="select"
                    bsSize="sm"
                    style={{marginRight: 10, width: "100%", color: validation.department_id === false ? "red" : null }}
                    onChange={async (e) => {
                      await this.onChange(e.target.value, "department_id");
                    }}
                  >
                    <option disabled value="">
                      Select Department
                    </option>
                    {departments.map((p) => (
                      <option value={p} key={p}>
                        {p}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-product_id"
                  >
                    Study Level
                  </label>
                  <Input
                    value={studylevel_id}
                    type="select"
                    bsSize="sm"
                    style={{marginRight: 10, width: "100%", color: validation.studylevel_id === false ? "red" : null }}
                    onChange={async (e) => {
                      await this.onChange(e.target.value, "studylevel_id");
                    }}
                  >
                    <option disabled value="">
                      Select Study level
                    </option>
                    {studylevels.map((p) => (
                      <option value={p} key={p}>
                        {p}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-file"
                    style={{ color: validation.file === false ? "red" : null }}
                  >
                   
                    Upload Project
                  </label>
                  <Input
                    style={{ height: 41 }}
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-file"
                    
                    
                    onChange={(e) => this.onChange(e.target.files[0], "file")}
                    type="file"
                  />
                   <FormText color="muted">
                    you can only upload doc,pdf,txt files
                  </FormText>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-phone"
                    style={{ color: validation.price === false ? "red" : null }}
                  >
                    <Required />
                    Add Price
                  </label>
                  <Input
                    style={{ height: 41 }}
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-phone"
                    onChange={(e) => this.onChange(e.target.value, "price")}
                    placeholder="Add price"
                    type="number"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
                <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-phone"
                    //style={{ color: validation.page_numbers === false ? "red" : null }}
                  >
                    <Required />
                    Add No of pages
                  </label>
                  <Input
                    style={{ height: 41 }}
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-phone"
                    onChange={(e) => this.onChange(e.target.value, "page_numbers")}
                    placeholder="Add page number"
                    type="number"
                  />
                </FormGroup>
                </Col>
                <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-chapters"
                    //style={{ color: validation.chapters === false ? "red" : null }}
                  >
                    <Required />
                    Add No of chapters
                  </label>
                  <Input
                    style={{ height:35 }}
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-chapters"
                    onChange={(e) => this.onChange(e.target.value, "chapters")}
                    placeholder="Add Chapters"
                    type="number"
                  />
                </FormGroup>
                </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-phone"
                    style={{
                      color: validation.summary === false ? "red" : null,
                    }}
                  >
                    <Required />
                    Project Summary
                  </label>
                  
                  <SunEditor 
                  setOptions={{
                    height: 200,
                  buttonList: buttonList.complex // Or Array of button list, eg. [['font', 'align'], ['image']]
                  // Other option
                  }}
                  placeholder="Please type here..."
                  onChange={this.handleChange} />
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="modal-footer" style={{ padding: "1rem" }}>
            <Button
              size="sm"
              color="secondary"
              data-dismiss="modal"
              type="button"
              disabled={saving}
              onClick={toggle}
            >
              Close
            </Button>
            <Button
              color="success"
              type="button"
              disabled={saving}
              size="sm"
              onClick={this.onSave}
              style={{
                backgroundColor: "#EC3237",
                borderColor: "#EC3237",
                color: "#fff",
              }}
            >
              Save
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AddProject;
