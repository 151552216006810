import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormText,
  UncontrolledTooltip
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { getProject, updateProject  }  from 'services/resellerProjectService';
import { getUser } from "services/authService";
import SpinDiv from "components/SpinDiv.jsx";
import axios from "axios";
import settings from "../../../services/settings";
import { authHeader } from "../../../services/authHeader";
import { authService } from "../../../services/authService";
import { Alert } from "bootstrap";

class Project extends React.Component {
  constructor(props) {
    super(props);
    const { location: state } = props;
    this.state = {
      loading: false,
      edit: false,
      change: false,
      project: state && state.project ? state.project : null,
      validation: {},
      categories: [],
      format: {},
      studylevels: [],
      departments: [],
      id: props.match.params.id,
      users: []
    };
  }

  componentDidMount() {
    this.getProject();
  }

  getProject = () => {
    const { id } = this.state;
    this.setState({ loading: true });
    getProject(id).then(
      res => {
        console.log(res);
        this.setState({
          loading: false,
          project: { ...res.project },
          initialProject: { ...res.project },
          studylevels: res.studylevels,
          download:res.download,
          departments: res.departments,
          categories: res.categories,
          format: res.format,
          users: res.users
          //.map(p=>({label:p.name, value:p.id})),
        });
      },
      error => {
        this.setState({ loading: false });
      }
    );
  };

  toggleEdit = () => {
    const { initialProject } = this.state;
    this.setState({ edit: !this.state.edit, project: { ...initialProject } });
  };

  togglePassword = () => {
    this.setState({ change: !this.state.change });
  };

  onSave = async e => {
    e.preventDefault();
    await toast.dismiss();
    const { project, validation } = this.state;
    const {
      title,
      category_id,
      department_id,
      studylevel_id,
      chapters,
      summary,
      page_count,
      prices,
      file,
      reference,
      available,
      id
    } = project;
    await this.setState({
      validation: {
        ...validation,
        title: title !== "",
        category_id: category_id !== "",
        department_id: department_id !== "",
        studylevel_id: studylevel_id !== "",
        chapters: chapters !== "",
        summary: summary !== "",
        prices: prices !== "",
        page_count: page_count !== "",
        file: file !== "",
        reference: reference !== "",
        available: available !== ""
      }
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveProject();
    } else {
      const errors = Object.keys(this.state.validation).filter(id => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(
        () =>
          toast.error(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold" }}>Errors:</p>
              {errors.map(v => (
                <p key={v} style={{ margin: 0, fontSize: 14 }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          ),
        250
      );
    }
  };

  saveProject = () => {
    this.setState({ saving: true });
    let data = new FormData();
    let file = this.state.project.file;
    
    if (this.state.project.file) {
      data.append("file", file);
      data.set("title", this.state.project.title);
      data.set("category_id", this.state.project.category_id);
      data.set("department_id", this.state.project.department_id);
      data.set("studylevel_id", this.state.project.studylevel_id);
      data.set("chapters", this.state.project.chapters);
      data.set("summary", this.state.project.summary);
      data.set("page_count", this.state.project.page_count);
      data.set("prices", this.state.project.prices);
      data.set("reference", this.state.project.reference);
      data.set("available", this.state.project.available);
    return axios
      .post(
        `${settings.API_URL}reseller/updateproject/${this.state.project.id}`,
        data,
        {
          headers: authHeader()
        },
        authService.handleResponse
      )
      .then(res => {
        console.log(res.data);
        this.showToast("Project Updated");
        //document.getElementById('myedit').style.display = 'none';
        //document.getElementById('back').style.display = 'block';
        this.setState({ saving: false, edit: false });
        this.getProject(this.state.project.id);
      })
      .catch((err) => {
        //console.log(err.response.data.file);
        this.setState({
          errorMessage: err.response.data,
          show: true,
        });
        console.log(this.state.errorMessage);
        if (this.state.errorMessage.title) {
         alert(this.state.errorMessage.title[0]);
         
        }
        if (this.state.errorMessage.file) {
         alert(this.state.errorMessage.file[0]);
          
        }
        
        this.setState({ loading: false });
      });
    }else{
      const { project } = this.state;
        updateProject({ ...project }).then(
          (res) => {
            console.log(res);
            this.setState({
              saving: false,
              edit: false,
              project: { ...res.project },
              initialProject: { ...project },
              //target: res.target
            });
            this.showToast("Project Updated");
          },
          (error) => {
            console.log(error);
            this.setState({ saving: false });
          }
        );

    }
    
  };

  validationRules = field => {
    if (field === "title") {
      return "title is required";
    } else if (field === "category_id") {
      return "Category is required";
    } else if (field === "department_id") {
      return "Department is required";
    } else if (field === "studylevel_id") {
      return "Study level is required";
    } else if (field === "summary") {
      return "Summary is required";
    } else if (field === "reference") {
      return "Reference is required";
    } else if (field === "available") {
      return "Availability is required";
    } else if (field === "page_count") {
      return "Number of pages is required";
    } else if (field === "prices") {
      return "Price is required";
    } else if (field === "chapters") {
      return "Chapters is required";
    } else if (field === "file") {
      return "file is required";
    }
  };

  onChange = (e, state) => {
    console.log(e);
    const { project } = this.state;

    this.setState({ project: { ...project, [state]: e } });
  };
  handleChange = content => {
    const { project } = this.state;
    this.setState({ project: { ...project, summary: content } });
    //this.setState({summary:content });
    //Get Content Inside Editor
  };

  showToast = msg => {
    toast.error(<div style={{ padding: 20 }}>{msg}</div>);
  };

  render() {
    const {
      project,
      loading,
      validation,
      edit,
      download,
      studylevels,
      departments,
      categories,
      format,
      initialProject,
      saving,
      change,
      users
    } = this.state;
    const Required = () => <span style={{ color: "red" }}>*</span>;
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        minWidth: 170,
        height: 46,
        borderColor: "hsl(0,0%,95%)",
        boxShadow: "1px 1px 1px rgba(50, 50, 50, 0.1)"
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      }
    };

    return (
      <>
        <div
          className="header pb-8 pt-5 pt-md-6"
          style={{ backgroundColor: "#3E70D7" }}
        >
          <Container fluid>
            <div className="header-body">
              <Link
                to="/reseller/projects"
                className="white-link"
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer"
                }}
              >
                <i className=" ni ni-bold-left" style={{ marginRight: 5 }} />
                <span>Back to Projects</span>
              </Link>
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            {project && (
              <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                <Card className="card-profile shadow">
                  {project && (
                    <CardBody className="pt-0 pt-md-0">
                      <Row>
                        <div className="col">
                          <div className="card-profile-stats d-flex justify-content-center">
                            <div>
                              <span>Title: </span>
                              <span
                                style={{ display: "unset" }}
                                className="heading"
                              >
                                {project.title}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Row>
                      <div className="text-center">
                        {project.department_id && (
                          <div className="h5 font-weight-300">
                            <i className="ni location_pin mr-2" />
                            Department:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {project.department_id}
                            </span>
                          </div>
                        )}
                        {
                          <div className="h5 font-weight-300">
                            <i className="ni location_pin mr-2" />
                            Sold:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {download} times
                            </span>
                          </div>
                        }
                        {project.category_id && (
                          <div className="h5 mt-1 font-weight-300">
                            Category:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {project.category_id}
                            </span>
                          </div>
                        )}
                        {project.prices && (
                          <div className="h5 mt-1 font-weight-300">
                            price:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {project.prices}
                            </span>
                          </div>
                        )}
                        {project.page_count && (
                          <div className="h5 mt-1 font-weight-300">
                            Pages:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {project.page_count}
                            </span>
                          </div>
                        )}
                        {project.chapters && (
                          <div className="h5 mt-1 font-weight-300">
                            Chapters:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {project.chapters}
                            </span>
                          </div>
                        )}
                        {project.available && (
                          <div className="h5 mt-1 font-weight-300">
                            Availability:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {project.available}
                            </span>
                          </div>
                        )}

                        {project.reference && (
                          <div className="h5 mt-1 font-weight-300">
                            Reference:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {project.reference}
                            </span>
                          </div>
                        )}
                        {project.studylevel_id && (
                          <div className="h5 mt-1 font-weight-300">
                            Study Levels:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {project.studylevel_id}
                            </span>
                          </div>
                        )}
                        {console.log(format)}
                        {format && (
                          <div className="h5 mt-1 font-weight-300">
                            Format:{" "}
                            <span style={{ fontWeight: "bold" }}>{format}</span>
                          </div>
                        )}

                        {project.deleted && (
                          <div className="h5 mt-1 font-weight-300">
                            <span style={{ fontWeight: "bold", color: "red" }}>
                              {project.deleted == "yes"
                                ? "Deleted: A data associated with this project was deleted, update to retrieve project"
                                : ""}
                            </span>
                          </div>
                        )}
                        {project.approved && (
                          <div className="h5 mt-1 font-weight-300">
                            Approved:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {project.approved == 1
                                ? "Active"
                                : "Inactive(Not Approve)"}
                            </span>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  )}
                </Card>
              </Col>
            )}
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                {loading && <SpinDiv text={"Loading..."} />}
                {saving && <SpinDiv text={"Saving..."} />}
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      {initialProject && (
                        <h3 className="mb-0">
                          Owner: {initialProject.user.name}
                        </h3>
                      )}
                    </Col>
                    {initialProject &&
                    (getUser().admin ||
                      getUser().id === initialProject.user_id) ? (
                      <Col className="text-right" xs="4">
                        {project && (
                          <Button
                            color={edit ? "secondary" : "success"}
                            onClick={this.toggleEdit}
                            size="sm"
                          >
                            {edit ? "Discard Changes" : "Edit"}
                          </Button>
                        )}
                      </Col>
                    ) : (
                      <Col className="text-right" xs="4">
                        {project && (
                          <Button
                            color="success"
                            onClick={this.addSubProject}
                            size="sm"
                          >
                            Add Project
                          </Button>
                        )}
                      </Col>
                    )}
                  </Row>
                </CardHeader>
                {project && (
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-2">
                        Project information
                      </h6>
                      <div>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                style={{
                                  color:
                                    validation.title === false ? "red" : null
                                }}
                              >
                                <Required />
                                Title
                              </label>
                              <Input
                                value={project.title}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(e.target.value, "title");
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-file"
                                style={{
                                  color:
                                    validation.file === false ? "red" : null
                                }}
                              >
                                Upload Project
                              </label>
                              <Input
                                style={{ height: 41 }}
                                className="form-control-alternative"
                                defaultValue=""
                                id="input-file"
                                onChange={e =>
                                  this.onChange(e.target.files[0], "file")
                                }
                                type="file"
                              />
                              <FormText color="muted">
                                you can only upload doc,pdf,txt files
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-category_id"
                                style={{
                                  color:
                                    validation.category_id === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Category
                              </label>
                              <Input
                                value={project.category_id}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "category_id"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Category
                                </option>
                                {categories.map(p => (
                                  <option value={p} key={p}>
                                    {p}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-department_id"
                                style={{
                                  color:
                                    validation.department_id === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Department
                              </label>
                              <Input
                                value={project.department_id}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "department_id"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Department
                                </option>
                                {departments.map(p => (
                                  <option value={p} key={p}>
                                    {p}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-prices"
                              >
                                <Required />
                                Price
                              </label>
                              <Input
                                value={project.prices}
                                type="number"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(e.target.value, "prices");
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-studylevel_id"
                                style={{
                                  color:
                                    validation.studylevel_id === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Study Level
                              </label>
                              <Input
                                value={project.studylevel_id}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "studylevel_id"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Study Level
                                </option>
                                {studylevels.map(p => (
                                  <option value={p} key={p}>
                                    {p}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pagecount"
                              >
                              
                                Page Number
                              </label>
                              <Input
                                value={project.page_count}
                                type="number"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "page_count"
                                  );
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                
                                Chapters
                              </label>
                              <Input
                                value={project.chapters}
                                type="number"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "chapters"
                                  );
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-reference"
                              >
                                Choose Reference
                              </label>
                              <Input
                                value={project.reference}
                                disabled={!edit}
                                type="select"
                                bsSize="sm"
                                style={{
                                  marginRight: 10,
                                  width: "100%",
                                  color:
                                    validation.reference === false
                                      ? "red"
                                      : null
                                }}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "reference"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Choose Reference
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-available"
                              >
                                Choose Availability
                              </label>
                              <Input
                                 disabled={!edit}
                                value={project.available}
                                type="select"
                                bsSize="sm"
                                style={{
                                  marginRight: 10,
                                  width: "100%",
                                  color:
                                    validation.available === false
                                      ? "red"
                                      : null
                                }}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "available"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Choose Availability
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-territory"
                              >
                                Summary:
                                <Required />
                              </label>
                              <SunEditor
                                setOptions={{
                                  height: 200,
                                  buttonList: [
                                    [
                                      "undo",
                                      "redo",
                                      "font",
                                      "fontSize",
                                      "formatBlock",
                                      "paragraphStyle",
                                      "blockquote",
                                      "bold",
                                      "underline",
                                      "italic",
                                      "strike",
                                      "subscript",
                                      "superscript",
                                      "fontColor",
                                      "hiliteColor",
                                      "textStyle",
                                      "removeFormat",
                                      "outdent",
                                      "indent",
                                      "align",
                                      "horizontalRule",
                                      "list",
                                      "lineHeight",
                                      "table",
                                      "link",
                                      "image",
                                      "video",
                                      "audio" /** 'math', */, // You must add the 'katex' library at options to use the 'math' plugin.
                                      "fullScreen",
                                      "showBlocks",
                                      "codeView",
                                      "preview",
                                      "print",
                                      "save",
                                      "template"
                                    ]
                                  ] // Or Array of button list, eg. [['font', 'align'], ['image']]
                                  // Other option
                                }}
                                disable={!edit}
                                setContents={project.summary}
                                onChange={this.handleChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr style={{ marginBottom: 20, marginTop: 10 }} />
                      {edit && (
                        <div>
                          <Button
                            color="success"
                            type="button"
                            disabled={saving}
                            onClick={this.onSave}
                            style={{
                              backgroundColor: "#EC3237",
                              borderColor: "#EC3237",
                              color: "#fff"
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      )}
                    </Form>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Project;
