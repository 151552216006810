import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Button,
  Input,
  Badge
} from "reactstrap";
import { toast } from 'react-toastify';
import "views/index.css";
import { getSeminars }  from 'services/seminarService';
import DeleteSeminar from "./DeleteSeminar";
import {getUser} from 'services/authService'
import SpinDiv from "components/SpinDiv.jsx";
import CustomPagination from "components/CustomPagination.jsx";

class Seminar extends React.Component {
  state = {
    activeNav: 1,
    loading: true,
    seminars:[],
  	page: 1,
  	rows: 10,
  	total: 0,
    search: '',
    plans:[],
  };

  componentDidMount() {
    this.getSeminars();
  }

  componentWillMount() {
  }

  

  getSeminars = () => {
    const {plan,user, page, rows, search} = this.state;
    this.setState({loading: true});
    getSeminars({user, page,plan, rows, search})
      .then((res) => {console.log(res)
        this.setState({
          seminars: res.seminars.data,
          plans:res.plans,
          page: res.seminars.current_page,
          total: res.seminars.total,
          loading: false,
        })
      },
      error => {
        this.setState({loading: false});
      });
  }

  


  onChange = (e, state) => {
    this.setState({[state]: e})
  }
 
  
  onPage = async (page) => {
    const {search} = this.state;
    await this.setState({page});
    this.getSeminars();
    if(search===''){
      await this.getSeminars();
    } else {
      await this.search();
    }
  }

  onSearch = () => {

  }

  

  
  toggleDelete = (deleteSeminar) => {
    this.setState({ deleteSeminar });
  };
  
  showToast = msg => {
    toast.error(<div style={{padding:25}}>{msg}</div>);
  }


  render() {
    const {plans,seminars,deleteSeminar, total, page, rows, search, loading} = this.state;
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#3E70D7'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        {deleteSeminar && (
          <DeleteSeminar
            saved={this.getSeminars}
            deleteSeminar={deleteSeminar}
            toggle={() => this.setState({ deleteSeminar: null })}
          />
        )}
          
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center" style={{justifyContent:'space-between', padding: '0 15px', overflowX: 'scroll', overflow: 'visible', flexWrap: 'nowrap'}}>
                    <div>
                      <h3 className="mb-0">Requests <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h3>
                    </div>
                    <div className="mobile-flex">
                      <Input placeholder="Search..." style={{maxHeight: 28, marginRight: 5, minWidth: 80}}
                        value={search}
                        onChange={e=>this.onChange(e.target.value, 'search')}
                        onKeyUp={(e) => {if(e.key==='Enter'){this.getSeminars();}}} />
                      <Button className="btn-icon btn-2 hide-mobile" color="secondary" size="sm" style={{marginRight: 10}} onClick={this.getSeminars}>
                        <i className="fa fa-search" />
                      </Button>
                     
                    </div>
                  </Row>
                </CardHeader>
                <Table className={`align-items-center table-flush ${loading?'load-opacity':''}`} responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Topic</th>
                      <th scope="col">Department</th>
                      <th scope="col">Date created</th>
                      <th scope="col">Completion Date</th>
                      <th scope="col">status</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {seminars.map((seminar, key) => {

                      return (
                        <tr >
                        
                          <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                        this.props.history.push('/admin/seminar/'+seminar.id)}}>{seminar.full_name}</td>
                          <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                        this.props.history.push('/admin/seminar/'+seminar.id)}}>{seminar.phone}</td>
                          <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                        this.props.history.push('/admin/seminar/'+seminar.id)}}>{seminar.topic}</td>
                          <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                        this.props.history.push('/admin/seminar/'+seminar.id)}}>{seminar.department}</td>
                          <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                        this.props.history.push('/admin/seminar/'+seminar.id)}}>{seminar.created_at}</td>
                          <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                        this.props.history.push('/admin/seminar/'+seminar.id)}}>{seminar.delivery_date}</td>
                          <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                        this.props.history.push('/admin/seminar/'+seminar.id)}}>{seminar.status}</td>
                          <td>
                            <Button
                              color="danger"
                              onClick={() => this.toggleDelete(seminar)}
                              size="sm"
                            >
                              Delete
                            </Button>
                          </td>
                           
                        </tr>
                        
                        );
                      })}
                  </tbody>
                </Table>
                {!loading&&seminars.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Seminar found
                </div>}
               
                {seminars.length>0&&<CardFooter className={`py-4 ${loading?'load-opacity':''}`}>
                  <CustomPagination page={page} total={total} rows={rows} onPage={this.onPage} />
                </CardFooter>}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Seminar;
