import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Input,
} from "reactstrap";
import DeleteHirewriter from "./DeleteHirewriter";
import { getHirewriters } from "services/hirewriterService";
import AddHirewriter from "./AddHirewriter";
//import EditCategory from "./EditCategory";

import SpinDiv from "components/SpinDiv.jsx";
import CustomPagination from "components/CustomPagination.jsx";
import "views/index.css";
import { deleteHirewriter } from "../../../services/hirewriterService";
import {Pagination} from 'antd'
class Hirewriter extends React.Component {
  state = {
    page: 1,
    total: 0,
    search: "",
    plans: [],
    categories:[],
    rows:10
  };
 
  componentDidMount() {
    this.getHirewriters();
  }

  getHirewriters = () => {
    const { state, page, search } = this.state;
    this.setState({ loading: true });
    getHirewriters({ state, page, search }).then(
      (res) => {
        this.setState({
          plans: res.plans.data,
          categories:res.categories,
          page: res.plans.current_page,
          total: res.plans.total,
          loading: false,
        });
      },
      (error) => {
        console.log(error);
        this.setState({ loading: false });
      }
    );
  };

  toggleAdd = () => {
    this.setState({ add: !this.state.add });
  };
  onPage = async (page, rows) => {
    const {search} = this.state;
    await this.setState({page, rows});
    if(search===''){
      await this.getHirewriters();
    } else {
      await this.search();
    }
  }

  /*toggleEdit = (editCategory) => {
    this.setState({ editCategory });
  };*/

  toggleDelete = (deleteHirewriter) => {
    this.setState({ deleteHirewriter });
  };

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };
  render() {
    const {
      add,
      loading,
      search,
      plans,
      editCategory,
      categories,
      deleteHirewriter,
      total, page, rows,

    } = this.state;

    return (
      <>
        <div
          className="header pb-8 pt-5 pt-md-6"
          style={{ backgroundColor: "#3E70D7" }}
        >
          <Container fluid>
            <div className="header-body">
              <Link
                to="/admin/settings"
                className="white-link"
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <i className=" ni ni-bold-left" style={{ marginRight: 5 }} />
                <span>Back to Settings</span>
              </Link>
            </div>
          </Container>
        </div>
        {/* Page content */}
        {add && (
          <AddHirewriter
            saved={this.getHirewriters}
            toggle={this.toggleAdd}
            categories={categories}
            add={add}
          />
        )}
        {/*editCategory && (
          <EditCategory
            saved={this.getCategories}
            editCategory={editCategory}
            toggle={() => this.setState({ editCategory: null })}
          />
        )*/}
        {deleteHirewriter && (
          <DeleteHirewriter
            saved={this.getHirewriters}
            deleteHirewriter={deleteHirewriter}
            toggle={() => this.setState({ deleteHirewriter: null })}
          />
        )}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading && <SpinDiv text={"Loading..."} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Hirewriter Plans </h3>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Input
                        placeholder="Search..."
                        style={{ maxHeight: 28, marginRight: 5 }}
                        value={search}
                        onChange={(e) =>
                          this.onChange(e.target.value, "search")
                        }
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            this.getHirewriters();
                          }
                        }}
                      />
                      <Button
                        className="btn-icon btn-2"
                        color="secondary"
                        size="sm"
                        style={{ marginRight: 10 }}
                        onClick={this.getHirewriters}
                      >
                        <i className="fa fa-search" />
                      </Button>
                      <Button
                        color="success"
                        onClick={this.toggleAdd}
                        size="sm"
                      >
                        Create Plan
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Title</th>
                      <th scope="col">Plan Type</th>
                      <th scope="col">Chapters</th>
                      <th scope="col">Price</th>
                      <th scope="col">Data Analysis</th>
                      <th scope="col"></th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {plans.map((plan) => {
                      return (
                       
                        <tr  key={plan.id}   className="hover-list">
                          <th scope="row" onClick={() => {//console.log('111')
                        this.props.history.push(`/admin/settings/hirewriters/${plan.id}`)}}>{plan.plan_title}</th>
                          <td onClick={() => {//console.log('111')
                        this.props.history.push(`/admin/settings/hirewriters/${plan.id}`)}}>{plan.plan_type}</td>
                          <td onClick={() => {//console.log('111')
                        this.props.history.push(`/admin/settings/hirewriters/${plan.id}`)}}>{plan.chapters}</td>
                          <td onClick={() => {//console.log('111')
                        this.props.history.push(`/admin/settings/hirewriters/${plan.id}`)}}>{plan.price}</td>
                          <td onClick={() => {//console.log('111')
                        this.props.history.push(`/admin/settings/hirewriters/${plan.id}`)}}>{plan.data_analysis}</td>
                          <td>
                            
                            <Button
                              color="danger"
                              onClick={() => this.toggleDelete(plan)}
                              size="sm"
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {!loading && plans.length < 1 && (
                  <div
                    style={{
                      color: "#ccc",
                      alignSelf: "center",
                      padding: 10,
                      fontSize: 13,
                    }}
                  >
                    <i className="fa fa-ban" style={{ marginRight: 5 }} />
                    No Category found
                  </div>
                )}
                 <Row style={{margin:10}}>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {plans.length > 0 && <Pagination
                  total={total}
                  showSizeChanger
                  showTotal={total => `Total ${total} Plans`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Hirewriter;
