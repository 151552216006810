import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';


export function getActivity(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}activity/${id}`, requestOptions).then(authService.handleResponse);
}

export function getActivities(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}activities`, requestOptions).then(authService.handleResponse);
}

export function deleteMultActivity(data) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
         body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}delete_multactivity`, requestOptions).then(authService.handleResponse);
}

