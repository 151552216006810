/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from "layouts/Admin.jsx";
import StaffLayout from "layouts/Staff.jsx";
import AuthLayout from "layouts/Auth.jsx";
import ResellerLayout from "layouts/Reseller.jsx";
import AuthResellerLayout from "layouts/AuthReseller.jsx";
import ResetPassword from "./views/users/ResetPassword";
import 'antd/dist/antd.css';


ReactDOM.render(
  <BrowserRouter>
   <Switch>
      <Route exact path="/auth/reset-password/:id" render={props => <ResetPassword {...props} />} />
      <Route path="/admin" render={props => <AdminLayout {...props} />} />
      <Route path="/staff" render={props => <StaffLayout {...props} />} />
      <Route path="/auth" render={props => <AuthLayout {...props} />} />
      <Route path="/authreseller" render={props => <AuthResellerLayout {...props} />} />
      <Route path="/reseller" render={props => <ResellerLayout {...props} />} />
      <Redirect from="/" to="/admin/home" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
