import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const categoryService = {
    addCategory,
    getCategories,
    updateCategory,
    deleteCategory
};

export function getCategories(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}bcategories`, requestOptions).then(authService.handleResponse);
}

export function addCategory(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addbcategory`, requestOptions).then(authService.handleResponse);
}

export function updateCategory(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updatebcategory/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteCategory(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletebcategory/${id}`, requestOptions).then(authService.handleResponse);
}
