import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Button,
  Input,
  Badge
} from "reactstrap";
import { toast } from 'react-toastify';
import "views/index.css";
import { getEssays }  from 'services/essayService';
import {getUser} from 'services/authService'
import SpinDiv from "components/SpinDiv.jsx";
import DownloadProject from "./DownloadProject";
import {Pagination} from 'antd'

class Essay extends React.Component {
  state = {
    activeNav: 1,
    loading: true,
    essays:[],
  	page: 1,
  	rows: 10,
  	total: 0,
    search: '',
    plans:[],
  };

  componentDidMount() {
    this.getEssays();
  }

  componentWillMount() {
  }

  

  getEssays = () => {
    const {plan,user, page, rows, search} = this.state;
    this.setState({loading: true});
    getEssays({user, page,plan, rows, search})
      .then((res) => {console.log(res)
        this.setState({
          essays: res.essays.data,
          plans:res.plans,
          page: res.essays.current_page,
          total: res.essays.total,
          loading: false,
        })
      },
      error => {
        this.setState({loading: false});
      });
  }

  


  onChange = (e, state) => {
    this.setState({[state]: e})
  }

  toggleDownload= (downloadProject) => {
    this.setState({ downloadProject });
  };
 
  
  onPage = async (page, rows) => {
    const {search} = this.state;
    await this.setState({page, rows});
    this.getEssays();
    if(search===''){
      await this.getEssays();
    } else {
      await this.search();
    }
  }

  onSearch = () => {

  }

  

  

  
  showToast = msg => {
    toast.error(<div style={{padding:25}}>{msg}</div>);
  }


  render() {
    const {plans, downloadProject,essays, total, page, rows, search, loading} = this.state;
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#3E70D7'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
       
        {downloadProject && (
          <DownloadProject
            saved={this.getEssays}
            downloadProject={downloadProject}
            toggle={() => this.setState({ downloadProject: null })}
          />
        )}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center" style={{justifyContent:'space-between', padding: '0 15px', overflowX: 'scroll', overflow: 'visible', flexWrap: 'nowrap'}}>
                    <div>
                      <h3 className="mb-0">Essay Requests <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h3>
                    </div>
                    <div className="mobile-flex">
                      <Input placeholder="Search..." style={{maxHeight: 28, marginRight: 5, minWidth: 80}}
                        value={search}
                        onChange={e=>this.onChange(e.target.value, 'search')}
                        onKeyUp={(e) => {if(e.key==='Enter'){this.getEssays();}}} />
                      <Button className="btn-icon btn-2 hide-mobile" color="secondary" size="sm" style={{marginRight: 10}} onClick={this.getEssays}>
                        <i className="fa fa-search" />
                      </Button>
                     
                    </div>
                  </Row>
                </CardHeader>
                <Table className={`align-items-center table-flush ${loading?'load-opacity':''}`} responsive>
                  <thead className="thead-light">
                    <tr>
                   
                      <th scope="col">Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Topic</th>
                      <th scope="col">Date Submitted</th>
                      <th scope="col">Completion Date</th>
                      <th scope="col">status</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {essays.map((essay, key) => {

                      return (
                        <tr key={key} className="hover-list" to="/" >
                          <td onClick={() => {//console.log('111')
                        this.props.history.push('/admin/essay/'+essay.id)}}>{essay.fullname}</td>
                          <td onClick={() => {//console.log('111')
                        this.props.history.push('/admin/essay/'+essay.id)}}>{essay.phone}</td>
                          <td onClick={() => {//console.log('111')
                        this.props.history.push('/admin/essay/'+essay.id)}}>{essay.title}</td>
                          <td onClick={() => {//console.log('111')
                        this.props.history.push('/admin/essay/'+essay.id)}}>{essay.created_at}</td>
                          <td onClick={() => {//console.log('111')
                        this.props.history.push('/admin/essay/'+essay.id)}}>{essay.delivery}</td>
                          <td onClick={() => {//console.log('111')
                        this.props.history.push('/admin/essay/'+essay.id)}}>{essay.status}</td>
                          {essay.file !== null ? <td>
                            
                            <Button
                              color="primary"
                              onClick={() => this.toggleDownload(essay)}
                              size="sm"
                            >
                              Download
                            </Button>
                          </td> : null }
                           
                        </tr>
                        
                        );
                      })}
                  </tbody>
                </Table>
                {!loading&&essays.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Essay found
                </div>}
               
                <Row style={{margin:10}}>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {essays.length > 0 && <Pagination
                  total={total}
                  showSizeChanger
                  showTotal={total => `Total ${total} Essays`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Essay;
