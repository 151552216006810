import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const blogtagService = {
    addTag,
    getTags,
    updateTag,
    deleteTag
};

export function getTags(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}ptags`, requestOptions).then(authService.handleResponse);
}

export function addTag(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addptag`, requestOptions).then(authService.handleResponse);
}

export function updateTag(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateptag/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteTag(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleteptag/${id}`, requestOptions).then(authService.handleResponse);
}
