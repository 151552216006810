import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  FormText,
  Col,
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getProject } from "services/projectService";
import SpinDiv from "components/SpinDiv.jsx";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import { getUser } from "services/authService";
import { Select } from "antd";

const emailReg =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneReg = /^([0]\d{10})$/;
const { Option } = Select;
class Project extends React.Component {
  constructor(props) {
    super(props);
    const { location: state } = props;
    this.state = {
      loading: false,
      edit: false,
      change: false,
      project: state && state.project ? state.project : null,
      validation: {},
      categories: [],
      tags: [],
      tag_id: [],
      format: {},
      studylevels: [],
      departments: [],
      id: props.match.params.id,
      users: [],
      file: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.formats = [
      "header",
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
      "video",
      "align",
      "color",
      "background",
    ];
    this.modules = {
      toolbar: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image", "video"],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["clean"],
      ],
    };
  }

  componentDidMount() {
    this.getProject();
  }

  getProject = () => {
    const { id } = this.state;
    this.setState({ loading: true });
    getProject({ id }).then(
      (res) => {
        console.log(res);
        this.setState({
          loading: false,
          project: res.project,
          tag_id: res.project.tags.map((tag, key) => {
            return tag.id;
          }),
          studylevels: res.studylevels,
          departments: res.departments,
          downloads: res.downloads,
          categories: res.categories,
          summary: String(res.project.summary),
          tags: res.tags,
          format: res.format,
          users: res.users,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  toggleEdit = () => {
    const { project } = this.state;
    this.setState({ edit: !this.state.edit, project: { ...project } });
  };

  togglePassword = () => {
    this.setState({ change: !this.state.change });
  };

  handleTagChange = (value) => {
    console.log(value);
    this.setState({
      tag_id: value,
    });
  };

  fileChangedHandler = (e) => {
    this.setState({ file: [...this.state.file, ...e.target.files] });
  };

  loadOptions = (data) => async (search, prevOptions) => {
    await this.sleep(1000);

    let filteredOptions;
    if (!search) {
      filteredOptions = data;
    } else {
      const searchLower = search.toLowerCase();

      filteredOptions = data.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore,
    };
  };

  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { project, file, validation, tag_id } = this.state;
    const {
      title,
      category_id,
      department_id,
      studylevel_id,
      approved,
      chapters,
      summary,
      page_count,
      prices,
      reference,
      available,
      id,
    } = project;
    await this.setState({
      validation: {
        ...validation,
        title: title !== "",
        category_id: category_id !== "",
        department_id: department_id !== "",
        studylevel_id: studylevel_id !== "",
        approved: approved !== "",
        chapters: chapters !== "",
        summary: summary !== "",
        prices: prices !== "",
        page_count: page_count !== "",
        approved: approved !== "",
        //file: file !== "",
        //tag_id: tag_id.length > 0,
        reference: reference !== "",
        available: available !== "",
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      console.log(title);
      console.log(tag_id);
      this.saveProject();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });

      toast(
        <div style={{ padding: "10px 20px" }}>
          <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
          {errors.map((v) => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
              * {this.validationRules(v)}
            </p>
          ))}
        </div>
      );
    }
  };

  saveProject = () => {
    this.setState({ saving: true });
    const { file, tag_id } = this.state;
    let data = new FormData();

    if (tag_id.length > 0) {
      for (var i in tag_id) {
        data.set(`tag_id[${i}]`, tag_id[i]);
      }
    }

    if (file.length > 0) {
      let op = file;
      for (var i in op) {
        data.append(`file[${i}]`, op[i]);
      }
    }
    data.set("title", this.state.project.title);
    data.set("project_id", this.state.project.id);
    data.set("category_id", this.state.project.category_id);
    data.set("department_id", this.state.project.department_id);
    data.set("studylevel_id", this.state.project.studylevel_id);
    data.set("approved", this.state.project.approved);
    data.set("chapters", this.state.project.chapters);
    data.set("summary", this.state.summary);
    data.set("page_count", this.state.project.page_count);
    data.set("prices", this.state.project.prices);
    data.set("reference", this.state.project.reference);
    data.set("available", this.state.project.available);
    return axios
      .post(
        `${settings.API_URL}updateproject/${this.state.project.id}`,
        data,
        {
          headers: authHeader(),
        },
        authService.handleResponse
      )
      .then((res) => {
        console.log(res.data);
        this.showToast("Project Updated");

        this.setState({ saving: false, edit: false });
        this.getProject(this.state.project.id);
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          errorMessage: err.response.data,
          show: true,
        });
        console.log(this.state.errorMessage);
        if (this.state.errorMessage.title) {
          alert(this.state.errorMessage.title[0]);
        }
        if (this.state.errorMessage.file) {
          alert(this.state.errorMessage.file[0]);
        }
        this.setState({ saving: false });
      });
  };

  validationRules = (field) => {
    if (field === "title") {
      return "title is required";
    } else if (field === "category_id") {
      return "Category is required";
    } else if (field === "department_id") {
      return "Department is required";
    } else if (field === "studylevel_id") {
      return "Study level is required";
    } else if (field === "summary") {
      return "Summary is required";
    } else if (field === "reference") {
      return "Reference is required";
    } else if (field === "available") {
      return "Availability is required";
    } else if (field === "prices") {
      return "Price is required";
    } else if (field === "file") {
      return "file is required";
    } else if (field === "tag_id") {
      return "Tag is required";
    }
  };

  onChange = (e, state) => {
    console.log(e);
    const { project } = this.state;

    this.setState({ project: { ...project, [state]: e } });
  };
  // handleChange = (content) => {
  //   const { project } = this.state;
  //   this.setState({ project: { ...project, content: content } });
  // };

  handleChange(summary) {
    this.setState({ summary });
  }

  showToast = (msg) => {
    toast.error(<div style={{ padding: 20 }}>{msg}</div>);
  };

  render() {
    const {
      project,
      summary,
      loading,
      validation,
      edit,
      studylevels,
      departments,
      categories,
      downloads,
      tags,
      format,
      tag_id,
      saving,
      change,
      users,
      contentState,
    } = this.state;

    const Required = () => <span style={{ color: "red" }}>*</span>;

    return (
      <>
        {loading && <SpinDiv text={"Loading..."} />}
        {saving && <SpinDiv text={"Saving..."} />}
        <div
          className="header pb-8 pt-5 pt-md-6"
          style={{ backgroundColor: "#3E70D7" }}
        >
          <Container fluid>
            <div className="header-body">
              <Link
                to="/admin/projects"
                className="white-link"
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <i className=" ni ni-bold-left" style={{ marginRight: 5 }} />
                <span>Back to Projects</span>
              </Link>
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col md="8">
              <Card className="bg-secondary shadow">
                {loading && <SpinDiv text={"Loading..."} />}
                {saving && <SpinDiv text={"Saving..."} />}
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      {project && (
                        <h3 className="mb-0">Owner: {project.user.name}</h3>
                      )}
                    </Col>

                    {project &&
                    (getUser().admin || getUser().id === project.user_id) ? (
                      <Col className="text-right" xs="4">
                        {project && (
                          <Button
                            color={edit ? "secondary" : "success"}
                            onClick={this.toggleEdit}
                            size="sm"
                          >
                            {edit ? "Discard Changes" : "Edit"}
                          </Button>
                        )}
                      </Col>
                    ) : (
                      <Col className="text-right" xs="4">
                        {project && (
                          <Button
                            color="success"
                            onClick={this.addSubProject}
                            size="sm"
                          >
                            Add Project
                          </Button>
                        )}
                      </Col>
                    )}
                  </Row>
                  <h5 className="mb-0">
                    Total Downloads
                    <span
                      style={{
                        color: "#aaa",
                        fontSize: 14,
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      ({downloads})
                    </span>
                  </h5>
                </CardHeader>
                {project && tags && tag_id && (
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-2">
                        Project information
                      </h6>
                      <div>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                style={{
                                  color:
                                    validation.title === false ? "red" : null,
                                }}
                              >
                                <Required />
                                Title
                              </label>
                              <Input
                                value={project.title}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async (e) => {
                                  await this.onChange(e.target.value, "title");
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-file"
                                style={{
                                  color:
                                    validation.file === false ? "red" : null,
                                }}
                              >
                                Upload Project
                              </label>
                              <Input
                                style={{ height: 41 }}
                                className="form-control-alternative"
                                defaultValue=""
                                id="input-file"
                                onChange={this.fileChangedHandler}
                                type="file"
                              />
                              <FormText color="muted">
                                you can only upload doc,pdf,txt files
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-category_id"
                                style={{
                                  color:
                                    validation.category_id === false
                                      ? "red"
                                      : null,
                                }}
                              >
                                <Required />
                                Category
                              </label>
                              <Input
                                value={project.category_id}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async (e) => {
                                  await this.onChange(
                                    e.target.value,
                                    "category_id"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Category
                                </option>
                                {categories.map((p) => (
                                  <option value={p} key={p}>
                                    {p}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Select
                              mode="multiple"
                              style={{ width: "100%" }}
                              placeholder="select project tags"
                              onChange={this.handleTagChange}
                              optionLabelProp="label"
                              optionFilterProp="children"
                              defaultValue={tag_id}
                            >
                              {tags.map((tag, key) => {
                                return (
                                  <option value={tag.id} label={tag.title}>
                                    {tag.title}
                                  </option>
                                );
                              })}
                            </Select>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-department_id"
                                style={{
                                  color:
                                    validation.department_id === false
                                      ? "red"
                                      : null,
                                }}
                              >
                                <Required />
                                Department
                              </label>
                              <Input
                                value={project.department_id}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async (e) => {
                                  await this.onChange(
                                    e.target.value,
                                    "department_id"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Department
                                </option>
                                {departments.map((p) => (
                                  <option value={p} key={p}>
                                    {p}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-prices"
                              >
                                <Required />
                                Price
                              </label>
                              <Input
                                value={project.prices}
                                type="number"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async (e) => {
                                  await this.onChange(e.target.value, "prices");
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-studylevel_id"
                                style={{
                                  color:
                                    validation.studylevel_id === false
                                      ? "red"
                                      : null,
                                }}
                              >
                                <Required />
                                Study Level
                              </label>
                              <Input
                                value={project.studylevel_id}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async (e) => {
                                  await this.onChange(
                                    e.target.value,
                                    "studylevel_id"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Study Level
                                </option>
                                {studylevels.map((p) => (
                                  <option value={p} key={p}>
                                    {p}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pagecount"
                              >
                                Page Number
                              </label>
                              <Input
                                value={project.page_count}
                                type="number"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async (e) => {
                                  await this.onChange(
                                    e.target.value,
                                    "page_count"
                                  );
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-available"
                              >
                                Choose Approval
                              </label>
                              <Input
                                disabled={!edit}
                                value={project.approved}
                                type="select"
                                bsSize="sm"
                                style={{
                                  marginRight: 10,
                                  width: "100%",
                                  color:
                                    validation.available === false
                                      ? "red"
                                      : null,
                                }}
                                onChange={async (e) => {
                                  await this.onChange(
                                    e.target.value,
                                    "approved"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Approve Project
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                Chapters
                              </label>
                              <Input
                                value={project.chapters}
                                type="number"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async (e) => {
                                  await this.onChange(
                                    e.target.value,
                                    "chapters"
                                  );
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-reference"
                              >
                                Choose Reference
                              </label>
                              <Input
                                value={project.reference}
                                disabled={!edit}
                                type="select"
                                bsSize="sm"
                                style={{
                                  marginRight: 10,
                                  width: "100%",
                                  color:
                                    validation.reference === false
                                      ? "red"
                                      : null,
                                }}
                                onChange={async (e) => {
                                  await this.onChange(
                                    e.target.value,
                                    "reference"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Choose Reference
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-available"
                              >
                                Choose Availability
                              </label>
                              <Input
                                disabled={!edit}
                                value={project.available}
                                type="select"
                                bsSize="sm"
                                style={{
                                  marginRight: 10,
                                  width: "100%",
                                  color:
                                    validation.available === false
                                      ? "red"
                                      : null,
                                }}
                                onChange={async (e) => {
                                  await this.onChange(
                                    e.target.value,
                                    "available"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Choose Availability
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-territory"
                              >
                                Summary:
                                <Required />
                              </label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <ReactQuill
                            theme="snow"
                            modules={this.modules}
                            formats={this.formats}
                            value={summary}
                            onChange={this.handleChange}
                          />
                          {/* <Editor
                          
                          value={summary}
                          onEditorChange={this.handleEditorChange}

                          initialValue={project.summary}
                          init={{
                            height: 300,
                            menubar: true,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                          }}

                        /> */}
                        </Row>
                      </div>
                      <hr style={{ marginBottom: 20, marginTop: 10 }} />
                      {edit && (
                        <div>
                          <Button
                            color="success"
                            type="button"
                            disabled={saving}
                            onClick={this.onSave}
                            style={{
                              backgroundColor: "#EC3237",
                              borderColor: "#EC3237",
                              color: "#fff",
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      )}
                    </Form>
                  </CardBody>
                )}
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-profile shadow">
                {project && (
                  <CardBody className="pt-0 pt-md-0">
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center">
                          <div>
                            <span>Title: </span>
                            <span
                              style={{ display: "unset" }}
                              className="heading"
                            >
                              {project.title}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <div className="text-center">
                      {project.department_id && (
                        <div className="h5 font-weight-300">
                          <i className="ni location_pin mr-2" />
                          Department:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {project.department_id}
                          </span>
                        </div>
                      )}
                      {project.category_id && (
                        <div className="h5 mt-1 font-weight-300">
                          Category:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {project.category_id}
                          </span>
                        </div>
                      )}
                      {project.prices && (
                        <div className="h5 mt-1 font-weight-300">
                          price:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {project.prices}
                          </span>
                        </div>
                      )}
                      {project.page_count && (
                        <div className="h5 mt-1 font-weight-300">
                          Pages:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {project.page_count}
                          </span>
                        </div>
                      )}
                      {project.chapters && (
                        <div className="h5 mt-1 font-weight-300">
                          Chapters:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {project.chapters}
                          </span>
                        </div>
                      )}
                      {project.available && (
                        <div className="h5 mt-1 font-weight-300">
                          Availability:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {project.available}
                          </span>
                        </div>
                      )}
                      {downloads && (
                        <div className="h5 mt-1 font-weight-300">
                          Total Downloads:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {downloads}
                          </span>
                        </div>
                      )}

                      {project.reference && (
                        <div className="h5 mt-1 font-weight-300">
                          Reference:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {project.reference}
                          </span>
                        </div>
                      )}
                      {project.studylevel_id && (
                        <div className="h5 mt-1 font-weight-300">
                          Study Levels:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {project.studylevel_id}
                          </span>
                        </div>
                      )}
                      {project.studylevel_id && (
                        <div className="h5 mt-1 font-weight-300">
                          Uploaded file status:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {project.filename ? "Yes" : "No"}
                          </span>
                        </div>
                      )}
                      {console.log(format)}
                      {format && (
                        <div className="h5 mt-1 font-weight-300">
                          Format:{" "}
                          <span style={{ fontWeight: "bold" }}>{format}</span>
                        </div>
                      )}

                      {project.deleted && (
                        <div className="h5 mt-1 font-weight-300">
                          <span style={{ fontWeight: "bold", color: "red" }}>
                            {project.deleted == "yes"
                              ? "Deleted: A data associated with this project was deleted, update to retrieve project"
                              : ""}
                          </span>
                        </div>
                      )}
                      {project.approved && (
                        <div className="h5 mt-1 font-weight-300">
                          Approved:{" "}
                          <span style={{ fontWeight: "bold", color: "green" }}>
                            {project.approved == 1
                              ? "Active"
                              : "Inactive(Not Approve)"}
                          </span>
                        </div>
                      )}
                    </div>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Project;
