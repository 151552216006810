import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const projectService = {
    addProject,
    getProjects,
    updateProject,
    getStat,
};

export function getProjects(data) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}projects`, requestOptions).then(authService.handleResponse);
}

export function getProjectSpice(data) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}projects_spice`, requestOptions).then(authService.handleResponse);
}

export function getSearch(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}projects/search`, requestOptions).then(authService.handleResponse);
}

export function addProject(data) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
         body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addproject`, requestOptions).then(authService.handleResponse);
}

export function deleteMultProject(data) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
         body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}delete_multproject`, requestOptions).then(authService.handleResponse);
}

export function getStat() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}admin/projectsold`, requestOptions).then(authService.handleResponse);
}


export function getProject(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
         body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}singleproject`, requestOptions).then(authService.handleResponse);
}

export function updateProject(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateproject/${data.id}`, requestOptions).then(authService.handleResponse);
}
export function downloadProject(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}download/${id}`, requestOptions).then(authService.handleResponse);
}
export function deleteProject(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleteproject2/${id}`, requestOptions).then(authService.handleResponse);
}
