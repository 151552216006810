import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Input
} from "reactstrap";
import { toast } from "react-toastify";

import { addUser } from "services/userService";
import { filter } from "services/authService";
import SpinDiv from "components/SpinDiv.jsx";

const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneReg = /^([0]\d{10})$/;

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNav: 1,
      loading: false,
      status: true,
      admin: false,
      validation: {},
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      phone: "",
      address: ""
    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
  }

  onSave = async e => {
    e.preventDefault();
    await toast.dismiss();
    const {
      firstname,
      lastname,
      email,
      password,
      role,
      phone,
      address,
      validation
    } = this.state;
    await this.setState({
      validation: {
        ...validation,
        firstname: firstname !== "",
        lastname: lastname !== "",
        role: role !== "",
        email: emailReg.test(email),
        phone: phoneReg.test(phone),
        password: password.length >= 6
      }
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveUser();
    } else {
      const errors = Object.keys(this.state.validation).filter(id => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(
        () =>
          toast.error(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold" }}>Errors:</p>
              {errors.map(v => (
                <p key={v} style={{ margin: 0, fontSize: 14 }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          ),
        250
      );
    }
  };

  validationRules = field => {
    if (field === "firstname") {
      return "First name is required";
    } else if (field === "lastname") {
      return "Last name is required";
    } else if (field === "email") {
      return "Email is invalid";
    } else if (field === "password") {
      return "Password must be at least 6 characters long";
    } else if (field === "phone") {
      return "Phone number is invalid";
    
  } else if (field === "role") {
    return "Role is required";
  }
  };

  saveUser = () => {
    this.setState({ loading: true });
    const {
      firstname,
      lastname,
      password,
      email,
      phone,
      status,
      admin,
      address,
      role
    } = this.state;
    console.log(this.state.status);

    addUser({
      firstname,
      lastname,
      password,
      email,
      phone,
      status,
      admin,
      address,
      role
    }).then(
      res => {
        console.log(res);
        this.setState({ loading: false });
        this.props.saved();
        this.props.toggle();
        this.showToast("User Created");
      },
      error => {
        console.log(error);
        if (error.email) {
          this.showToast("A user with this Email address already exists");
        }
        if (error.phone) {
          this.showToast("A user with this Phone number already exists");
        }
        this.setState({ loading: false });
      }
    );
  };

  showToast = msg => {
    toast.error(<div style={{ padding: 20 }}>{msg}</div>);
  };

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  render() {
    const { addUser, toggle } = this.props;
    const { saving, loading, status, admin, validation, show } = this.state;
    const Required = () => <span style={{ color: "red" }}>*</span>;
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={addUser}
          toggle={() => !saving && toggle}
          style={{ maxWidth: 800 }}
        >
          {loading && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <h3 className="modal-title" id="exampleModalLabel">
              Add User
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" style={{ border: "1px solid #eee" }}>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-first-name"
                    style={{
                      color: validation.firstname === false ? "red" : null
                    }}
                  >
                    <Required />
                    First name
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-first-name"
                    onChange={e => this.onChange(e.target.value, "firstname")}
                    placeholder="First name"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                    style={{
                      color: validation.lastname === false ? "red" : null
                    }}
                  >
                    <Required />
                    Last name
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-last-name"
                    onChange={e => this.onChange(e.target.value, "lastname")}
                    placeholder="Last name"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-email"
                    style={{ color: validation.email === false ? "red" : null }}
                  >
                    <Required />
                    Email
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-email"
                    onChange={e => this.onChange(e.target.value, "email")}
                    placeholder="Email"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-phone"
                    style={{ color: validation.phone === false ? "red" : null }}
                  >
                    Phone
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-phone"
                    onChange={e => this.onChange(e.target.value, "phone")}
                    placeholder="Phone"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="5">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-address">
                    Address
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-address"
                    onChange={e => this.onChange(e.target.value, "address")}
                    placeholder="Address"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row
              style={{
                border: "1px #eee solid",
                padding: "10px 5px 12px",
                margin: "0 2px 15px",
                borderRadius: 7
              }}
            >
              <Col lg="3">
              <FormGroup>
                          <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Status
                            </label>
                            <Input
                              type="select"
                              bsSize="sm"
                              style={{ marginRight: 10, width: "100%" }}
                              onChange={async e => {
                                await this.onChange(e.target.value, "status");
                              }}
                            >
                              <option disabled value="">
                                Status
                              </option>
                              <option value="1">Active</option>
                              <option value="0">Inactive</option>
                            </Input>
                          </FormGroup>
            
              </Col>
              <Col lg="3">
              <FormGroup>
                          <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Admin
                            </label>
                            <Input
                              type="select"
                              bsSize="sm"
                              style={{ marginRight: 10, width: "100%" }}
                              onChange={async e => {
                                await this.onChange(e.target.value, "admin");
                              }}
                            >
                              <option disabled value="">
                                Admin
                              </option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </Input>
                          </FormGroup>
            
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Input
                    type="select"
                    bsSize="sm"
                    style={{ marginRight: 10, width: "100%" }}
                    onChange={async e => {
                      await this.onChange(e.target.value, "role");
                    }}
                  >
                    
                    <option>
                      Assign Role
                    </option>
                    <option value="staff">staff</option>
                    <option value="reseller">Reseller</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row
              style={{
                border: "1px #eee solid",
                padding: "10px 5px 12px",
                margin: "0 2px 15px",
                borderRadius: 7
              }}
            ></Row>
            <Row
              style={{
                border: "1px #eee solid",
                padding: "10px 5px 0px",
                margin: "0 2px",
                borderRadius: 7
              }}
            >
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-password"
                    style={{
                      color: validation.password === false ? "red" : null
                    }}
                  >
                    <Required />
                    Password
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Password"
                      type={show ? "text" : "password"}
                      onChange={e => this.onChange(e.target.value, "password")}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <span
                          className="hover-link"
                          style={{ fontSize: 12, cursor: "pointer" }}
                          onClick={() => this.setState({ show: !show })}
                        >
                          {show ? "hide" : "show"}
                        </span>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="modal-footer" style={{ padding: "1rem" }}>
            <Button
              size="sm"
              color="secondary"
              data-dismiss="modal"
              type="button"
              disabled={saving}
              onClick={toggle}
            >
              Close
            </Button>
            <Button
              color="success"
              type="button"
              disabled={saving}
              size="sm"
              onClick={this.onSave}
              style={{
                backgroundColor: "#EC3237",
                borderColor: "#EC3237",
                color: "#fff"
              }}
            >
              Save
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AddUser;
