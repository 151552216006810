import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormText,
  UncontrolledTooltip
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { getReport } from "services/reportService";
import { getUser } from "services/authService";
import SpinDiv from "components/SpinDiv.jsx";
import axios from "axios";
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";

class Report extends React.Component {
  constructor(props) {
    super(props);
    const { location: state } = props;
    this.state = {
      loading: false,
      edit: false,
      change: false,
      report: state && state.report ? state.report : null,
      validation: {},
      format: {},
      id: props.match.params.id,
      users: []
    };
  }

  componentDidMount() {
    this.getReport();
  }

  getReport = () => {
    const { id} = this.state;
    this.setState({ loading: true });
    getReport(id).then(
      (res) => {
          
        this.setState({
          loading: false,
          report: { ...res.report },
          initialReport: { ...res.report },
          //.map(p=>({label:p.name, value:p.id})),
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  toggleEdit = () => {
    const { initialReport } = this.state;
    this.setState({ edit: !this.state.edit, report: { ...initialReport } });
  };

  togglePassword = () => {
    this.setState({ change: !this.state.change });
  };

  /*onSave = async e => {
    e.preventDefault();
    await toast.dismiss();
    const { assignment, validation } = this.state;
    const {
      status,
      message,
      reference,
      available,
      subject,
      id
    } = assignment;
    await this.setState({
      validation: {
        ...validation,
        
        //status: status !== "",
        //message: message !== "",
      }
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveAssignment();
    } else {
      const errors = Object.keys(this.state.validation).filter(id => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(
        () =>
          toast.error(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold" }}>Errors:</p>
              {errors.map(v => (
                <p key={v} style={{ margin: 0, fontSize: 14 }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          ),
        250
      );
    }
  };

  saveAssignment = () => {
    this.setState({ saving: true });
    let data = new FormData();
      data.set("message", this.state.assignment.message);
      data.set("status", this.state.assignment.status);
      data.set("subject", this.state.assignment.subject);
    return axios
      .post(
        `${settings.API_URL}updateassignment/${this.state.assignment.id}`,
        data,
        {
          headers: authHeader()
        },
        authService.handleResponse
      )
      .then(res => {
        console.log(res.data);
        this.showToast("Status updated");
        //document.getElementById('myedit').style.display = 'none';
        //document.getElementById('back').style.display = 'block';
        this.setState({ saving: false, edit: false });
        this.getReport(this.state.assignment.id);
      })
      .catch(err => {
        console.log(err);
        this.setState({
          errorMessage: err,
          show: true
        });
        this.setState({ saving: false });
      });
    
    
  };

  validationRules = field => {
    if (field === "title") {
      return "title is required";
    } else if (field === "category_id") {
      return "Category is required";
   
    }
  };

  onChange = (e, state) => {
    console.log(e);
    const { assignment } = this.state;

    this.setState({ assignment: { ...assignment, [state]: e } });
  };

  showToast = msg => {
    toast.error(<div style={{ padding: 20 }}>{msg}</div>);
  };*/

  render() {
    const {
      report,
      loading,
      //validation,
      //edit,
      initialReport,
      saving,
      change,
      users
    } = this.state;
    const Required = () => <span style={{ color: "red" }}>*</span>;
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        minWidth: 170,
        height: 46,
        borderColor: "hsl(0,0%,95%)",
        boxShadow: "1px 1px 1px rgba(50, 50, 50, 0.1)"
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      }
    };

    return (
      <>
        <div
          className="header pb-8 pt-5 pt-md-6"
          style={{ backgroundColor: "#3E70D7" }}
        >
          <Container fluid>
            <div className="header-body">
              <Link
                to="/admin/reports"
                className="white-link"
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer"
                }}
              >
                <i className=" ni ni-bold-left" style={{ marginRight: 5 }} />
                <span>Back to Report</span>
              </Link>
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                {loading && <SpinDiv text={"Loading..."} />}
                {saving && <SpinDiv text={"Saving..."} />}
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      
                    </Col>
                   
                  </Row>
                </CardHeader>
                {report && (
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-2">
                        Reported Work information
                      </h6>
                      <div>
                      <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                
                              >
                                <Required />
                                Name
                              </label>
                              <Input
                                value={report.fullname}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled
                                
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                
                              >
                                <Required />
                                Link
                              </label>
                              <Input
                                value={report.link}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled
                                
                              ></Input>
                            </FormGroup>
                          </Col>
                         
                        </Row>
                        <Row>
                        <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                
                              >
                                <Required />
                                Project Topic
                              </label>
                              <Input
                                value={report.project_topic}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                
                              >
                                <Required />
                                Year
                              </label>
                              <Input
                                value={report.year}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled
                               
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        
  
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                
                              >
                                <Required />
                                Year
                              </label>
                              <Input
                                value={report.year}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled
                                
                              ></Input>
                            </FormGroup>
                          </Col>
                          
                        
                        </Row>
                        
                        
                        <Row>
                          
                          
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-body"
                              >
                                Description:
                                <Required />
                              </label>
                              <Input
                                value={report.additional_info}
                                type="textarea"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled
                                
                              ></Input>
                            </FormGroup>
                          </Col>
                          
                        </Row>
                       </div> 
                    </Form>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Report;
