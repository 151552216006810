/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { getStat }  from 'services/resellerProjectService';

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

class ResellerHeader extends React.Component {
    state = {
        activeNav: 1,
        loading: true,
        sold:"",
        upload:"",
        request:"",
          page: 1,
          rows: 10,
          total: 0,
        search: '',
        blogcategories:[],
      };
    
      componentDidMount() {
        this.getStat();
      }
    
      componentWillMount() {
      }
    
      
    
      getStat = () => {
        this.setState({loading: true});
        getStat()
          .then((res) => {console.log(res)
            this.setState({
              sold: res.sold,
              prequest:res.prequest,
              upload:res.upload,
              loading: false,
            })
          },
          error => {
            this.setState({loading: false});
          });
      }
    
  render() {
    const {sold,upload,prequest} = this.state;
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-7" style={{backgroundColor: '#3E70D7'}}>
          
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Project Uploaded
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {upload}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Col>
                      </Row>
                      
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Project Sold
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {sold}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Project Requested
                          </CardTitle>
                            <span className="h2 font-weight-bold mb-0">{prequest}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default ResellerHeader;
