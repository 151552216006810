import ResellerIndex from "views/ResellerIndex.jsx";
import Projects from "views/resellers/projects/Index.jsx";
import User from "views/resellers/users/Index.jsx";
import Downloads from "./views/resellers/downloads/Index.jsx";
var routes = [
  {
    path: "/home",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: ResellerIndex,
    layout: "/reseller"
  },
  {
    path: "/projects",
    name: "Project",
    icon: "ni ni-archive-2 text-green",
    component: Projects,
    layout: "/reseller"
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "fas fa-user text-orange",
    component: User,
    layout: "/reseller"
  },

  {
    path: "/downloads",
    name: "Downloads",
    icon: "fa fa-download text-blue",
    component: Downloads,
    layout: "/reseller"
  },
   
];
export default routes;
