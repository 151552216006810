import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const hirewriterService = {
    getReport,
    updateAssignment,
    getReports
};
export function getReport(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}reportwork/${id}`, requestOptions).then(authService.handleResponse);
}

export function getReports(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}reportwork`, requestOptions).then(authService.handleResponse);
}

export function updateAssignment(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateassignment/${data.id}`, requestOptions).then(authService.handleResponse);
}
