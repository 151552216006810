import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  FormGroup,
  Input,
  Label,
  FormText,
} from "reactstrap";

import { toast } from "react-toastify";
import axios from "axios";
import { addProject, getProject } from "services/projectService";
import SpinDiv from "components/SpinDiv.jsx";
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
// import { Editor } from '@tinymce/tinymce-react';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Select } from "antd";
import { getProjectSpice } from "../../services/projectService";

class AddProject extends React.Component {
  constructor(props) {
    super(props);
    // this.handleChange = this.handleChange.bind(this);
    // this.handleEditorChange = this.handleEditorChange.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.formats = [
      "header",
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
      "video",
      "align",
      "color",
      "background",
    ];
    this.modules = {
      toolbar: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image", "video"],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["clean"],
      ],
    };

    this.state = {
      activeNav: 1,
      loading: false,
      search: "",

      editorState: "",
      theme: "snow",
      categories: [],
      departments: [],
      studylevels: [],
      tag_id: [],
      users: props.users,
      validation: {},
      title: "",
      file: "",
      page_numbers: "",
      price: "",
      summary: "",
      approved: "",
      category_id: "",
      department_id: "",
      studylevel_id: "",
      chapters: "",
      reference: "",
      available: "",
      handleChange: "",
      tags: [],
    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
    this.getProjectSpice();
  }

  getProjectSpice = async () => {
    this.setState({ loading: true });
    getProjectSpice().then(
      (res) => {
        this.setState({
          categories: res.categories,
          studylevels: res.studylevels,
          departments: res.departments,
          tags: res.tags,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {
      title,
      category_id,
      department_id,
      studylevel_id,
      tag_id,
      tags,
      approved,
      chapters,
      summary,
      editorState,
      page_numbers,
      price,
      reference,
      available,
      file,
      validation,
    } = this.state;

    await this.setState({
      validation: {
        ...validation,
        title: title !== "",
        category_id: category_id !== "",
        department_id: department_id !== "",
        studylevel_id: studylevel_id !== "",
        tag_id: tag_id.length > 0,
        approved: approved !== "",
        reference: reference !== "",
        summary: summary !== "",
        available: available !== "",
        price: price !== "",
        approved: approved !== "",
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveProject();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(
        () =>
          toast.error(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold" }}>Errors:</p>
              {errors.map((v) => (
                <p key={v} style={{ margin: 0, fontSize: 14 }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          ),
        250
      );
    }
  };

  validationRules = (field) => {
    if (field === "title") {
      return "title is required";
    } else if (field === "category_id") {
      return "Category is required";
    } else if (field === "department_id") {
      return "Department is required";
    } else if (field === "studylevel_id") {
      return "Study level is required";
    } else if (field === "summary") {
      return "Summary is required";
    } else if (field === "reference") {
      return "Reference is required";
    } else if (field === "available") {
      return "Availabilty is required";
    } else if (field === "price") {
      return "Price is required";
    } else if (field === "approved") {
      return "Approval field is required";
    } else if (field === "tag_id") {
      return "Tag is required";
    }
  };

  saveProject = () => {
    this.setState({ saving: true });
    const {
      title,
      category_id,
      department_id,
      studylevel_id,
      tag_id,
      approved,
      chapters,
      summary,
      file,
      page_numbers,
      price,
      reference,
      available,
      editorState,
    } = this.state;

    let data = new FormData();

    let op = tag_id;

    for (var i in op) {
      data.set(`tag_id[${i}]`, op[i]);
    }

    file && data.append("file", file);
    data.set("title", title);
    data.set("approved", approved);
    data.set("category_id", category_id);
    data.set("department_id", department_id);
    data.set("studylevel_id", studylevel_id);

    data.set("chapters", chapters);
    data.set("summary", summary);
    data.set("page_numbers", page_numbers);
    data.set("price", price);
    data.set("reference", reference);
    data.set("available", available);

    return axios
      .post(
        `${settings.API_URL}addproject`,
        data,
        {
          headers: authHeader(),
        },
        authService.handleResponse
      )
      .then((res) => {
        this.setState({ saving: false });
        this.props.saved();
        this.props.toggle();
        this.showToast("Project Created");
      })
      .catch((err) => {
        this.setState({
          errorMessage: err.response.data,
          show: true,
        });

        if (this.state.errorMessage.title) {
          this.showToast(this.state.errorMessage.title[0]);
        }

        if (this.state.errorMessage.file) {
          this.showToast(this.state.errorMessage.file[0]);
        }
        this.setState({ loading: false });
      });
  };

  showToast = (msg) => {
    toast.error(<div style={{ padding: 20 }}>{msg}</div>);
  };

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  // handleChange = (summarys) => {
  //   this.setState({ summarys });
  // };

  // handleEditorChange(summary) {
  //   this.setState({ summary });
  // }

  // onEditorStateChange = (editorState) => {
  //   this.setState({
  //     editorState
  //   });
  // };

  handleChange(summary) {
    this.setState({ summary });
  }

  handleThemeChange(newTheme) {
    if (newTheme === "core") newTheme = null;
    this.setState({ theme: newTheme });
  }

  tagHandleChange = (tag_id) => {
    if (tag_id.length > 0) {
      let new_tag_id = tag_id.map((obj) => {
        return Number(obj.split(" ")[0]);
      });
      this.setState({
        tag_id: new_tag_id,
      });
    } else {
      return [];
    }
  };

  tagHandler = (e) => {
    let target = e.target;
    let name = target.name;
    //here
    let value = Array.from(target.selectedValues, (option) => option.value);
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { addProject, toggle } = this.props;

    const {
      projects,
      tags,
      tag_id,
      categories,
      departments,
      studylevels,
      approved,
      saving,
      loading,
      validation,
      file,
      category_id,
      department_id,
      studylevel_id,
      reference,
      available,
      title,
      selectedOption,
      editorState,
      summary,
    } = this.state;
    const Required = () => <span style={{ color: "red" }}>*</span>;
    return (
      <>
        <Modal
          className="modal-dialog-full"
          isOpen={addProject}
          toggle={() => !saving && toggle}
          style={{ maxWidth: "60%" }}
        >
          {loading && <SpinDiv text={"Loading..."} />}
          {saving && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <h3 className="modal-title" id="exampleModalLabel">
              Add Project
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>x</span>
            </button>
          </div>
          <div className="modal-body" style={{ border: "1px solid #eee" }}>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-phone"
                    style={{ color: validation.title === false ? "red" : null }}
                  >
                    <Required />
                    Add Title
                  </label>

                  <Input
                    style={{ height: 35 }}
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-title"
                    onChange={(e) => this.onChange(e.target.value, "title")}
                    placeholder="Add title"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-category_id"
                  >
                    Choose Category
                  </label>
                  <Input
                    value={category_id}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.category_id === false ? "red" : null,
                    }}
                    onChange={async (e) => {
                      await this.onChange(e.target.value, "category_id");
                    }}
                  >
                    <option disabled value="">
                      Select Category
                    </option>
                    {categories.map((p) => (
                      <option value={p} key={p}>
                        {p}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-product_id"
                  >
                    Department
                  </label>
                  <Input
                    value={department_id}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.department_id === false ? "red" : null,
                    }}
                    onChange={async (e) => {
                      await this.onChange(e.target.value, "department_id");
                    }}
                  >
                    <option disabled value="">
                      Select Department
                    </option>
                    {departments.map((p) => (
                      <option value={p} key={p}>
                        {p}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-product_id"
                  >
                    Study Level
                  </label>
                  <Input
                    value={studylevel_id}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.studylevel_id === false ? "red" : null,
                    }}
                    onChange={async (e) => {
                      await this.onChange(e.target.value, "studylevel_id");
                    }}
                  >
                    <option disabled value="">
                      Select Study level
                    </option>
                    {studylevels.map((p) => (
                      <option value={p} key={p}>
                        {p}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-product_id"
                    style={{ display: "block" }}
                  >
                    Choose project Tag
                  </label>
                  <Select
                    mode="multiple"
                    defaultValue={this.state.tag_id}
                    allowClear
                    onChange={this.tagHandleChange}
                    style={{ width: "100%" }}
                  >
                    <option value="">Select Tags</option>
                    {tags.length == 0 && ""}
                    {tags.map((p, index) => (
                      <option key={p.id + " " + p.title}>{p.title}</option>
                    ))}
                  </Select>
                </FormGroup>
              </Col>
              <Col lg="6"></Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-file"
                    style={{ color: validation.file === false ? "red" : null }}
                  >
                    Upload Project
                  </label>
                  <Input
                    style={{ height: 41 }}
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-file"
                    onChange={(e) => this.onChange(e.target.files[0], "file")}
                    type="file"
                  />
                  <FormText color="muted">
                    you can only upload doc,pdf,txt files
                  </FormText>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-phone"
                    style={{ color: validation.price === false ? "red" : null }}
                  >
                    <Required />
                    Add Price
                  </label>
                  <Input
                    style={{ height: 41 }}
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-phone"
                    onChange={(e) => this.onChange(e.target.value, "price")}
                    placeholder="Add price"
                    type="number"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-reference"
                  >
                    Choose Approval
                  </label>
                  <Input
                    value={approved}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.approved === false ? "red" : null,
                    }}
                    onChange={async (e) => {
                      await this.onChange(e.target.value, "approved");
                    }}
                  >
                    <option disabled value="">
                      Choose Approval
                    </option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-phone"
                    style={{
                      color: validation.page_numbers === false ? "red" : null,
                    }}
                  >
                    Add No of pages
                  </label>
                  <Input
                    style={{ height: 41 }}
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-phone"
                    onChange={(e) =>
                      this.onChange(e.target.value, "page_numbers")
                    }
                    placeholder="Add page number"
                    type="number"
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-chapters"
                    style={{
                      color: validation.chapters === false ? "red" : null,
                    }}
                  >
                    Add No of chapters
                  </label>
                  <Input
                    style={{ height: 35 }}
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-chapters"
                    onChange={(e) => this.onChange(e.target.value, "chapters")}
                    placeholder="Add Chapters"
                    type="number"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-reference"
                  >
                    Choose Reference
                  </label>
                  <Input
                    value={reference}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.reference === false ? "red" : null,
                    }}
                    onChange={async (e) => {
                      await this.onChange(e.target.value, "reference");
                    }}
                  >
                    <option disabled value="">
                      Choose Reference
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-available"
                  >
                    Is this file readily available?
                  </label>
                  <Input
                    value={available}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.available === false ? "red" : null,
                    }}
                    onChange={async (e) => {
                      await this.onChange(e.target.value, "available");
                    }}
                  >
                    <option disabled value="">
                      Choose Availability
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Col lg="12">
              <ReactQuill
                theme="snow"
                value={this.state.summary}
                modules={this.modules}
                formats={this.formats}
                onChange={this.handleChange}
              />
            </Col>

            <Row></Row>
          </div>
          <div className="modal-footer" style={{ padding: "1rem" }}>
            <Button
              size="sm"
              color="secondary"
              data-dismiss="modal"
              type="button"
              disabled={saving}
              onClick={toggle}
            >
              Close
            </Button>
            <Button
              color="success"
              type="button"
              disabled={saving}
              size="sm"
              onClick={this.onSave}
              style={{
                backgroundColor: "#EC3237",
                borderColor: "#EC3237",
                color: "#fff",
              }}
            >
              Save
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AddProject;
