import React from "react";
import { Link } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import {
  Button,
  Card,
  //CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  //Row,
  Col
} from "reactstrap";

import  { toast } from "react-toastify";
import { sendrecovery } from "services/authService";

class Forgotpassword extends React.Component {
  state = {
    email: "",
    loading: false,
    error: null,
    show: false,
  };

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  isValid = () => {
    const {email } = this.state;
    const emailreg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      email != null &&
      (emailreg.test(email.trim()))
    ) {
      return true;
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const {email } = this.state;
    this.setState({ loading: true });
    sendrecovery({email }).then(
      (v) => {
        this.setState({
          loading: false,
        });
        document.getElementById('form').style.display = 'none';
        document.getElementById('info').style.display = 'none';
        document.getElementById('mailsent').style.display = 'block';
      },
      (error) => {
        this.setState({ loading: false, password: "" });
        toast.dismiss();
        toast.configure({ hideProgressBar: true, closeButton: false });
        toast(
          <div style={{ padding: 20, color: "#EC3237" }}>
            Link not sent
          </div>
        );
      }
    );
  };

  render() {
    const { show, loading,email} = this.state;
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <img
                  style={{ width: 180, borderRadius: 5 }}
                  alt="..."
                  src={require("assets/img/brand/logo.png")}
                />
              </div>
              <div className="text-center" id="info">
                  
                </div>
                <div className="text-center" style={{display: "none"}} id="mailsent">
                  <Button
                    className="my-4"
                    
                    style={{
                        color:"black",
                      fontWeight:"bold",
                      width: "100%",
                      backgroundColor: "success",
                      padding: loading ? "0.25rem 1.25rem" : null,
                    }}
                  >
                     <span>Reset link has been sent to <br/>{this.state.email}<br/>
                     </span>
                  </Button>
                </div>
              <Form role="form" id="form">
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Enter Your Account email"
                      type="text"
                      value={email}
                      onChange={(e) =>
                        this.onChange(e.target.value, "email")
                      }
                    />
                    
                  </InputGroup>
                </FormGroup>
                
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="success"
                    type="submit"
                    disabled={!this.isValid() || loading}
                    style={{
                      maxHeight: 43,
                      width: "100%",
                      backgroundColor: "#3E70D7",
                      borderColor: "#3E70D7",
                      padding: loading ? "0.25rem 1.25rem" : null,
                    }}
                    onClick={this.onSubmit}
                  >
                    {loading ? <Spinner animation="grow" variant="light" /> : <span>Send Reset Link</span>}
                  </Button>
                </div>
              </Form>
              <Link
              className="text-center"
              style={{ width: "100%", color: "black", hover:"blue" }}
              to="/auth/login"
            >
              <small>Back to Login</small>
            </Link>
             
            </CardBody>
          </Card>
          
        </Col>
      </>
    );
  }
}

export default Forgotpassword;
