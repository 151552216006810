import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  FormGroup,
  Input,
  Label,
  FormText
} from "reactstrap";
import { toast } from "react-toastify";
import SunEditor, {buttonList} from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import axios from "axios";
import { addBlog, getBlog } from "services/blogService";
import SpinDiv from "components/SpinDiv.jsx";
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import { Editor } from '@tinymce/tinymce-react';
import Select from 'react-select';
const tests = [
  { value: 'a', label: 'x' },
  { value: 'b', label: 'y' },
  { value: 'c', label: 'z' },
  { value: 'd', label: 'd' },
];

class AddBlog extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.state = {
      activeNav: 1,
      loading: false,
      search: "",
      categories: props.categories,
      users: props.users,
      validation: {},
      selectOptions: [],
      title: "",
      image: "",
      body: "",
      tags: props.tags,
      category_id: "",
      tag_id: [],
      selectedOptions: [],
      options:props.tags.map(function(obj) { 
        obj['value'] = obj['id']; // Assign new key
        obj['label'] = obj['title']; 
        delete obj['title']; // Delete old key 
          delete obj['id'];
        return obj; 
      }) 
    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
  }

  onSave = async e => {
    e.preventDefault();
    await toast.dismiss();
    const { title, category_id,options, body, tag_id, image, validation} = this.state;
    await this.setState({
      validation: {
        ...validation,
        title: title !== "",
        category_id: category_id !== "",
        tag_id: tag_id !== "",
        body: body !== "",
        image: image !== ""
      }
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveBlog();
    } else {
      const errors = Object.keys(this.state.validation).filter(id => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(
        () =>
          toast.error(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold" }}>Errors:</p>
              {errors.map(v => (
                <p key={v} style={{ margin: 0, fontSize: 14 }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          ),
        250
      );
    }
  };

  validationRules = field => {
    if (field === "title") {
      return "title is required";
    } else if (field === "category_id") {
      return "Category is required";
    } else if (field === "body") {
      return "Post is required";
    } else if (field === "tag_id") {
      return "Tags is required";
    } else if (field === "image") {
      return "post image is required";
    }
  };

  saveBlog = () => {
    this.setState({ loading: true });
    const { title, category_id, tag_id, body, image } = this.state;
    //const description= draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    if (this.state.image) {
      let data = new FormData();
      console.log(this.state.selectOptions);
     let op = this.state.selectedOptions;
      for(var i in op){
        data.set(`tag_id[${i}]`, op[i].value);
      }
    
      data.append("image", this.state.image);
      data.set("title", this.state.title);
      data.set("category_id", this.state.category_id);
      data.set("body", this.state.body);
      //data.set("tags", this.state.tags);
      return axios
        .post(
          `${settings.API_URL}blog/create`,
          data,
          {
            headers: authHeader()
          },
          authService.handleResponse
        )
        .then(res => {
          console.log(res.data);
          this.setState({ loading: false });
          this.props.saved();
          this.props.toggle();
          this.showToast("Blog Created");
        })
        .catch(err => {
          console.log(err);
          this.setState({
            errorMessage: err,
            show: true
          });
          if (this.state.errorMessage) {
            console.log(this.state.errorMessage);
            this.showToast("Invalid title / Image Type or size");
          }
          this.setState({ loading: false });
        });
    }
  };

  showToast = msg => {
    toast.error(<div style={{ padding: 20 }}>{msg}</div>);
  };

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };
  handleChange=(content)=>{
    this.setState({body:content });
     //Get Content Inside Editor
  };

  
  handleEditorChange(body) {
    this.setState({ body });
  }

  

  /*tagHandler = e => {
    let target = e.target;
    let name = target.name;
    //here
    let value = Array.from(target.selectedOptions, option => option.value);
    this.setState({
      [name]: value
    });
  };*/
  tagHandleChange = (selectedOptions) => {
    this.setState({ selectedOptions });
  }
  render() {
    const { addBlog, toggle } = this.props;

    const {
      blogs,
      categories,
      tags,
      saving,
      loading,
      validation,
      image,
      category_id,
      tag_id,
      body,
      selectedOption,
      options
    } = this.state;
    const Required = () => <span style={{ color: "red" }}>*</span>;
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={addBlog}
          toggle={() => !saving && toggle}
          style={{ maxWidth: 700 }}
        >
          {loading && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <h3 className="modal-title" id="exampleModalLabel">
              Add Blog
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>x</span>
            </button>
          </div>
          <div className="modal-body" style={{ border: "1px solid #eee" }}>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-phone"
                    style={{ color: validation.title === false ? "red" : null }}
                  >
                    <Required />
                    Add Title
                  </label>
                  <Input
                    style={{ height: 35 }}
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-title"
                    onChange={e => this.onChange(e.target.value, "title")}
                    placeholder="Add title"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-category_id"
                  >
                    Choose Category
                  </label>
                  <Input
                    value={category_id}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.category_id === false ? "red" : null
                    }}
                    onChange={async e => {
                      await this.onChange(e.target.value, "category_id");
                    }}
                  >
                    <option disabled value="">
                      Select Category
                    </option>
                    {categories.map(p => (
                      <option value={p.id} key={p}>
                        {p.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              
            </Row>
            <Row>
              <Col lg="12">
            {console.log(options)}
                <FormGroup>
                <label
                    className="form-control-label"
                    htmlFor="input-product_id"
                  >
                    Choose Blog Tag
                  </label>
                
                <Select
                  isMulti
                  value={selectedOption}
                  onChange={this.tagHandleChange}
                  options={options}
                 
                />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-image"
                    style={{ color: validation.image === false ? "red" : null }}
                  >
                    Upload Image
                  </label>
                  <Input
                    style={{ height: 41 }}
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-image"
                    onChange={e => this.onChange(e.target.files[0], "image")}
                    type="file"
                  />
                  <FormText color="muted">you can only upload Images</FormText>
                </FormGroup>
              </Col>
              <Col lg="6"></Col>
            </Row>
            
            <Row>
            <Col lg="12">
            <Editor
                //onInit={(evt, editor) => editorRef.current = editor}
                style={{
                  marginRight: 10,
                  width: "100%",
                  color: validation.body === false ? "red" : null,
                }}
                value={this.state.body} onEditorChange={this.handleEditorChange}

                //initialValue="<p>This is the initial content of the editor.</p>"
                init={{
                  height: 500,
                  menubar: true,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar: 'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              />
            </Col>
            </Row>
          </div>
          <div className="modal-footer" style={{ padding: "1rem" }}>
            <Button
              size="sm"
              color="secondary"
              data-dismiss="modal"
              type="button"
              disabled={saving}
              onClick={toggle}
            >
              Close
            </Button>
            <Button
              color="success"
              type="button"
              disabled={saving}
              size="sm"
              onClick={this.onSave}
              style={{
                backgroundColor: "#EC3237",
                borderColor: "#EC3237",
                color: "#fff"
              }}
            >
              Save
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AddBlog;
