import React from "react";
import { Row, Col, Button, Modal, FormGroup, Input } from "reactstrap";
import { toast } from "react-toastify";

import { addTag } from "services/blogtagService";
import SpinDiv from "components/SpinDiv.jsx";

class AddTag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      search: "",
      validation: {},
      title: "",
    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
  }

  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { title, validation } = this.state;
    await this.setState({
      validation: {
        ...validation,
        title: title !== "",
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveTag();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(
        () =>
          toast.error(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold" }}>Errors:</p>
              {errors.map((v) => (
                <p key={v} style={{ margin: 0, fontSize: 14 }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          ),
        250
      );
    }
  };

  validationRules = (field) => {
    if (field === "title") {
      return "Tag Title is required";
    }
  };

  saveTag = () => {
    this.setState({ loading: true });
    const { title} = this.state;
    addTag({ title }).then(
      (res) => {
        console.log(res);
        this.setState({ loading: false });
        this.props.saved();
        this.props.toggle();
        this.showToast("Tag Created");
      },
      (error) => {
        console.log(error);
        if (error.title) {
          this.showToast("A tag with this title already exists");
        }
        this.setState({ loading: false });
      }
    );
  };

  showToast = (msg) => {
    toast.error(<div style={{ padding: 20 }}>{msg}</div>);
  };

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  render() {
    const { add, toggle } = this.props;
    const { loading, validation } = this.state;
    const Required = () => <span style={{ color: "red" }}>*</span>;
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={add}
          toggle={() => !loading && toggle}
          style={{ maxWidth: 500 }}
        >
          {loading && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <h3 className="modal-title" id="exampleModalLabel">
              Add Tag
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" style={{ border: "1px solid #eee" }}>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-name"
                    style={{ color: validation.name === false ? "red" : null }}
                  >
                    <Required />
                    Title
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-name"
                    onChange={(e) => this.onChange(e.target.value, "title")}
                    placeholder="Name"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            
          </div>
          <div className="modal-footer" style={{ padding: "1rem" }}>
            <Button
              size="sm"
              color="secondary"
              data-dismiss="modal"
              type="button"
              disabled={loading}
              onClick={toggle}
            >
              Close
            </Button>
            <Button
              color="success"
              type="button"
              disabled={loading}
              size="sm"
              onClick={this.onSave}
              style={{
                backgroundColor: "#EC3237",
                borderColor: "#EC3237",
                color: "#fff",
              }}
            >
              Save
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AddTag;
