import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const downloadService = {
    getDownloads,
    updateDownload,
    getDownload,
    //deleteTarget,
    //exportTargets
};

export function getDownloads(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}downloads`, requestOptions).then(authService.handleResponse);
}
export function deleteDownload(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletedownload/${id}`, requestOptions).then(authService.handleResponse);
}

export function updateDownload(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updatedownload/${data.id}`, requestOptions).then(authService.handleResponse);
}



export function getDownload(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}downloadr/${id}`, requestOptions).then(authService.handleResponse);
}