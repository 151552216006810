import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const projectService = {
    addProject,
    getProjects,
    updateProject,
    getDownloads,
    getUsers,
    getUser,
    updateUser,
    getStat
    //deleteTarget,
    //exportTargets
};

export function getUsers(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
        
    };
    return fetch(`${settings.API_URL}reseller/profile`, requestOptions).then(authService.handleResponse);
}



export function getUser() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}reseller/profile/detail`, requestOptions).then(authService.handleResponse);
}

export function getStat() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}projectsold`, requestOptions).then(authService.handleResponse);
}

export function updateUser(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}reseller/profile/update`, requestOptions).then(authService.handleResponse);
}


export function getProjects(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}reseller/projects`, requestOptions).then(authService.handleResponse);
}

export function addProject(data) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
         body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}reseller/addproject`, requestOptions).then(authService.handleResponse);
}
export function getProject(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}reseller/project/${id}`, requestOptions).then(authService.handleResponse);
}

export function updateProject(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}reseller/updateproject/${data.id}`, requestOptions).then(authService.handleResponse);
}
export function downloadProject(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}reseller/download/${id}`, requestOptions).then(authService.handleResponse);
}

export function getDownloads(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}reseller/downloads`, requestOptions).then(authService.handleResponse);
}
